/* eslint-disable max-len */
import {createRef, Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {Field, FormName, formValueSelector, getFormValues, change} from 'redux-form';
import {SvgIcon, Button} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import ShipmentItemFields from '../shipmentItemFieldsLegacy';
import {warnings} from './utils/warnings';
import InputField from 'App/formComponents/fields/input';
import {getLinearFeetEstimatePromise} from 'App/actions/shipments';
import './styles.scss';

// @todo: move defaults to a central location
const defaultLineItem = {
  package_type: 'PLT',
  length: 40,
  width: 48,
  weight_unit: 'LB',
  length_unit: 'IN',
  stackable: false
};

/**
 * Shipment Line-Items Fields
 * A redux-form component
 */
class ShipmentItemsFields extends Component {
  constructor() {
    super();

    this.handleLinearFeetEstimate = _.debounce(this.handleLinearFeetEstimate, 300);

    this.addButtonRef = createRef();
    this.scrollToItemRef = createRef();
  }

  static defaultProps = {
    slimForm: false,
    showPurchaseOrderRef: true,
    shipmentLineItems: []
  };

  componentDidMount() {
    // This condition verifys that modalState on shipmentDetailsLineItems is passing a mode prop before allowing a click event
    if (this.props.modalState?.mode === 'add') {
      this.addButtonRef.current.click();
    }
  }

  componentDidUpdate(prevProps) {
    const {hasLTL, hasVLTL, form} = this.props;
    const ltlChanged = prevProps.hasLTL !== this.props.hasLTL;
    const vltlChanged = prevProps.hasVLTL !== this.props.hasVLTL;

    if (!hasLTL && !hasVLTL && (ltlChanged || vltlChanged)) {
      this.props.dispatch(change(form, 'total_linear_feet', null));
    } else if ((hasLTL || hasVLTL) && (ltlChanged || vltlChanged)) {
      this.handleLinearFeetEstimate();
    }

    this.scrollToItemRef?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  }

  /**
   * Add additional line item into redux form state
   */
  handleAddField(e) {
    e.preventDefault();
    const {fields, onAddItem, unitPreferences} = this.props;

    defaultLineItem.weight_unit = unitPreferences.weightUnit;
    defaultLineItem.length_unit = unitPreferences.lengthUnit;
    defaultLineItem.temp_unit = unitPreferences.temperatureUnit;

    fields.push(defaultLineItem);
    onAddItem && onAddItem();
  }

  /**
   * Remove line-item from field
   */
  handleRemoveItem(index, e) {
    e.preventDefault();
    const {shipmentLineItems, fields, onRemoveItem} = this.props;

    fields.remove(index);
    onRemoveItem && onRemoveItem(shipmentLineItems[index], index);
  }

  /**
   * Estimate Linear Feet and display the field
   * This is being calculated via http request
   * @param {Array} lineItems  Shipment line-items
   */
  handleLinearFeetEstimate(lineItems) {
    const {hasLTL, hasVLTL, user, totalLinearFeet, form, shipmentLineItems} = this.props;
    const isAuthed = Object.keys(user).length;

    if (isAuthed && (hasLTL || hasVLTL)) {
      /** Request estimated linear feet from required fields */
      return getLinearFeetEstimatePromise({line_items: shipmentLineItems})
        .then((resp) => {
          const minLinearFeet = 12;
          const hasLinearFeetField = resp && (resp.estimated_linear_feet >= minLinearFeet || totalLinearFeet);

          /** Set value manually in redux form */
          if (hasLinearFeetField) {
            this.props.dispatch(change(form, 'total_linear_feet', resp.estimated_linear_feet));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    const {
      fields,
      hasLTL,
      hasFTL,
      hasVLTL,
      hasDrayage,
      hasParcel,
      isIntlParcel,
      slimForm,
      externalForm,
      formValues,
      totalLinearFeet,
      purchaseOrder,
      shipmentLineItems,
      showPurchaseOrderRef,
      purchaseOrdersByShipment,
      fieldWarnings = warnings(formValues, this.props),
      meta: {error}
    } = this.props;

    const savedItemsCount = shipmentLineItems.reduce((total, item) => total + Boolean(item.id), 0);

    if (!fields.length) {
      fields.push(defaultLineItem);
    }
    return (
      <div className="shipment-items">
        {fields.map((lineItem, index) => {
          const isOnlySavedItem = shipmentLineItems[index] && shipmentLineItems[index].id && savedItemsCount === 1;
          const poId = shipmentLineItems[index] && shipmentLineItems[index].purchase_order;
          const po = purchaseOrder[poId];
          const poShipmentId = po && po.shipment;
          const purchaseOrders = poShipmentId && purchaseOrdersByShipment[poShipmentId];
          const showPurchaseOrderNumber =
            showPurchaseOrderRef && purchaseOrders && purchaseOrders.find((order) => order.id === poId);
          let extraProps = {};
          if (this.props.modalState?.mode === 'edit' && this.props.modalState?.index === index) {
            extraProps = {ref: this.scrollToItemRef};
          } else if (this.props.modalState?.mode === 'add' && index === fields.length - 1) {
            extraProps = {ref: this.scrollToItemRef};
          }
          return (
            <div {...extraProps} key={index}>
              <div className="shipment-item-header">
                <div>
                  <span>Item {index + 1}</span>
                  {showPurchaseOrderNumber && (
                    <span className="shipment-item-order">
                      Order{' '}
                      <Link to={`/purchase-orders/${purchaseOrder[shipmentLineItems[index].purchase_order].id}`}>
                        #{purchaseOrder[shipmentLineItems[index].purchase_order].order_number}
                      </Link>
                    </span>
                  )}
                </div>
                <div>
                  {fields.length > 1 && !isOnlySavedItem && (
                    <a href="#" className="trashButton" onClick={this.handleRemoveItem.bind(this, index)}>
                      <SvgIcon name="TrashOutlined" />
                    </a>
                  )}
                </div>
              </div>
              <FormName>
                {({form}) => (
                  <ShipmentItemFields
                    form={form}
                    name={lineItem}
                    externalForm={externalForm}
                    index={index}
                    hasLTL={hasLTL || hasVLTL}
                    hasFTL={hasFTL}
                    hasDrayage={hasDrayage}
                    hasParcel={hasParcel}
                    isIntlParcel={isIntlParcel}
                    slimForm={slimForm}
                    onLinearFeetEstimate={this.handleLinearFeetEstimate.bind(this)}
                    entityType={CustomFieldEntityTypesEnum.ShipmentLineItem}
                  />
                )}
              </FormName>
            </div>
          );
        })}
        {totalLinearFeet && <Field name="total_linear_feet" label="Total Linear Feet" component={InputField} />}
        {fieldWarnings.excessiveSize && (
          <div className="size-warning">
            <span>
              <strong className="error-text">Disclaimer:</strong> Shipments more than 6000 lbs or 12 linear feet may
              require shipping with Volume Less Than Truckload mode in order to avoid overage fees. Contact{' '}
              <a href="mailto:support@shipwell.com">support@shipwell.com</a> with questions.
            </span>
          </div>
        )}
        <div className="shipment-items-footer">
          <Button onClick={this.handleAddField.bind(this)} variant="tertiary" iconName="Plus">
            Add Item
          </Button>
        </div>
        {error && (
          <p className="error-text-form-level">
            <i className="icon icon-Delayed" />
            {error}
          </p>
        )}
      </div>
    );
  }
}

export default connect((state, props) => ({
  user: state.auth.user,
  company: state.auth.company,
  purchaseOrder: state.purchaseOrders.details.purchaseOrdersById,
  purchaseOrdersByShipment: state.purchaseOrders.details.purchaseOrdersByShipment,
  formValues: getFormValues(props.form)(state),
  totalWeight: formValueSelector(props.form)(state, 'total_weight_override'),
  manualWeightEntry: formValueSelector(props.form)(state, 'manual_total_weight'),
  totalLinearFeet: formValueSelector(props.form)(state, 'total_linear_feet'),
  shipmentLineItems: formValueSelector(props.form)(state, 'line_items'),
  unitPreferences: state.userCompany.unitPreferences
}))(ShipmentItemsFields);
