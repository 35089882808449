import {connect} from 'react-redux';
import {Formik, Field, Form} from 'formik';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import moment from 'moment';
import {object, string, array, bool, number} from 'yup';
import {useQuery} from '@tanstack/react-query';
import {Card, FormikCheckbox, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {ContractRateCurrencyEnum, ContractDistanceUnitEnum, ContractWeightUnitEnum} from '@shipwell/backend-core-sdk';
import {validateContractAddress} from './utils';
import FormFooter from 'App/formComponents/formSections/formFooter';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import './styles.scss';
import {FUEL_SURCHARGE_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getFuelSurchargeTable} from 'App/api/rateTables';
import {ContractFields} from 'App/formComponents/formSections/contractFields';
import {validateDollarValue} from 'App/utils/globals';
import {RATE_TYPE_RATE_TABLE, RATE_TYPE_PER_HOUR} from 'App/containers/userCompany/rateTables/constants';
import {useGetContractLanes} from 'App/data-hooks/contracts/useGetContractLanes';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';

/** Default values needed for Formik */
const defaultFormValues = {
  end_date: null,
  fuel_included: false,
  fuel_surcharge_table: null,
  distance_estimation_system: '',
  modes: [{description: 'Full Truckload', id: 1}],
  name: '',
  rate: '',
  rate_type: 'FLAT_RATE',
  start_date: moment().format('YYYY-MM-DD'),
  status: 'ACTIVE',
  email: '',
  calculate_per_hour_rate_automatically: false,
  per_hour_start_field: null,
  per_hour_end_field: null
};

export const validationSchema = object().shape({
  name: string().nullable().required('Contract name is required.'),
  status: string().nullable().required('Status is required.'),
  carrier: object().nullable().required('Carrier is required'),
  rate: string()
    .nullable()
    .when('rate_type', {
      is: (value) => value !== RATE_TYPE_RATE_TABLE,
      then: string()
        .nullable()
        .test('rate', 'A valid dollar value is required.', (value) => {
          if (value) {
            return validateDollarValue(value, 2);
          }
          return true;
        })
        .required('Rate is required')
    }),
  minimum_rate: string()
    .nullable()
    .test('minimum_rate', 'A valid dollar value is required.', (value) => {
      if (value) {
        return validateDollarValue(value, 2);
      }
      return true;
    }),
  add_additional_stop_charges: bool(),
  additional_stop_charges_type: string()
    .nullable()
    .when('add_additional_stop_charges', {
      is: true,
      then: string().required('A charge type is required whe "Add Additional Stop Charges" is selected')
    }),
  additional_stop_charge_flat_fee: string()
    .nullable()
    .test('additional_stop_charge_flat_fee', 'A valid dollar value is required.', (value) => {
      if (value) {
        return validateDollarValue(value, 2);
      }
      return true;
    }),
  additional_stop_charge_per_mile: string()
    .nullable()
    .test('additional_stop_charge_per_mile', 'A valid dollar value is required.', (value) => {
      if (value) {
        return validateDollarValue(value, 2);
      }
      return true;
    }),
  email: string().email('A valid email is required.').nullable(),
  origins: array().when('hasContractLanes', {
    is: (value) => value === false,
    then: array()
      .test('origins', 'Each address should contain a state/province and country.', (value) =>
        validateContractAddress(value)
      )
      .min(1, 'At least one address is required.')
  }),
  destinations: array().when('hasContractLanes', {
    is: (value) => value === false,
    then: array()
      .test('destinations', 'Each address should contain a state/province and country.', (value) =>
        validateContractAddress(value)
      )
      .min(1, 'At least one address is required.')
  }),
  additional_stops: array().of(
    object().shape({
      address_1: string().nullable(),
      city: string().nullable(),
      country: string().nullable(),
      postal_code: string().nullable(),
      state_province: string().nullable(),
      order_index: number(),
      additional_stop_charge: string()
        .nullable()
        .test('additional_stop_charge', 'A valid dollar value is required.', (value) => {
          if (value) {
            return validateDollarValue(value, 2);
          }
          return true;
        })
    })
  ),
  per_hour_start_field: string()
    .nullable()
    .when(['rate_type', 'calculate_per_hour_rate_automatically'], {
      is: (rateType, calculatePerHourRateAutomatically) =>
        rateType === RATE_TYPE_PER_HOUR && [true, 'true'].includes(calculatePerHourRateAutomatically),
      then: string().nullable().required('Start time is required.')
    }),
  per_hour_end_field: string()
    .nullable()
    .when(['rate_type', 'calculate_per_hour_rate_automatically'], {
      is: (rateType, calculatePerHourRateAutomatically) =>
        rateType === RATE_TYPE_PER_HOUR && [true, 'true'].includes(calculatePerHourRateAutomatically),
      then: string().nullable().required('End time is required.')
    })
});

/**
 * Contract Form
 */
const ContractForm = (props) => {
  const {values, onSubmit, onCancel, carrierRelationship, canEditContracts, isEdit, company, cloningContract} = props;

  const contractId = values.id;
  const {hasContractLanes} = useGetContractLanes({contractId});

  const fuelSurchargeTableId = values?.fuel_surcharge_table;
  const internationalPreferencesQuery = useGetInternationalPreferences();
  const isCanadianDefault = internationalPreferencesQuery?.data?.currency === 'CAD';

  // defaults based on company country setting
  const distanceUnit = isCanadianDefault ? ContractDistanceUnitEnum.Kilometer : ContractDistanceUnitEnum.Mile;
  const currency = isCanadianDefault ? ContractRateCurrencyEnum.Cad : ContractRateCurrencyEnum.Usd;
  const weightUnit = isCanadianDefault ? ContractWeightUnitEnum.Kg : ContractWeightUnitEnum.Lb;

  const getFuelSurchargeQuery = useQuery(
    [FUEL_SURCHARGE_QUERY_KEY, fuelSurchargeTableId],
    () => getFuelSurchargeTable(fuelSurchargeTableId),
    {
      enabled: !isNil(fuelSurchargeTableId)
    }
  );
  const fuelSurcharge = getFuelSurchargeQuery.data?.body;

  /** Cancel form */
  const handleCancel = (e) => {
    e.preventDefault();

    if (onCancel) {
      onCancel();
    }
  };

  /** Submit contract values */
  const handleContractSubmit = async (values, actions) => await onSubmit?.(values, actions);

  return (
    <>
      {internationalPreferencesQuery?.isLoading ? (
        <ShipwellLoader loading />
      ) : (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            distance_unit: distanceUnit,
            rate_currency: currency,
            additional_stop_charge_flat_fee_currency: currency,
            additional_stop_charge_per_mile_currency: currency,
            minimum_rate_currency: currency,
            weight_unit: weightUnit,
            ...defaultFormValues,
            carrier: carrierRelationship,
            ...values,
            fuel_surcharge_table: fuelSurcharge,
            hasContractLanes: hasContractLanes
          }}
          onSubmit={handleContractSubmit}
        >
          {({isSubmitting, values, setFieldValue, dirty, ...props}) => (
            <Form className="contracts__form mb-10" noValidate>
              <Card title="Contract">
                <ContractFields
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  cloningContract={cloningContract}
                  company={company}
                  lockCarrier={!cloningContract && (Boolean(carrierRelationship) || Boolean(values.id))}
                  setFieldValue={setFieldValue}
                  values={values}
                  hasContractLanes={hasContractLanes}
                />
              </Card>
              {!isEdit && (
                <ModalFormFooter
                  options={
                    <>
                      <Field component={FormikCheckbox} name="clone_on_save" label={'Clone contract after saving'} />
                      <Tooltip
                        placement="top"
                        tooltipClassname="contracts__form-tooltips"
                        tooltipContent={
                          <span>
                            This allows you to create a copy of an existing contract to use as a starting point for a
                            new contract.
                          </span>
                        }
                      >
                        <SvgIcon name="InfoOutlined" />
                      </Tooltip>
                    </>
                  }
                  isSubmitting={isSubmitting}
                  onCancel={handleCancel}
                />
              )}
              {isEdit && dirty && canEditContracts && (
                <FormFooter isSubmitting={isSubmitting} onCancel={handleCancel} />
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

ContractForm.propTypes = {
  canEditContracts: PropTypes.bool,
  carrierRelationship: PropTypes.object,
  cloningContract: PropTypes.object,
  company: PropTypes.object,
  isEdit: PropTypes.any,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.shape({
    fuel_surcharge_table: PropTypes.string,
    id: PropTypes.string
  })
};

export default connect((state) => ({
  company: state.userCompany.company
}))(ContractForm);
