import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {compose, renameProp} from 'recompose';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {initialFormValues} from 'App/containers/quotes/create/utils/constants';
import {QuickQuote, defaultFormSettings} from 'App/containers/quotes/create';
import {withCustomFieldsProvider} from 'App/data-hooks';
import {withCreateParcelRateRequest} from 'App/api/quoting/hooks/parcel/useCreateParcelRateRequest';
import {withGetParcelRateRequestIdByShipmentId} from 'App/api/quoting/hooks/parcel/useGetParcelRateRequestIdByShipmentId';

const EditQuickQuoteForm = reduxForm({
  ...defaultFormSettings,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(QuickQuote);

const EditQuickQuoteFormWithCustomFields = compose(
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentStop),
  renameProp('customFields', 'stopCustomFields'),
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentLineItem),
  withCreateParcelRateRequest,
  withGetParcelRateRequestIdByShipmentId
)(EditQuickQuoteForm);

export const EditQuickQuote = connect((state) => {
  return {
    user: state.auth.user,
    initialValues: initialFormValues,
    companyTags: state.shipments.tags,
    defaultShipmentTags: state.auth.user?.default_shipment_tags
  };
})(EditQuickQuoteFormWithCustomFields);
