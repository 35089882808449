import {isEmpty} from 'lodash';
import {Component} from 'react';
import {Link, browserHistory} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {SubmissionError} from 'redux-form';
import {Button, OverlayTrigger, Popover, DropdownButton, MenuItem, Tooltip} from 'react-bootstrap';
import get from 'lodash/get';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';
import {SvgIcon, Tooltip as ShipwellTooltip, Toast} from '@shipwell/shipwell-ui';
import ShipmentVendorEquipmentUpdateForm from './forms/ShipmentVendorEquipmentUpdateForm';
import {bind} from 'App/utils/camelize';
import * as shipmentActions from 'App/actions/shipments';
import * as shipmentdetailsActions from 'App/actions/shipmentdetails';
import * as vendorActions from 'App/actions/vendors';
import * as userActions from 'App/actions/users';
import * as documentActions from 'App/actions/documents';
import {putTriumphPayAccountDetails, postTriumphPayAccount} from 'App/actions/_vendors';
import {startAppTracking} from 'App/actions/_shipments';
import SearchVendor from 'App/containers/Settings/ManageVendors/SearchVendor';
import Vendor from 'App/containers/Settings/ManageVendors/Vendor';
import ManageVendorsForm from 'App/containers/Settings/ManageVendors/ManageVendorsForm';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {
  permUpdateQuotes,
  unpackErrors,
  permViewCarriers,
  permViewUsers,
  formatDateTime,
  reeferTypes,
  formatMileage
} from 'App/utils/globals';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import {
  CELSIUS,
  convertCelsiusToFahrenheit,
  METRIC,
  convertFahrenheitToCelsius,
  convertMilesToKilometers
} from 'App/utils/internationalConstants';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import './_shipment-carrier.scss';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

export class ShipmentVendorEquipment extends Component {
  constructor(props) {
    super(props);

    bind(this, [
      'cancelEdit',
      'editCarrier',
      'handleFormSubmit',
      'handleUpdateShipment',
      'renderEditCarrier',
      'unassignCarrier',
      'handleUpdateVendor',
      'createVendor',
      'handleOnComplete',
      'addTag',
      'handleBlur',
      'handleTagsChange',
      'handleTagsFocus',
      'editCompany',
      'tagsSearch',
      'getVendorInfo',
      'renderVendorModal',
      'switchScreen',
      'triggerAppDownloadLink',
      'triggerApplessTracking',
      'closeModal',
      'fetchUsers'
    ]);

    this.state = {
      editCarrier: false,
      canUpdateQuotes: false,
      canUpdateShipment: false,
      canUpdateEquipmentConfig: false,
      canViewCarrier: false || this.props.externalForm,
      selectedCarrier: null,
      isCurrentCarrier: false,
      showCarrierSearchModal: false,
      currentTags: [],
      currentTag: '',
      keyboard: false,
      currentScreen: 'search',
      showDriverSuccess: false,
      showPOCField: false,
      vendorPOCs: [],
      defaultVendorPOC: null,
      saving: false,
      showPowerUnitWarningToast: false
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.permissions) {
      this.setState({
        canUpdateQuotes: this.props.user.permissions.includes(permUpdateQuotes) ? true : false,
        canUpdateShipment:
          this.props.user.permissions.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
          this.props.user.permissions.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)
            ? true
            : false,
        canViewCarrier: this.props.user.permissions.includes(permViewCarriers) ? true : false,
        canViewUsers: this.props.user.permissions.includes(permViewUsers) ? true : false
      });
    }

    if (
      this.props.vendor &&
      this.props.vendor.vendor &&
      this.props.carrier &&
      this.props.vendor.vendor.id === this.props.carrier.id
    ) {
      //then the current carrier assigned is the vendor, and equipment updates can be made
      this.setState({canUpdateEquipmentConfig: true});
    } else if (!this.props.vendor) {
      this.setState({canUpdateEquipmentConfig: true});
    }

    if (this.props.carrier && this.props.company && this.props.carrier.id === this.props.company.id) {
      //then my company is the current carrier and i can make updates
      this.setState({canUpdateEquipmentConfig: true, isCurrentCarrier: true});
    }

    //get initial vendorPOC info
    if (this.props.one && this.props.one.vendor) {
      //get the details of this vendor by ID
      this.props.searchForCarrierByID(this.props.one.vendor.value).then((response) => {
        if (response.status === 200) {
          if (
            response.details &&
            response.details.results &&
            response.details.results[0] &&
            response.details.results[0].point_of_contacts &&
            response.details.results[0].point_of_contacts.length > 1
          ) {
            //show the POC field when form is open
            this.setState({showPOCField: true, vendorPOCs: response.details.results[0].point_of_contacts});
          } else if (
            response.details &&
            response.details.results &&
            response.details.results[0] &&
            response.details.results[0].point_of_contacts &&
            response.details.results[0].point_of_contacts.length === 1
          ) {
            //when there is only one POC, auto-assign it when the form is submitted.
            this.setState({
              defaultVendorPOC: response.details.results[0].point_of_contacts[0].id,
              showPOCField: false,
              vendorPOCs: response.details.results[0].point_of_contacts
            });
          } else {
            this.setState({showPOCField: false, vendorPOCs: []});
          }
        }
      });

      //get the company object to send later
      this.props.getCompanyDetails(this.props.one.vendor.value).then((response) => {
        if (response.status === 200) {
          this.setState({selectedCarrier: response.details});
          //if company doesnt have a carrier object, don't allow adding driver phone number
          if (response.details && !response.details.carrier) {
            this.setState({canUpdateEquipmentConfig: false});
          } else if (
            (response.details && !response.details.brokerage) ||
            (response.details.brokerage && response.details.brokerage.is_3pl === false)
          ) {
            this.setState({canUpdateEquipmentConfig: true});
          }
        } else {
          this.setState({selectedCarrier: null});
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.vendor &&
      nextProps.carrier &&
      (nextProps.vendor !== this.props.vendor || nextProps.carrier !== this.props.carrier)
    ) {
      if (nextProps.vendor.vendor.id === nextProps.carrier.id && !this.state.canUpdateEquipmentConfig) {
        this.setState({canUpdateEquipmentConfig: true});
      }
    }

    if (
      nextProps.carrier &&
      nextProps.company &&
      (nextProps.carrier !== this.props.carrier || nextProps.company !== this.props.company)
    ) {
      if (nextProps.carrier.id === nextProps.company.id && !this.state.canUpdateEquipmentConfig) {
        this.setState({canUpdateEquipmentConfig: true, isCurrentCarrier: true});
      }
    }

    if (nextProps.user && this.props.user !== nextProps.user && nextProps.user.permissions) {
      this.setState({
        canUpdateQuotes: nextProps.user.permissions.includes(permUpdateQuotes) ? true : false,
        canUpdateShipment:
          nextProps.user.permissions.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
          nextProps.user.permissions.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)
            ? true
            : false,
        canViewCarrier: nextProps.user.permissions.includes(permViewCarriers) ? true : false,
        canViewUsers: nextProps.user.permissions.includes(permViewUsers) ? true : false
      });
    }
    if (
      this.props.shipmentVendorEquipmentUpdateForm &&
      nextProps.shipmentVendorEquipmentUpdateForm &&
      nextProps.shipmentVendorEquipmentUpdateForm.values &&
      nextProps.shipmentVendorEquipmentUpdateForm.values.vendor &&
      this.props.shipmentVendorEquipmentUpdateForm.values &&
      (!this.props.shipmentVendorEquipmentUpdateForm.values.vendor ||
        nextProps.shipmentVendorEquipmentUpdateForm.values.vendor !==
          this.props.shipmentVendorEquipmentUpdateForm.values.vendor)
    ) {
      //if the selected vendor has more than one POC, we show the POC field so they can select the person that will be working the shipment
      //get the details of this vendor by ID
      this.props
        .searchForCarrierByID(nextProps.shipmentVendorEquipmentUpdateForm.values.vendor.value)
        .then((response) => {
          if (response.status === 200) {
            if (
              response.details &&
              response.details.results &&
              response.details.results[0] &&
              response.details.results[0].point_of_contacts &&
              response.details.results[0].point_of_contacts.length > 1
            ) {
              //show the POC field when form is open
              this.setState({showPOCField: true, vendorPOCs: response.details.results[0].point_of_contacts});
            } else if (
              response.details &&
              response.details.results &&
              response.details.results[0] &&
              response.details.results[0].point_of_contacts &&
              response.details.results[0].point_of_contacts.length === 1
            ) {
              //when there is only one POC, auto-assign it when the form is submitted.
              this.setState({
                defaultVendorPOC: response.details.results[0].point_of_contacts[0].id,
                showPOCField: false,
                vendorPOCs: response.details.results[0].point_of_contacts
              });
            } else {
              this.setState({showPOCField: false, vendorPOCs: []});
            }
          }
        });

      //get the company object to send later
      this.props.getCompanyDetails(nextProps.shipmentVendorEquipmentUpdateForm.values.vendor.value).then((response) => {
        if (response.status === 200) {
          this.setState({selectedCarrier: response.details});
          //if company doesnt have a carrier object, don't allow adding driver phone number
          if (response.details && !response.details.carrier) {
            this.setState({canUpdateEquipmentConfig: false});
          } else if (
            (response.details && !response.details.brokerage) ||
            (response.details.brokerage && response.details.brokerage.is_3pl === false)
          ) {
            this.setState({canUpdateEquipmentConfig: true});
          }
        } else {
          this.setState({selectedCarrier: null});
        }
      });
    }
  }

  createVendor() {
    this.props.clearCarriers();
    this.props.clearSelectedCarrier();
    this.props.clearSelectedRelationship();
    this.setState({
      showCarrierSearchModal: true
    });
  }

  handleOnComplete(vendor) {
    this.setState({showCarrierSearchModal: false, currentScreen: 'search'});
  }

  updated() {
    this.props.fetchDetails(this.props.one.id);
    this.setState({
      editCarrier: false,
      saving: false
    });
  }

  cancelEdit() {
    this.setState({
      editCarrier: false
    });
  }

  editCarrier() {
    this.setState({
      editCarrier: !this.state.editCarrier
    });
  }

  transformEquipmentType(type) {
    type = parseInt(type, 10);
    type = this.props.equipmentTypes.filter((e) => e.id === type)[0];
    return type;
  }

  transformMode(mode) {
    mode = parseInt(mode, 10);
    mode = this.props.shipmentModes.filter((type) => type.id === mode)[0];
    return mode;
  }

  transformServiceLevel(level) {
    level = parseInt(level, 10);
    level = this.props.serviceLevels.filter((type) => type.id === level)[0];
    return level;
  }

  handleFormSubmit(attrs) {
    this.setState({saving: true});
    let shouldUpdateShipment = false;
    let shouldUpdateVendor = false;
    let shouldUpdateEquipmentConfig = false;
    // allow update equipment anytime
    if (
      attrs.equipment_type ||
      attrs.mode ||
      attrs.service_level ||
      attrs.notes_for_carrier ||
      attrs.temperature_lower_limit ||
      attrs.temperature_upper_limit
    ) {
      //if any of these things have values, we'll do a PUT on the shipment to update
      shouldUpdateShipment = true;
    }

    let selectedCarrier = null;
    if (attrs.vendor) {
      selectedCarrier = attrs.vendor.value;
    }
    let currentVendor = null;
    if (this.props.one.vendor) {
      currentVendor = this.props.one.vendor.value;
    }
    // use selectedCarrier or currentVendor or prompt to assign
    // carrier if there is no equipment_config
    if (!selectedCarrier || (selectedCarrier && currentVendor && selectedCarrier === currentVendor)) {
      if (
        (this.props.one.most_recently_awarded_quote &&
          this.props.one.most_recently_awarded_quote.source_type === 'INSTANT' &&
          this.props.one.most_recently_awarded_quote.mode.id !== 1) ||
        !attrs.vendor_point_of_contact ||
        this.props.one.vendor_point_of_contact === attrs.vendor_point_of_contact
      ) {
        shouldUpdateVendor = false;
      } else {
        shouldUpdateVendor = true;
      }
    } else {
      shouldUpdateVendor = true;
    }
    if (this.state.canUpdateEquipmentConfig === true) {
      if (attrs.equipment_config && attrs.equipment_config.driver) {
        shouldUpdateEquipmentConfig = true;
      }
      if (
        attrs.equipment_config &&
        (attrs.equipment_config.power_unit_name || attrs.equipment_config.power_unit_name === null)
      ) {
        shouldUpdateEquipmentConfig = true;
      }
      if (attrs.equipment_config?.trailer_name || attrs.equipment_config?.trailer_name === '') {
        shouldUpdateEquipmentConfig = true;
      }
    } else {
      shouldUpdateEquipmentConfig = false;
    }

    // start by updating the shipment if necessary
    if (shouldUpdateShipment) {
      return this.handleUpdateShipment(
        JSON.parse(JSON.stringify(attrs)),
        shouldUpdateVendor,
        shouldUpdateEquipmentConfig
      );
    }
    if (shouldUpdateVendor) {
      return this.handleUpdateVendor(attrs, shouldUpdateEquipmentConfig);
    }
    if (shouldUpdateEquipmentConfig) {
      return this.handleEquipmentConfig(attrs);
    }
    this.updated();
  }

  handleUpdateShipment(attrs, shouldUpdateVendor, shouldUpdateEquipmentConfig) {
    const shipmentId = this.props.shipment.id;
    const submissionError = {};
    const body = JSON.parse(JSON.stringify(this.props.one));
    if (attrs.equipment_type) {
      body.equipment_type = this.transformEquipmentType(attrs.equipment_type.id);
    }
    if (attrs.mode) {
      body.mode = this.transformMode(attrs.mode.id);
    }
    if (attrs.service_level) {
      body.service_level = this.transformServiceLevel(attrs.service_level.id);
    }
    // dont check for blank notes because notes can be blank
    body.notes_for_carrier = attrs.notes_for_carrier;

    const {unitPreferences} = this.props;

    if (attrs.temperature_lower_limit) {
      if (unitPreferences.temperatureUnit === CELSIUS) {
        body.temperature_lower_limit = convertCelsiusToFahrenheit(attrs.temperature_lower_limit);
      } else {
        body.temperature_lower_limit = attrs.temperature_lower_limit;
      }
    }
    if (attrs.temperature_upper_limit) {
      if (unitPreferences.temperatureUnit === CELSIUS) {
        body.temperature_upper_limit = convertCelsiusToFahrenheit(attrs.temperature_upper_limit);
      } else {
        body.temperature_upper_limit = attrs.temperature_upper_limit;
      }
    }

    if (isEmpty(body)) {
      return;
    }

    // update mode, equipment, service level
    return this.props.shipmentsShipmentIdPut(shipmentId, body, {}).then((response) => {
      if (response.status === 200) {
        if (shouldUpdateVendor) {
          return this.handleUpdateVendor(attrs, shouldUpdateEquipmentConfig);
        }
        if (shouldUpdateEquipmentConfig) {
          return this.handleEquipmentConfig(attrs);
        }
        this.updated();
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        if (submissionError.driver) {
          submissionError.equipment_config = {driver: submissionError.driver};
        }
        submissionError._error = response.error_description;
        this.setState({saving: false});
        throw new SubmissionError(submissionError);
      }
    });
  }

  handleUpdateVendor(attrs, shouldUpdateEquipmentConfig) {
    // Assign or update a carrier, then assign carrier to an equipment config
    const {one} = this.props;
    const {showPOCField, defaultVendorPOC, selectedCarrier} = this.state;

    const carrierAssignmentPayload = {
      vendor: selectedCarrier,
      customer_charge_line_items: [],
      vendor_charge_line_items: []
    };

    let isNewOrChangedCarrier = true;
    let carrierAssignmentRequest;

    // Check if carrier has changed
    if (
      one.most_recently_awarded_quote &&
      one.most_recently_awarded_quote.source_type === 'INSTANT' &&
      one.most_recently_awarded_quote.mode.id !== 1
    ) {
      isNewOrChangedCarrier = selectedCarrier.id !== one.current_carrier.id;
    } else if (one.relationship_to_vendor) {
      isNewOrChangedCarrier = selectedCarrier.id !== one.relationship_to_vendor.vendor.id;
    }

    // Assign point of contacts
    if (showPOCField && attrs.vendor_point_of_contact) {
      carrierAssignmentPayload.vendor_point_of_contact = attrs.vendor_point_of_contact;
    } else if (!showPOCField && defaultVendorPOC) {
      carrierAssignmentPayload.vendor_point_of_contact = defaultVendorPOC;
    }

    // Create or update carrier assignment
    if (isNewOrChangedCarrier) {
      carrierAssignmentRequest = this.props.assignCarrier(one.id, carrierAssignmentPayload);
    } else {
      carrierAssignmentRequest = this.props.editCarrierAssignment(one.id, one.relationship_to_vendor.id, {
        ...carrierAssignmentPayload,
        customer_charge_line_items: one.relationship_to_vendor.customer_charge_line_items.map(
          ({id, ...charge}) => charge
        ),
        vendor_charge_line_items: one.relationship_to_vendor.vendor_charge_line_items.map(({id, ...charge}) => charge)
      });
    }

    return carrierAssignmentRequest.then((assignCarrierResponse) => {
      // create equipment config if there is a driver (required)
      if (assignCarrierResponse.status === 200) {
        if (shouldUpdateEquipmentConfig) {
          return this.handleEquipmentConfig(attrs);
        }
        this.updated();
      } else {
        const errors = assignCarrierResponse.field_errors || [];
        let myError = {
          vendor: {primary_phone_number: ''}
        };
        const submissionError = {vendor: ''};
        myError = unpackErrors(errors, myError);
        myError._error = assignCarrierResponse.error_description;
        if (myError.vendor.primary_phone_number) {
          if (myError.vendor.primary_phone_number === 'Enter a valid phone number.') {
            submissionError.vendor = "Carrier company's phone number is not valid";
          } else {
            submissionError.vendor = myError.vendor.primary_phone_number;
          }
        }
        this.setState({saving: false});

        throw new SubmissionError(submissionError);
      }
    });
  }

  handleEquipmentConfig(attrs) {
    const {setError} = this.props;

    const equipmentConfig = {};
    // set the driver
    if (attrs.equipment_config && attrs.equipment_config.driver) {
      const driverPhone = attrs.equipment_config.driver.phone_number;

      equipmentConfig.driver = {};
      equipmentConfig.driver.phone_number = driverPhone;
      if (equipmentConfig.driver.phone_number === '') {
        delete equipmentConfig.driver;
      }
    }
    if (attrs?.equipment_config?.power_unit_name) {
      equipmentConfig.power_unit_name = attrs.equipment_config.power_unit_name.value;
      if (attrs.equipment_config.power_unit_name.label?.id) {
        equipmentConfig.power_unit_pk = attrs.equipment_config.power_unit_name.label.id;
      }
    } else if (!attrs?.equipment_config?.power_unit_name) {
      equipmentConfig.power_unit_name = '';
    }
    if (attrs.equipment_config?.trailer_name) {
      equipmentConfig.trailer_name = attrs.equipment_config.trailer_name;
    } else if (attrs.equipment_config?.trailer_name === '') {
      equipmentConfig.trailer_name = '';
    }

    if (isEmpty(equipmentConfig)) {
      this.updated();
    } else {
      // assign carrier to equipment config
      return this.props.assignEquipmentConfig(this.props.one.id, equipmentConfig).then((assignEquipmentResponse) => {
        if (assignEquipmentResponse.status === 200) {
          if (
            assignEquipmentResponse.details.power_unit &&
            assignEquipmentResponse.details.power_unit?.status !== 'Active'
          ) {
            //show a warning if the power unit is not active
            setError(
              'Power Unit Inactive',
              `Power Unit ${assignEquipmentResponse.details?.power_unit?.name} is not active in the assigned carrier. Please contact the carrier to confirm the Power Unit number.`
            );
          }
          //delete any scheduled calls if the equipment config is changed
          this.props.triggerCallCancel();
          this.updated();
          return;
        }
        //show errors
        const errors = assignEquipmentResponse.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        if (submissionError.driver) {
          submissionError.equipment_config = {driver: submissionError.driver};
        }
        submissionError._error = assignEquipmentResponse.error_description;
        this.setState({saving: false});

        throw new SubmissionError(submissionError);
      });
    }
  }

  renderEditCarrier(allowEditCarrier) {
    return (
      <ShipmentVendorEquipmentUpdateForm
        awardedQuote={this.props.awardedQuote}
        showPOCField={this.state.showPOCField}
        vendorPOCs={this.state.vendorPOCs}
        cancelEdit={this.cancelEdit}
        formValues={this.props.shipmentVendorEquipmentUpdateForm}
        isCurrentCarrier={this.state.isCurrentCarrier}
        onSubmit={this.handleFormSubmit}
        allowEditCarrier={allowEditCarrier}
        canUpdateEquipmentConfig={this.state.canUpdateEquipmentConfig}
        canViewCarrier={this.state.canViewCarrier}
        loadBoardEnabled={this.props.one && this.props.one.metadata && this.props.one.metadata.load_board_enabled}
        saving={this.state.saving}
      />
    );
  }

  replaceUnderscores(name) {
    return name.replace(/_/g, ' ');
  }

  unassignCarrier(e) {
    e.target.disabled = true;

    return this.props.unassignCarrier(this.props.shipment.id).then((response) => {
      if (response.status === 200) {
        //delete any scheduled events that would go to this vendor
        this.props.triggerCallCancel();
        this.setState({showPOCField: false, vendorPOCs: []});
        this.updated();
      }
      return;
    });
  }

  addTag(tag) {
    if (!tag.id) {
      return this.props.postCarrierTags({name: tag}).then(
        function (response) {
          if (response.status === 200) {
            if (!this.state.currentTags) {
              this.setState({
                currentTags: [response.details]
              });
            } else if (!this.state.currentTags.includes(response.details)) {
              this.setState((prevState) => ({
                currentTags: [...prevState.currentTags, response.details]
              }));
            }
          }
        }.bind(this)
      );
    }
    if (!this.state.currentTags) {
      this.setState({
        currentTags: [tag]
      });
    } else if (!this.state.currentTags.includes(tag)) {
      this.setState((prevState) => ({
        currentTags: [...prevState.currentTags, tag]
      }));
    }
  }

  handleBlur(e) {
    const tags = document.getElementById('rw_2__listbox');
    let tag;
    const createTag = document.getElementById('rw_2__createlist_option');
    if (tags && tags.getElementsByClassName('rw-state-focus')[0] && tags.children.length <= 1) {
      tag = tags.getElementsByClassName('rw-state-focus')[0].innerHTML;
    }
    if (tag) {
      this.addTag(this.getTag(tag));
    } else if (createTag && createTag.classList.contains('rw-state-focus')) {
      this.addTag(createTag.children[0].innerHTML.replace(/"/g, ''));
    } else if (e.target.value) {
      this.addTag(this.getTag(e.target.value));
    }
    this.setState({currentTag: '', keyboard: false});
  }
  handleTagsChange(e) {
    this.setState({currentTags: e});
  }
  handleTagsFocus() {
    this.setState({keyboard: true});
  }
  tagsSearch(e) {
    this.setState({currentTag: e});
  }

  editCompany(attrs) {
    const body = {
      shipwell_vendor: attrs.vendor && attrs.vendor.shipwell_vendor ? attrs.vendor.shipwell_vendor : {},
      notes: attrs.notes,
      tags: this.state.currentTags,
      point_of_contacts: attrs.point_of_contacts,
      carrier_owner: {id: attrs.carrier_owner}
    };
    let triumphPayPut = [];
    let triumphPayPost = [];
    let triumphPayAccounts = [];
    attrs &&
      attrs.triumphPay &&
      attrs.triumphPay.triumph_payment_accounts.forEach((acct) => {
        triumphPayAccounts = [...triumphPayAccounts, acct];
      });
    triumphPayAccounts.forEach((acct) => {
      //if there's a routing number and an account number, send the fields pertaining to bank account
      if (
        acct.bank_account_data &&
        acct.bank_account_data.bank_routing_number &&
        acct.bank_account_data.bank_account_number &&
        (acct.payment_type.id === 'ACH_BANK' || acct.payment_type === 'ACH_BANK')
      ) {
        acct = {
          bank_account_data: acct.bank_account_data,
          payment_type: acct.payment_type.id || acct.payment_type,
          id: acct.id
        };
      }
      //if there's a mail to name and address, send the fields pertaining to mail check
      else if (
        acct.vendor_billing_address &&
        (acct.payment_type.id === 'MAIL_CHECK' || acct.payment_type === 'MAIL_CHECK')
      ) {
        acct = {
          vendor_billing_address: acct.vendor_billing_address,
          payment_type: acct.payment_type.id || acct.payment_type,
          id: acct.id
        };
      }
      //if there's a factoring company name, send the fields pertaining to factoring company
      else if (
        acct.vendor_factor_company &&
        acct.vendor_factor_company.company_name &&
        (acct.payment_type.id === 'FACTOR' || acct.payment_type === 'FACTOR')
      ) {
        acct = {
          vendor_factor_company: {company_name: acct.vendor_factor_company.company_name},
          payment_type: acct.payment_type.id || acct.payment_type,
          id: acct.id
        };
      } else {
        //not a valid account type, do not send
        acct = {};
      }
    });

    triumphPayAccounts.forEach((acct) => {
      if ('id' in acct) {
        triumphPayPut = [...triumphPayPut, acct];
      } else {
        triumphPayPost = [...triumphPayPost, acct];
      }
    });

    //if empty billing address, delete it
    if (isEmpty(body.shipwell_vendor.billing_address)) {
      delete body.shipwell_vendor.billing_address;
    }

    // if no carrier owner, delete it
    if (!body.carrier_owner.id) {
      body.carrier_owner = null;
    }

    //convert insurance date to right format
    if (body.shipwell_vendor && body.shipwell_vendor.insurance_expires_at) {
      body.shipwell_vendor.insurance_expires_at = moment(body.shipwell_vendor.insurance_expires_at).format();
    } else if (body.shipwell_vendor) {
      body.shipwell_vendor.insurance_expires_at = null;
    }

    //don't submit identifying codes array with empty objects
    if (body.shipwell_vendor.identifying_codes && body.shipwell_vendor.identifying_codes.length > 0) {
      body.shipwell_vendor.identifying_codes = body.shipwell_vendor.identifying_codes.filter(
        (value) => !isEmpty(value)
      );
    }
    //Always resolve carrierRelationship promise. If there is a triumphPayPut, resolve that promise also
    const promises = [
      this.props.putCarrierRelationshipsCarrierRelationshipId(this.props.selectedRelationship.id, body)
    ];

    if (triumphPayPut.length > 0) {
      triumphPayPut.forEach((acct, index, object) => {
        if (acct.vendor_factor_company === null) {
          // remove null factor_company key
          delete acct.vendor_factor_company;
        }
        // remove null billing_address key
        if (acct.vendor_billing_address === null) {
          delete acct.vendor_billing_address;
        }
        // don't put bank account items if not updating routing/account numbers
        if (!(acct.payment_type === 'ACH_BANK') || (acct.payment_type === 'ACH_BANK' && 'bank_account_data' in acct)) {
          promises.push(this.props.putTriumphPayAccountDetails(this.props.triumphPay.id, acct.id, acct));
        }
      });
    }
    if (triumphPayPost.length > 0) {
      triumphPayPost.forEach((acct) => {
        //send id key from dropdown selection on post
        acct.payment_type = acct.payment_type.id;
        promises.push(this.props.postTriumphPayAccount(this.props.triumphPay.id, acct));
      });
    }
    return Promise.all(promises).catch((error) => {
      const errors = error.field_errors || [];
      let myError = {
        vendor: {shipwell_vendor: {billing_address: {}, identifying_codes: []}},
        point_of_contacts: []
      };
      const submissionError = {
        vendor: {shipwell_vendor: {billing_address: {}, identifying_codes: []}},
        point_of_contacts: []
      };
      myError = unpackErrors(errors, myError);
      myError._error = error.error_description;
      //handle edge cases for errors here
      //phone number if phone number was not imported correctly
      if (myError.shipwell_vendor) {
        if (myError.shipwell_vendor.primary_phone_number === 'Enter a valid phone number.') {
          submissionError._error = "Carrier company's phone number is not valid";
        } else {
          submissionError._error = myError.shipwell_vendor.primary_phone_number;
        }
      }
      //poc errors
      const pocError = errors.find((el) => el.field_name === 'point_of_contacts');
      if (Array.isArray(errors) && pocError && Array.isArray(pocError.field_errors)) {
        submissionError.point_of_contacts = pocError.field_errors;
      }
      //billing address errors
      if (myError.vendor.shipwell_vendor.billing_address) {
        if (myError.vendor.shipwell_vendor.billing_address.city) {
          submissionError.vendor.shipwell_vendor.billing_address = myError.vendor.shipwell_vendor.billing_address.city;
        }
        if (myError.vendor.shipwell_vendor.billing_address.state_province) {
          submissionError.vendor.shipwell_vendor.billing_address =
            myError.vendor.shipwell_vendor.billing_address.state_province;
        }
        if (myError.vendor.shipwell_vendor.billing_address.country) {
          submissionError.vendor.shipwell_vendor.billing_address =
            myError.vendor.shipwell_vendor.billing_address.country;
        }
      }
      if (isEmpty(submissionError.vendor.shipwell_vendor.billing_address)) {
        delete submissionError.vendor.shipwell_vendor.billing_address;
      }

      //identifying codes errors
      //catch errors if these fields arent in edit mode
      const icError = errors.find((el) => el.field_name === 'shipwell_vendor.identifying_codes');
      if (Array.isArray(errors) && icError) {
        submissionError._error = 'Invalid Identifying Code';
      }

      if (myError._error && !submissionError._error) {
        submissionError._error = myError._error;
      }
      throw new SubmissionError(submissionError);
      return error;
    });
  }

  fetchUsers(id) {
    this.props.getCompanyUsers(id, {
      page: 1,
      pageSize: 1000,
      ordering: 'freight_authority__company__name'
    });
  }

  getVendorInfo(vendorId) {
    this.props.searchForCarrierByID(vendorId).then((response) => {
      if (response.status === 200) {
        if (response.details && response.details.results && response.details.results.length > 0) {
          this.setState({
            currentTags: response.details.results[0].tags
          });
          if (this.state.canViewUsers) {
            this.fetchUsers(this.props.company.id);
          }
          this.props.fetchCarrierDocuments(response.details.results[0].id);
          this.props
            .getCarrierRelationshipsCarrierRelationshipId(response.details.results[0].id)
            .then((carrierRelationshipResponse) => {
              if (carrierRelationshipResponse.status === 200) {
                browserHistory.push(`/carriers/${response.details.results[0].id}`);
              }
            });
        }
      }
    });
  }

  switchScreen(screen) {
    this.setState({currentScreen: screen});
  }

  renderVendorModal() {
    if (this.state.currentScreen === 'manual') {
      return <Vendor isManual showInModal handleOnComplete={this.handleOnComplete} closeModal={this.closeModal} />;
    }
    if (this.state.currentScreen === 'new') {
      return <Vendor showInModal handleOnComplete={this.handleOnComplete} closeModal={this.closeModal} />;
    }
    return <SearchVendor showInModal switchScreen={this.switchScreen} />;
  }

  triggerAppDownloadLink() {
    this.props.requestAppDownload(this.props.shipment.id).then((response) => {
      if (response && response.status === 200) {
        this.setState({showDriverSuccess: true});
        setTimeout(() => {
          this.setState({showDriverSuccess: false});
        }, 2000);
      }
    });
  }

  triggerAppTracking() {
    this.props.startAppTracking(this.props.shipment.id).then((response) => {
      if (response.status === 200) {
        this.setState({showStartedTrackingSuccess: true});
      }
    });
  }

  triggerApplessTracking() {
    const driver_phone =
      this.props.equipmentConfig && this.props.equipmentConfig.driver
        ? this.props.equipmentConfig.driver.phone_number
        : '';
    this.props.requestApplessPing(this.props.shipment.id, {driver_phone: driver_phone}).then((response) => {
      if (response && response.status === 200) {
        this.setState({showDriverSuccess: true});
        setTimeout(() => {
          this.setState({showDriverSuccess: false});
        }, 2000);
      }
    });
  }

  closeModal() {
    this.setState({showCarrierSearchModal: false});
  }

  render() {
    const {canUpdateShipment, canViewCarrier, isCurrentCarrier, showPowerUnitWarningToast} = this.state;
    const {
      carrier,
      vendor,
      carrierNotes,
      equipmentConfig,
      isLTL,
      shipment,
      selectedEquipmentType,
      selectedMode,
      selectedServiceLevel,
      unitPreferences = {},
      externalForm,
      isManualBook
    } = this.props;

    let vendorName,
      vendorEmail,
      vendorPhone,
      driverEmail,
      driverName,
      driverPhone,
      driverPin,
      equipmentType,
      mode,
      serviceLevel,
      usdotNumber,
      driverPlatform,
      driverLastOpenedApp,
      allowEditCarrier = true,
      serviceUrl = null,
      powerUnit = null,
      powerUnitStatus = null,
      trailer = null,
      isLTLInstantRate = false;

    if (selectedEquipmentType) {
      equipmentType = selectedEquipmentType;
    }

    serviceLevel = get(selectedServiceLevel, 'description');

    mode = get(selectedMode, 'description');

    if (
      this.props.one &&
      this.props.one.most_recently_awarded_quote &&
      this.props.one.most_recently_awarded_quote.source_type === 'INSTANT' &&
      this.props.one.most_recently_awarded_quote.mode.id !== 1
    ) {
      isLTLInstantRate = true;
      allowEditCarrier = false;
    }
    if (vendor) {
      //if the mode is LTL/VLTL and this is an instant rate, we want to display the carrier, not the vendor
      if (
        ((selectedMode && selectedMode.id === 2) || (selectedMode && selectedMode.id === 4)) &&
        carrier &&
        isLTLInstantRate
      ) {
        //specifically check if the awarded quote is an instant rate

        vendorName = carrier.name;
        vendorEmail = carrier.primary_email;
        vendorPhone = carrier.primary_phone_number;
        serviceUrl = carrier.carrier ? carrier.carrier.service_center_lookup_url : null;

        if (carrier.identifying_codes) {
          const dot = carrier.identifying_codes.filter((e) => e.type === 'USDOT');
          if (dot.length > 0) {
            usdotNumber = dot[0].value;
          } else {
            usdotNumber = '';
          }
        }
      } else {
        if (vendor.vendor) {
          vendorName = vendor.vendor.name;
          vendorEmail = vendor.vendor.primary_email;
          vendorPhone = vendor.vendor.primary_phone_number;
          serviceUrl = vendor.vendor.carrier ? vendor.vendor.carrier.service_center_lookup_url : null;
          if (vendor.vendor.identifying_codes) {
            const dot = vendor.vendor.identifying_codes.filter((e) => e.type === 'USDOT');
            if (dot.length > 0) {
              usdotNumber = dot[0].value;
            } else {
              usdotNumber = '';
            }
          }
        }
      }
    } else {
      if (
        ((selectedMode && selectedMode.id === 2) || (selectedMode && selectedMode.id === 4)) &&
        carrier &&
        isLTLInstantRate
      ) {
        vendorName = carrier.name;
        vendorEmail = carrier.primary_email;
        vendorPhone = carrier.primary_phone_number;
        serviceUrl = carrier.carrier ? carrier.carrier.service_center_lookup_url : null;

        if (carrier.identifying_codes) {
          const dot = carrier.identifying_codes.filter((e) => e.type === 'USDOT');
          if (dot.length > 0) {
            usdotNumber = dot[0].value;
          } else {
            usdotNumber = '';
          }
        }
      }
    }

    if (externalForm && !isManualBook) {
      vendorName = shipment.current_carrier ? shipment.current_carrier.name : '--';
      vendorEmail = shipment.current_carrier ? shipment.current_carrier.primary_email : null;
      vendorPhone = shipment.current_carrier ? shipment.current_carrier.primary_phone_number : null;
      serviceUrl =
        shipment.current_carrier && shipment.current_carrier.carrier
          ? shipment.current_carrier.carrier.service_center_lookup_url
          : null;
    }

    if (equipmentConfig) {
      // driver
      if (equipmentConfig.driver) {
        driverPhone = equipmentConfig.driver.phone_number;
        driverPin = equipmentConfig.driver.pin;
        driverLastOpenedApp = equipmentConfig.driver.app_last_opened_at;
        driverPlatform = equipmentConfig.driver.platform;
        // driver user

        if (equipmentConfig.driver.user) {
          driverName = `${equipmentConfig.driver.user.first_name} ${equipmentConfig.driver.user.last_name}`;
          driverEmail = equipmentConfig.driver.user.email;
        }
      }
      if (equipmentConfig.power_unit) {
        powerUnit = equipmentConfig.power_unit.name;
        powerUnitStatus = equipmentConfig.power_unit.status;
      }
      if (equipmentConfig.trailer) {
        trailer = equipmentConfig.trailer.name;
      }
    }
    const verifyRemoveVendor = (
      <Popover id="popover-remove-vendor" className="popover-remove-vendor">
        <p>Are you sure you want to remove this carrier?</p>
        <Button bsSize="small" bsStyle="primary" style={{width: '100%'}} onClick={(e) => this.unassignCarrier(e)}>
          Yes
        </Button>
      </Popover>
    );

    const isShipwellUser = this.props.company && this.props.company.is_shipwell;
    const canSeeTrackingOptions =
      this.props.company &&
      this.props.company.feature_flags &&
      this.props.company.feature_flags.can_initiate_mobile_ping_tracking;

    const hasDriver = driverPhone ? true : false;

    let selectedCarrierPOC = null;

    if (this.state.vendorPOCs && this.props.one && this.props.one.relationship_to_vendor) {
      selectedCarrierPOC = this.state.vendorPOCs.filter(
        (e) => e.id === this.props.one.relationship_to_vendor.vendor_point_of_contact
      )[0];
    }
    return (
      <div className="shipment-carrier">
        <Paper className="shipment__card">
          <h2 className="shipment-carrier--title">
            <span>{isCurrentCarrier || !canViewCarrier ? 'Equipment' : 'Carrier & Equipment'}</span>
            {canUpdateShipment && (
              <Link className={`btn-action`} onClick={() => this.editCarrier()}>
                <i className="flaticon-edit   action-link" title="edit" />
              </Link>
            )}

            {!this.props.is_quoting_limited_user && canViewCarrier && !externalForm && (
              <Button
                bsStyle="link"
                type="button"
                onClick={this.createVendor}
                className="btn-tertiary add-vendor"
                name="add-vendor"
              >
                <i className="flaticon-plus pad-right" /> Create Carrier
              </Button>
            )}
          </h2>
          <div className={`form-update-carrier ${this.state.editCarrier ? 'show' : 'hide'}`}>
            {this.renderEditCarrier(allowEditCarrier)}
          </div>
          <div className={`${this.state.editCarrier ? 'hide' : 'show lineitem-col--readonly'}`}>
            {!this.props.is_quoting_limited_user && canViewCarrier && (
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Name</span>
                <div className="shipment-carrier--contact">
                  <p>
                    {vendorName ? (
                      <span className="capitalize">
                        <a
                          role="button"
                          disabled={!this.props.carrierRelationships}
                          onClick={() => {
                            this.props.clearSelectedCarrier();
                            if (
                              ((selectedMode && selectedMode.id === 2) || (selectedMode && selectedMode.id === 4)) &&
                              carrier
                            ) {
                              this.getVendorInfo(carrier.id);
                            } else if (vendor && vendor.vendor) {
                              this.getVendorInfo(vendor.vendor.id);
                            }
                          }}
                        >
                          {vendorName}
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        {allowEditCarrier && canUpdateShipment && (
                          <OverlayTrigger rootClose trigger="click" placement="top" overlay={verifyRemoveVendor}>
                            <Button bsSize="small" bsStyle="default">
                              <i className="icon icon-x-small pad-right" />
                              Remove
                            </Button>
                          </OverlayTrigger>
                        )}
                      </span>
                    ) : (
                      '--'
                    )}
                  </p>

                  {serviceUrl && (
                    <p>
                      <a className="carrier-url" target="_blank" rel="noopener noreferrer" href={serviceUrl}>
                        Service Center Lookup
                      </a>
                    </p>
                  )}
                </div>
              </div>
            )}
            {canViewCarrier && (
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Status</span>
                <div className={`shipment-carrier--status ${shipment?.relationship_to_vendor?.carrier_status}`}>
                  {startCaseToLower(shipment?.relationship_to_vendor?.carrier_status || '--')}
                </div>
              </div>
            )}
            {!isCurrentCarrier && canViewCarrier && (
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Contact</span>
                {selectedCarrierPOC ? (
                  <div className="shipment-carrier--contact">
                    <p className="shipment-carrier--contact-user">
                      <i className="flaticon-user" />{' '}
                      {selectedCarrierPOC.first_name +
                        ' ' +
                        (selectedCarrierPOC.last_name ? selectedCarrierPOC.last_name : '')}
                    </p>
                    {selectedCarrierPOC.email ? (
                      <p>
                        <i className="icon icon-Mail" />{' '}
                        <b>
                          <a href={`mailto:${selectedCarrierPOC.email}`}>{selectedCarrierPOC.email}</a>
                        </b>
                      </p>
                    ) : (
                      '--'
                    )}
                    {selectedCarrierPOC.phone_number && (
                      <p>
                        <i className="icon icon-Phone2" /> {getPhoneHyperlink(selectedCarrierPOC.phone_number)}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="shipment-carrier--contact">
                    {vendorEmail ? (
                      <p>
                        <i className="icon icon-Mail" />{' '}
                        <b>
                          <a href={`mailto:${vendorEmail}`}>{vendorEmail}</a>
                        </b>
                      </p>
                    ) : (
                      '--'
                    )}
                    {vendorPhone && (
                      <p>
                        <i className="icon icon-Phone2" /> {getPhoneHyperlink(vendorPhone)}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {!isCurrentCarrier && canViewCarrier && (
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">USDOT #</span>
                <p>{usdotNumber ? usdotNumber : '--'}</p>
              </div>
            )}
            {allowEditCarrier && this.state.canUpdateEquipmentConfig && (
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Driver #</span>
                <div className="shipment-carrier--contact">
                  {driverName && <b>{driverName}</b>}
                  {driverPhone ? (
                    <div>
                      <i className="icon icon-Phone2" /> {getPhoneHyperlink(driverPhone)}
                      <OverlayTrigger
                        rootClose
                        placement="top"
                        overlay={
                          <Tooltip id="driver-app-info">
                            <span>
                              {driverPlatform && driverLastOpenedApp
                                ? 'Driver last opened the app: ' +
                                  formatDateTime(driverLastOpenedApp, false, moment.tz.guess())
                                : !driverPlatform && driverLastOpenedApp
                                ? 'Driver has disabled notifications. Driver last opened the app: ' +
                                  formatDateTime(driverLastOpenedApp, false, moment.tz.guess())
                                : 'Driver has not installed the Shipwell app.'}
                            </span>
                          </Tooltip>
                        }
                      >
                        <span>
                          {driverPlatform ? (
                            driverPlatform === 'ANDROID' ? (
                              <img
                                className="pad-left"
                                height={20}
                                width={20}
                                alt="Android"
                                src="/images/android.svg"
                              />
                            ) : (
                              <img className="pad-left" height={20} width={20} alt="iOS" src="/images/ios.svg" />
                            )
                          ) : (
                            <i className="flaticon-error text-danger pad-left" />
                          )}
                        </span>
                      </OverlayTrigger>
                      {canSeeTrackingOptions && hasDriver && (
                        //show options to start appless tracking and send app download link
                        <span className="shipment-carrier-tracking">
                          <DropdownButton id="tracking" bsStyle="primary" title="Shipwell App Tracking">
                            <MenuItem onSelect={this.triggerAppDownloadLink} eventKey="1">
                              Send Install Request
                            </MenuItem>
                            <MenuItem onSelect={this.triggerAppTracking.bind(this)} eventKey="1">
                              Start Tracking
                            </MenuItem>
                            {/*<MenuItem onSelect={this.triggerApplessTracking} eventKey="2">
                                                          By Cell Phone Tracking
                                                        </MenuItem>*/}
                          </DropdownButton>
                        </span>
                      )}
                      {this.state.showDriverSuccess && <span className="text-success">Sent!</span>}
                    </div>
                  ) : (
                    '--'
                  )}
                  {/*isShipwellUser && (
                    <p className="shipment-carrier__disclaimer">
                      Note: Cell phone tracking supported carriers are Verizon, T-Mobile, AT&T, Virgin Mobile, Metro
                      PCS, and Boost Mobile.
                    </p>
                  )*/}
                  {driverPin && <p>PIN: {driverPin}</p>}
                  {isCurrentCarrier && driverEmail && (
                    <p>
                      <i className="icon icon-Mail" />
                      {driverEmail}
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Mode</span>
              <p>{mode ? mode : '--'}</p>
            </div>

            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Equipment</span>
              <p>{equipmentType ? equipmentType.name : '--'}</p>
            </div>

            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Power Unit</span>
              <p className="shipmentDetails__row-powerUnit">
                {powerUnit || '--'}
                {powerUnit && powerUnitStatus !== 'Active' && (
                  <ShipwellTooltip
                    tooltipClassname="shipmentDetails__row-powerUnit-tooltip"
                    tooltipContent={
                      'Power Unit is not active in assigned carrier. Please contact the carrier to confirm Power Unit number.'
                    }
                  >
                    <SvgIcon name="ErrorOutlined" color="sw-error" />
                  </ShipwellTooltip>
                )}
              </p>
            </div>

            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Trailer</span>
              <p>{trailer ? trailer : '--'}</p>
            </div>

            <div className="shipment-carrier__col">
              {equipmentType && reeferTypes.includes(equipmentType.id) && (
                <div className="shipmentDetails__row">
                  <span className="shipmentDetails__row-title">Minimum Temperature</span>
                  <p>
                    {shipment.temperature_lower_limit || shipment.temperature_lower_limit === 0
                      ? unitPreferences.system === METRIC
                        ? Math.round(convertFahrenheitToCelsius(shipment.temperature_lower_limit)) + '\xB0 C'
                        : `${shipment.temperature_lower_limit} ℉`
                      : '--'}
                  </p>
                </div>
              )}
              {equipmentType && reeferTypes.includes(equipmentType.id) && (
                <div className="shipmentDetails__row">
                  <span className="shipmentDetails__row-title">Maximum Temperature</span>
                  <p>
                    {shipment.temperature_upper_limit || shipment.temperature_upper_limit === 0
                      ? unitPreferences.system === METRIC
                        ? Math.round(convertFahrenheitToCelsius(shipment.temperature_upper_limit)) + '\xB0 C'
                        : `${shipment.temperature_upper_limit} ℉`
                      : '--'}
                  </p>
                </div>
              )}
            </div>

            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">Service Level</span>
              <p>{this.props.awardedQuote || serviceLevel ? serviceLevel : '--'}</p>
            </div>

            <div className="shipmentDetails__row">
              <span className="shipmentDetails__row-title">
                {unitPreferences.system === METRIC ? 'Total Kilometers' : 'Total Miles'}
              </span>
              <p>
                {shipment && shipment.total_miles && !isNaN(shipment.total_miles)
                  ? unitPreferences.system === METRIC
                    ? convertMilesToKilometers(shipment.total_miles, 3)
                    : formatMileage(shipment.total_miles, 3)
                  : '--'}{' '}
                {shipment && shipment.total_miles && isLTL ? '(estimated)' : ''}
              </p>
            </div>

            {this.props.awardedQuote && this.props.awardedQuote.transit_days && (
              <div className="shipmentDetails__row">
                <span className="shipmentDetails__row-title">Transit Days</span>
                <p>
                  {this.props.awardedQuote.transit_days + (this.props.awardedQuote.transit_days > 1 ? ' days' : ' day')}
                </p>
              </div>
            )}

            <div className="shipmentDetails__row">
              <p className="shipmentDetails__row-title">Notes for Carrier</p>
              <p>{carrierNotes ? carrierNotes : '--'}</p>
            </div>
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={this.state.showStartedTrackingSuccess}
            autoHideDuration={null}
            onClose={() => this.setState({showStartedTrackingSuccess: false})}
            children={
              <div className="shipmentDetails__loadboardToast">
                <div className="shipmentDetails__loadboardToast-header">
                  <i className="flaticon-multiply" onClick={() => this.setState({showStartedTrackingSuccess: false})} />
                </div>
                <div className="shipmentDetails__loadboardToast-body">
                  <span>
                    <i className="flaticon-check_filled text-success" />
                  </span>
                  <span className="shipmentDetails__loadboardToast-content">
                    <p className="text-success">
                      <b>Tracking Request Sent!</b>
                    </p>
                  </span>
                </div>
              </div>
            }
          />

          <InfoModalWrapper
            show={this.state.showCarrierSearchModal}
            bsSize="large"
            onHide={() => {
              this.setState({
                showCarrierSearchModal: false,
                currentScreen: 'search'
              });
            }}
            title="Create Carrier"
            children={this.renderVendorModal()}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    company: state.auth.company,
    one: state.shipmentdetails.one,
    carrier: state.shipmentdetails.carrier,
    vendor: state.shipmentdetails.vendor,
    shipmentVendorEquipmentUpdateForm: state.form.shipmentVendorEquipmentUpdateForm,
    carrierRelationships: state.vendors.carrierRelationships,
    selectedCarrier: state.vendors.selectedCarrier,
    selectedRelationship: state.vendors.selectedRelationship,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    identifyingCodes: state.users.identifyingCodes,
    carrierTags: state.vendors.carrierTags,
    manageVendorsForm: state.form.ManageVendorsForm,
    companyUsers: state.users.companyUsers,
    unitPreferences: state.userCompany.unitPreferences,
    triumphPay: state.vendors.triumphPay
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...vendorActions,
      ...shipmentActions,
      ...shipmentdetailsActions,
      ...userActions,
      ...documentActions,
      startAppTracking,
      putTriumphPayAccountDetails,
      postTriumphPayAccount
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentVendorEquipment);
