import {
  Configuration,
  RateTablesApi,
  RateTablesApiQuotingRateTablesFuelSurchargeTablesGetRequest,
  FuelSurchargeTable,
  PaginatedFuelSurchargeTable
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createRateTablesApi() {
  return new RateTablesApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

export type CorrectedFuelSuchargeTable = FuelSurchargeTable & {id: string};
type CorrectedPaginatedFuelSurchargeTable = Omit<PaginatedFuelSurchargeTable, 'results'> & {
  results?: CorrectedFuelSuchargeTable[];
};

export const getFuelSurchargeTables = async ({
  page = 1,
  pageSize = 50,
  ordering,
  q
}: RateTablesApiQuotingRateTablesFuelSurchargeTablesGetRequest) => {
  const axiosResponse = await createRateTablesApi().quotingRateTablesFuelSurchargeTablesGet({
    page,
    pageSize,
    ordering,
    q
  });
  return axiosResponse.data as CorrectedPaginatedFuelSurchargeTable;
};

export const deleteFuelSurchargeTable = (fuelSurchargeTableId: string) => {
  return createRateTablesApi().quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdDelete({fuelSurchargeTableId});
};

export const getFuelSurchargeTable = async (fuelSurchargeTableId: string) => {
  const axiosResponse = await createRateTablesApi().quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdGet({
    fuelSurchargeTableId
  });
  return axiosResponse.data as CorrectedFuelSuchargeTable;
};
