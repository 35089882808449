import {useCallback, useEffect} from 'react';
import {Field, useFormikContext} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {getFuelSurchargeTables} from 'App/api/rateTables/typed';

const FuelSurchargeField = ({disabled}: {disabled?: boolean}) => {
  const {values, setFieldValue} = useFormikContext<{fuel_included?: boolean}>();
  const {fuel_included: fuelIncluded} = values;
  const queryRateTables = (q?: string) => getFuelSurchargeDetails(q);
  const getFuelSurchargeDetails = useCallback(async (q?: string) => {
    const response = await getFuelSurchargeTables({q});
    return response?.results;
  }, []);
  useEffect(() => {
    if (fuelIncluded) {
      setFieldValue('fuel_surcharge_table', null);
    }
  }, [fuelIncluded, setFieldValue]);
  return (
    <Field
      async
      name="fuel_surcharge_table"
      label="Fuel Surcharge Table"
      clearable={false}
      loadOptions={queryRateTables}
      getOptionLabel={(opts?: {name: string}) => opts?.name}
      getOptionValue={(opts?: {id: string}) => opts?.id}
      disabled={fuelIncluded || disabled}
      component={FormikSelect}
    />
  );
};

export default FuelSurchargeField;
