import {Component, createRef} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router';
import _ from 'lodash';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import classNames from 'classnames';
import {compose} from 'recompose';
import {
  Card,
  CollapsibleCardContent,
  Banner,
  Modal,
  Toast,
  TwoColumnLayout,
  TwoColumnLayoutLeft,
  TwoColumnLayoutRight,
  DragDropContainer,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Title
} from '@shipwell/shipwell-ui';
import {MovementDetails} from './components/MovementDetails/MovementDetailsContainer';
import ShipmentFinancials from './ShipmentFinancials';
import ShipmentStops from './ShipmentStops';
import ShipmentLineItems from './ShipmentLineItems';
import ShipmentAuction from './ShipmentAuction';
import {getShipmentDetailsLayoutSchemaDefault, SHIPMENT_LOAD_BOARDS_CARD, LOAD_CARD} from './shipmentDetailsSchema';
import ShipmentSourceCapacity from './ShipmentSourceCapacity';
import {getTenders} from 'App/api/tenders';
import * as addressBookActions from 'App/actions/addressBook';
import * as carrierActions from 'App/actions/vendors';
import * as userActions from 'App/actions/users';
import * as formActions from 'App/actions/forms';
import * as shipmentActions from 'App/actions/shipments';
import * as productActions from 'App/actions/products';
import {fetchPieceTypes} from 'App/actions/_shipments';
import {clearShipmentState} from 'App/actions/_shipmentDetails';
import * as shipmentdetailsActions from 'App/actions/shipmentdetails';
import * as documentActions from 'App/actions/documents';
import * as brokerActions from 'App/actions/brokers';
import * as integrationActions from 'App/actions/integrations';
import {getWorkflowExecutions, getWorkflowExecutionById, getWorkflowTriggeringEvents} from 'App/api/workflows';
import {getShipmentBills, getShipmentInvoices, getFinancialManagementSystems} from 'App/actions/_integrations';
import {getAllSpotNegotiationsByShipment} from 'App/actions/marketplace';
import Cases from 'App/components/Cases/Cases';
import {bind} from 'App/utils/camelize';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import TenderDetails from 'App/containers/Shipment/components/TenderDetails';
import InstantRates from 'App/containers/Marketplace/components/InstantRates';
import WorkflowSummaryCard from 'App/containers/WorkflowSummaryCard';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';
import {getPurchaseOrderByShipment} from 'App/containers/purchaseOrders/actions/async';
import {
  permViewOrders,
  permViewInvoice,
  formatDayOfWeekDateTime,
  addTabVisibilityEvent,
  removeTabVisibilityEvent,
  getTabVisibilityEventTypes
} from 'App/utils/globals';
import './_shipment.scss';
import './_shipment-details.scss';
import ErrorPage from 'App/common/ErrorPage';
import CarrierBidCreateContainer from 'App/containers/carrierBids/create';
import ShipmentCard from 'App/containers/Shipment/components/ShipmentCard';
import Collaboration from 'App/containers/Shipment/components/Collaboration';
import Header from 'App/containers/Shipment/components/Header';
import Marketplace from 'App/containers/Marketplace';
import MarketplaceDetails from 'App/containers/Marketplace/components/MarketplaceDetails';
import {withPageTitle} from 'App/utils/hooks/usePageTitle';
import WithStatusToasts from 'App/components/withStatusToasts';
import withFlags from 'App/utils/withFlags';
import LegacyShipmentTrackingOverview from 'App/components/ShipmentTrackingOverview/LegacyShipmentTrackingOverview';
import ConflictingWorkflows from 'App/containers/ConflictingWorkflows';
import {WORKFLOWS, DETAILS} from 'App/utils/dashboardConstants';
import {VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION} from 'App/components/permissions/PermissionsFallback/constants';
import {ScriptsInvocationStatus} from 'App/components/scriptsInvoationStatus/scriptsInvocationStatus';

let breadcrumbInterval;
let timelineInterval;
let automatedEventsInterval;
let shipmentChangesInterval;
let filterDistance = -1;
let SHIPMENT_DETAILS_TABS_MAP;

const MarketplaceWithRouter = withRouter(Marketplace);
const MarketplaceDetailsWithRouter = withRouter(MarketplaceDetails);

export const SHIPMENT_DETAILS_TABS_WITH_LABELS = (canViewFinancials = true) => {
  const shipmentTabs = [
    {id: DETAILS, label: 'Details'},
    {id: WORKFLOWS, label: 'Workflows'},
    ...(canViewFinancials ? [{id: 'bids', label: 'Bids'}] : []),
    ...(canViewFinancials ? [{id: 'instant-rates', label: 'Rates'}] : []),
    {id: 'tracking', label: 'Tracking'},
    ...(canViewFinancials ? [{id: 'financials', label: 'Financials'}] : []),
    {id: 'source-capacity', label: 'Source Capacity'}
  ];
  SHIPMENT_DETAILS_TABS = shipmentTabs.map((tab) => tab.id);
  SHIPMENT_DETAILS_TABS_MAP = keyBy(SHIPMENT_DETAILS_TABS, (tab) => SHIPMENT_DETAILS_TABS.indexOf(tab));
  return shipmentTabs;
};
export let SHIPMENT_DETAILS_TABS = SHIPMENT_DETAILS_TABS_WITH_LABELS().map((tab) => tab.id);

function getTabNameFromRoute(route) {
  const parts = get(route, 'path', '').split('/');
  const last = parts.pop();
  return SHIPMENT_DETAILS_TABS.indexOf(last) === -1 ? null : last;
}

function getRouteForTabIndex(index) {
  return SHIPMENT_DETAILS_TABS_MAP[index];
}

function getRouteForTabName(tabName) {
  return SHIPMENT_DETAILS_TABS.indexOf(tabName) === -1 ? 'details' : tabName;
}

function getTabIndexForTabName(tabName) {
  const tabIndex = SHIPMENT_DETAILS_TABS.indexOf(getRouteForTabName(tabName));
  return Math.max(0, tabIndex);
}

export class ShipmentDetails extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.messageRef = createRef();

    bind(this, [
      'constructMarkerArray',
      'expandMap',
      'fetchBreadcrumbs',
      'fetchDetails',
      'initialize',
      'clearAlert',
      'triggerCallCancel',
      'handleTabVisibityChange',
      'handleDragEnd',
      'handleCollaborationSelect',
      'handleCollaborationClose',
      'handleTabChange'
    ]);

    this.state = {
      canUpdateShipment: false,
      canViewShipment: true,
      disableCancel: true,
      emailList: [],
      errorMessage: null,
      errorType: null,
      expandMap: false,
      hasError: false,
      isLTL: false,
      isFTL: false,
      isDrayage: false,
      markerArray: [],
      breadcrumbs: [],
      showLoadboardToast: this.props.location && this.props.location.query.loadboard,
      showFTLRateRequested: this.props.location && this.props.location.query.FTLRateRequested,
      showFilterModal: false,
      showFilters: false,
      filterList: [],
      reps: [],
      showTags: false,
      timelinePagination: {
        page: 1,
        pageSize: 1000000
      },
      carrierPagination: {
        page: 1,
        pageSize: 1000000,
        ordering: 'company'
      },
      productsPagination: {
        page: 1,
        pageSize: 1000,
        ordering: 'product_ref',
        q: ''
      },
      tagsPagination: {
        page: 1,
        pageSize: 10000,
        ordering: 'name'
      },
      showCustomerField: true,
      is_quoting_limited_user: false,
      showDeleteCallsModal: false,
      canViewOrders: false,
      tenderDetails: null,
      hasRoutingGuide: false,
      routingGuides: null,
      routingGuideDetails: null,
      loadingRoutingGuides: false,
      layoutSchema: {},
      workflowExecutionErrorDetails: null,
      showAddOrderSuccess: false,
      showAddOrderError: false,
      addOrderErrorTitle: '',
      addOrderErrorMessage: ''
    };
  }

  componentDidMount() {
    // This page is no longer receiving preset state via the Shipment dashboard so it holds onto old shipment state from redux
    // clearing the state on mount so the component knows to fetch the current shipment displayed
    this.props.clearShipmentState();
    const shipmentDetailsLayoutSchemaDefault = getShipmentDetailsLayoutSchemaDefault(this.props.publicLoadboardPost);
    const {router, constructPageTitle, one: shipment, setSuccess} = this.props;
    const {params, location} = router;
    const id = params.shipment_id || 0;

    if (!_.isEmpty(this.props.company) && id) {
      let is_quoting_limited_user = false;
      if (this.props.user && this.props.user.is_quoting_limited_user) {
        is_quoting_limited_user = true;
        this.setState({is_quoting_limited_user: true});
      }
      if (this.props.user && this.props.user.permissions && this.props.user.permissions.includes(permViewOrders)) {
        this.setState({canViewOrders: true});
      }
      this.initialize(id, is_quoting_limited_user);
      //get initial data
      this.getShipmentTimeline();
      this.fetchBreadcrumbs();
    }

    //start polling
    this.handleTabVisibityChange();
    addTabVisibilityEvent(this.handleTabVisibityChange);
    //check if FTL or LTL
    if (this.props.one && this.props.one.mode && this.props.one.mode.id === 1) {
      this.setState({isFTL: true});
    }
    if (this.props.one && this.props.one.mode && (this.props.one.mode.id === 2 || this.props.one.mode.id === 4)) {
      this.setState({isLTL: true});
    }
    if (this.props.one && this.props.one.mode && this.props.one.mode.id === 5) {
      this.setState({isDrayage: true});
    }

    // Layout
    const mergeLayoutSchema = (currentLayoutSchema) => {
      const leftColumnLength = currentLayoutSchema.leftColumn.length;
      const leftColumnDefaultLength = shipmentDetailsLayoutSchemaDefault.leftColumn.length;

      if (leftColumnLength !== leftColumnDefaultLength) {
        const result = shipmentDetailsLayoutSchemaDefault.leftColumn.map((item, index) => {
          const currentItem = currentLayoutSchema.leftColumn.find((element) => element.id === item.id);

          if (currentItem) {
            return currentItem;
          }

          return {
            ...item,
            ordinalIndex: index
          };
        });

        return {
          ...currentLayoutSchema,
          leftColumn: result
        };
      }

      return currentLayoutSchema;
    };

    const userLayoutSchema = JSON.parse(localStorage.getItem('shipmentDetailsLayoutSchema'));

    const layoutSchema = userLayoutSchema ? mergeLayoutSchema(userLayoutSchema) : shipmentDetailsLayoutSchemaDefault;

    this.setState({layoutSchema});

    // Update page title with shipment ref
    if (shipment?.reference_id) {
      constructPageTitle(location, params, shipment.reference_id);
    }

    if (router.location?.query?.showEditDefaultTagsSuccess) {
      setSuccess('User Defaults Updated!', 'User defaults saved successfully.');
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {router, params, constructPageTitle, one: shipment} = this.props;
    const {location} = router;
    const id = params.shipment_id || 0;

    if (id && !_.isEmpty(this.props.company) && prevProps.company !== this.props.company) {
      let is_quoting_limited_user = false;

      if (this.props.user && this.props.user.is_quoting_limited_user) {
        is_quoting_limited_user = true;
        this.setState({is_quoting_limited_user: true});
      }
      if (this.props.user && this.props.user.permissions && this.props.user.permissions.includes(permViewOrders)) {
        this.setState({canViewOrders: true});
      }
      this.props.getPurchaseOrderByShipment(id);
      this.initialize(id, is_quoting_limited_user);
    }
    if (id !== prevProps?.params?.shipment_id) {
      this.props.clearShipmentState();
      this.initialize(id);
    }

    //check if FTL or LTL
    if (prevProps.one && this.props.one && this.props.one.id !== prevProps.one.id) {
      if (this.props.one.mode && this.props.one.mode.id === 1) {
        this.setState({isFTL: true});
      } else {
        this.setState({isFTL: false});
      }
      if (this.props.one.mode && (this.props.one.mode.id === 2 || this.props.one.mode.id === 4)) {
        this.setState({isLTL: true});
      } else {
        this.setState({isLTL: false});
      }
    }

    // Update page title with shipment ref
    if (shipment?.reference_id) {
      constructPageTitle(location, params, shipment.reference_id);
    }
  }

  async initialize(shipmentId, is_quoting_limited_user) {
    this.props.fetchEquipmentTypes();
    this.props.fetchShipmentModes();
    this.props.fetchServiceLevels();
    this.fetchDetails(shipmentId);
    this.props.fetchChargeCategories();
    this.props.fetchPackageTypes();
    if (is_quoting_limited_user || this.state.is_quoting_limited_user) {
      return;
    }
    this.getRoutingGuideDetails(shipmentId);
    this.props.fetchShipmentRepRoles();
    this.props.fetchPieceTypes();
    this.props.locationTypesGet();
    this.props.fetchReps(shipmentId);
    if (this.props.user && !this.props.user.is_quoting_limited_user) {
      this.getShipmentAutomatedEvents();
    }
    if (this.props.user && this.props.user.permissions && this.props.user.permissions.includes(permViewInvoice)) {
      const fmsResult = await this.props.getFinancialManagementSystems();
      if (fmsResult?.ok && fmsResult?.body?.configured_integrations) {
        if (this.props.user.permissions.includes('billing.view_invoices')) {
          this.props.getShipmentInvoices(shipmentId);
        }
        if (this.props.user.permissions.includes('billing.view_billing_info')) {
          this.props.getShipmentBills(shipmentId);
        }
      }
    }
    this.props.fetchIdentifyingCodes();
    //only get company users if you have permission
    if (
      this.props.user &&
      this.props.user.permissions &&
      this.props.user.permissions.includes('users.view_company_users')
    ) {
      this.props.getCompanyUsers(this.props.company.id, {
        page: 1,
        pageSize: 1000,
        ordering: 'freight_authority__company__name'
      });
    }

    this.props.getProducts(this.state.productsPagination);
    this.props.getCarrierTags({
      page: this.state.tagsPagination.page,
      pageSize: this.state.tagsPagination.pageSize,
      ordering: this.state.tagsPagination.ordering
    });
    if (
      this.props.company.brokerage &&
      this.props.user &&
      this.props.user.permissions &&
      this.props.user.permissions.includes('customer_relationships.view')
    ) {
      //determine whether we need to show the customer field
      this.props
        .fetchBrokerShipperRelationshipsForTypeaheads(this.props.company.brokerage.id, {page: 1, pageSize: 5})
        .then((response) => {
          if (
            response &&
            response.status === 200 &&
            response.details &&
            response.details.results &&
            response.details.results.length === 1
          ) {
            this.setState({showCustomerField: false});
          }
        });
    }
  }

  handleTabVisibityChange() {
    const {hidden} = getTabVisibilityEventTypes();

    const isHidden = document[hidden];
    if (isHidden) {
      clearInterval(timelineInterval);
      clearInterval(breadcrumbInterval);
      clearInterval(automatedEventsInterval);
      clearInterval(shipmentChangesInterval);
    } else {
      if (!this.props.vizTimelineRedesign) {
        this.pollShipmentTimeline();
        this.pollShipmentBreadcrumbs();
      }
      this.pollShipmentAutomatedEvents();
    }
  }

  async getRoutingGuideDetails(shipmentId) {
    this.setState({
      loadingRoutingGuides: true
    });
    try {
      //first get all executions that match this shipment id
      const allExecutions = await getWorkflowExecutions({
        shipment_id: shipmentId,
        limit: 100,
        include_bulk_operations: true
      });
      if (allExecutions.data && allExecutions.data?.data.length > 0) {
        //use the first in this list (since it is the most recent)
        const mostRecentExecution = await getWorkflowExecutionById(allExecutions.data.data[0].id);
        this.setState({
          hasRoutingGuide: true,
          routingGuideDetails: mostRecentExecution.data,
          workflowExecutionErrorDetails: null,
          routingGuides: allExecutions.data?.data,
          loadingRoutingGuides: false
        });
      } else {
        // if there are no running workflows, check to see if there is an error
        // preventing one from being automatically executed
        const workflowTriggeringEventsResponse = await getWorkflowTriggeringEvents({
          triggering_resource_ids: shipmentId
        });
        if (workflowTriggeringEventsResponse?.data?.data?.[0]) {
          this.setState({
            hasRoutingGuide: false,
            routingGuideDetails: null,
            workflowExecutionErrorDetails: workflowTriggeringEventsResponse?.data?.data?.[0] ?? null
          });
        }
        this.setState({
          loadingRoutingGuides: false
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        loadingRoutingGuides: false
      });
    }
  }

  pollShipmentTimeline(ms = 60000) {
    timelineInterval = window.setInterval(() => {
      this.getShipmentTimeline();
    }, ms);
  }

  getShipmentTimeline() {
    this.props.getShipmentTimeline(this.props.router.params.shipment_id, this.state.timelinePagination);
  }

  pollShipmentBreadcrumbs(ms = 60000) {
    breadcrumbInterval = window.setInterval(() => {
      //send no args so this defaults to the current zoom level and always fetches
      this.fetchBreadcrumbs();
    }, ms);
  }

  pollShipmentAutomatedEvents(ms = 60000) {
    automatedEventsInterval = window.setInterval(() => {
      this.getShipmentAutomatedEvents();
    }, ms);
  }

  getShipmentAutomatedEvents() {
    if (this.props.user && !this.props.user.is_quoting_limited_user) {
      this.props.getAutomatedEvents(this.props.router.params.shipment_id);
    }
  }

  async fetchDetails(id) {
    await this.props.shipmentsShipmentIdGet(id).then((response) => {
      if (response.status === 200) {
        this.props.getShipmentTimeline(id, this.state.timelinePagination).then(() => this.setMapMarkers());
        this.setState({
          hasError: false,
          errorType: null
        });
        //if this shipment is pending approval, get the spot negotiation details for the user to see
        if (this.props.one && this.props.one.metadata && this.props.one.metadata.pending_tender_acceptance) {
          this.props.getAllSpotNegotiationsByShipment(this.props.one.id).then((response) => {
            if (response.status === 200 && response.details && response.details.results.length > 0) {
              this.setState({tenderDetails: response.details.results.find((e) => e.tenders && e.tenders.length)});
            }
          });
        }

        if (this.props.one && this.props.one.metadata && this.props.one.metadata.pending_tender_acceptance) {
          getTenders({pageSize: 1000, shipmentId: this.props.one.id})
            .then((response) => {
              if (response && response.body) {
                const tender = response.body.results.find(({shipment_id}) => shipment_id === this.props.one.id);

                if (tender) {
                  this.setState({tenderDetails: tender});
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else if (response.status === 400) {
        this.setState({
          hasError: true,
          errorType: '400'
        });
      } else if (response instanceof SyntaxError) {
        this.setState({
          hasError: true,
          errorType: 'SyntaxError'
        });
      }
    });
  }

  fetchBreadcrumbs(distance) {
    distance = distance || null;
    const {one} = this.props;
    // fetch on zoom or on interval using existing zoom
    if (one && ((distance && filterDistance !== distance) || (!distance && filterDistance))) {
      const newDistance = distance ? distance : filterDistance;
      filterDistance = newDistance;
      this.props
        .getBreadcrumbs(
          one.id,
          {
            filterDistance: newDistance
          },
          this._count
        )
        .then(() => {
          this._count++;
        });
    }
  }

  componentWillUnmount() {
    this.props.clearAutomatedEvents();
    removeTabVisibilityEvent(this.handleTabVisibityChange);
    clearInterval(automatedEventsInterval);
    clearInterval(breadcrumbInterval);
    clearInterval(timelineInterval);
    clearInterval(shipmentChangesInterval);
    filterDistance = -1;
  }

  setMapMarkers() {
    if (this.props.one && this.props.one.stops && this.props.one.stops.length > 0) {
      this.constructMarkerArray(this.props.one.stops);
    }
  }

  constructMarkerArray(stops) {
    // grab the lat/lng of the addresses if they exist to put on the map
    const markerArray = [];
    if (stops && stops.length > 0) {
      const stopsArr = stops;
      for (let i = 0; i < stopsArr.length; i++) {
        if (
          stopsArr[i].location.address &&
          stopsArr[i].location.address.latitude &&
          stopsArr[i].location.address.longitude
        ) {
          markerArray.push({
            show: true,
            position: {
              lat: stopsArr[i].location.address.latitude,
              lng: stopsArr[i].location.address.longitude
            },
            info: '<p>' + stopsArr[i].location.address.formatted_address + '</p>',
            markerNumber: i + 1
          });
        }
      }
    }
    this.setState({markerArray: markerArray});
  }

  viewQuotes(hasOpenAuction) {
    if (this.props.one.mode && this.props.one.mode.code === 'PARCEL') {
      this.context.router.push('/marketplace/' + this.props.one.id + '/fedex-rates/');
    } else if (hasOpenAuction || (this.props.one.mode && this.props.one.mode.id === 1)) {
      this.context.router.push('/marketplace/' + this.props.one.id + '/bids');
    } else {
      this.context.router.push('/marketplace/' + this.props.one.id + '/instant-rates/');
    }
  }

  updateShipment(attrs) {
    const shipmentId = this.props.router.params.shipment_id;
    const payload = Object.assign(this.props.one, attrs);
    return this.props.shipmentsShipmentIdPut(shipmentId, payload, {}).then((response) => {
      if (response.status === 200) {
        this.fetchDetails(shipmentId);
      }
    });
  }

  triggerCallCancel() {
    if (this.props.hasFutureScheduledCalls) {
      this.setState({showDeleteCallsModal: true});
    }
  }

  closePopover(id) {
    const popover = document.getElementById(id);
    if (popover) {
      popover.classList.remove('in');
      popover.classList.add('out');
    }
  }

  hideEditButtons() {
    const editable = document.querySelector('.js-show-all-btn-action');
    // show editable briefly
    if (editable) {
      window.setTimeout(() => {
        editable.classList.remove('js-show-all-btn-action');
      }, 3000);
    }
  }

  showLoading() {
    return (
      <div className="shipment-details content-wrapper">
        <RollbarErrorBoundary>
          <ShipwellLoader loading={this.props.isLoading} />
        </RollbarErrorBoundary>
      </div>
    );
  }

  show404() {
    return (
      <ErrorPage
        primaryMessage="Sorry, we couldn't find this shipment."
        secondaryMessage="Please check your URL and try again, or contact your account admin for assistance."
      />
    );
  }

  showError() {
    return (
      <ErrorPage
        primaryMessage="Sorry, there was an error retrieving this shipment."
        secondaryMessage="Please contact your account admin for assistance for assistance if the problem
      persists."
      />
    );
  }

  expandMap() {
    this.setState({expandMap: !this.state.expandMap}, () => {
      // add toggle scrollto
      const className = this.state.expandMap ? '.shipment__map--expanded' : '.shipment__map--collapsed';
      document.querySelector(className).scrollIntoView({behavior: 'smooth', block: 'start'});
    });
  }

  clearAlert() {
    const {one} = this.props;
    this.updateShipment({
      metadata: {
        archived: one.metadata.archived,
        open: one.metadata.open,
        tags: one.metadata.tags,
        alert_message: null,
        alert_level: null
      },
      state: one.state
    });
  }

  handleCloseLoadboardToast() {
    //reset the URL
    window.history.replaceState({}, '', window.location.origin + window.location.pathname);

    this.setState({showLoadboardToast: false});
  }

  handleCloseFTLRateRequestedToast() {
    //reset the URL
    window.history.replaceState({}, '', window.location.origin + window.location.pathname);

    this.setState({showFTLRateRequested: false});
  }

  handleCollaborationSelect(index) {
    const currentLayoutSchema = this.state.layoutSchema;
    const newLayoutSchema = {
      ...currentLayoutSchema,
      sidebar: {
        isOpen: currentLayoutSchema?.sidebar?.selectedIndex === index ? !currentLayoutSchema?.sidebar?.isOpen : true,
        selectedIndex: index
      }
    };

    this.updateSchema(newLayoutSchema);
  }

  handleCollaborationClose() {
    const currentLayoutSchema = this.state.layoutSchema;
    const newLayoutSchema = {
      ...currentLayoutSchema,
      sidebar: {
        ...currentLayoutSchema.sidebar,
        isOpen: false
      }
    };

    this.updateSchema(newLayoutSchema);
  }

  handleDragEnd(newItems, columnId) {
    const currentLayoutSchema = this.state.layoutSchema;
    const newLayoutSchema = {
      ...currentLayoutSchema,
      [columnId]: newItems.map((item, index) => {
        return {
          ...item,
          ordinalIndex: index
        };
      })
    };

    this.updateSchema(newLayoutSchema);
  }

  handleCollapseItem(result, cardId, columnId) {
    const currentLayoutSchema = this.state.layoutSchema;
    const newLayoutSchema = {
      ...currentLayoutSchema,
      [columnId]: currentLayoutSchema[columnId].map((item) =>
        item.id === cardId ? {...item, isCollapsed: result} : item
      )
    };

    this.updateSchema(newLayoutSchema);
  }

  updateSchema(newLayoutSchema) {
    this.setState({
      layoutSchema: newLayoutSchema
    });

    localStorage.setItem('shipmentDetailsLayoutSchema', JSON.stringify(newLayoutSchema));
  }

  renderLoadboardToast() {
    return (
      <div className="shipmentDetails__loadboardToast">
        <div className="shipmentDetails__loadboardToast-header">
          <i className="flaticon-multiply" onClick={this.handleCloseLoadboardToast.bind(this)} />
        </div>
        <div className="shipmentDetails__loadboardToast-body">
          <span>
            <i className="flaticon-check_filled text-success" />
          </span>
          <span className="shipmentDetails__loadboardToast-content">
            <p className="text-success">
              <b>Quotes are on the way!</b>
            </p>
            <p>In the meantime, finish building your shipment.</p>
            <Link to={`/shipments/${this.props.one.id}/my-marketplace/vendors`}>View on Marketplace</Link>
          </span>
        </div>
      </div>
    );
  }

  renderFTLRateRequestedToast() {
    return (
      <div className="shipmentDetails__loadboardToast">
        <div className="shipmentDetails__loadboardToast-header">
          <i className="flaticon-multiply" onClick={this.handleCloseFTLRateRequestedToast.bind(this)} />
        </div>
        <div className="shipmentDetails__loadboardToast-body">
          <span>
            <i className="flaticon-check_filled text-success" />
          </span>
          <span className="shipmentDetails__loadboardToast-content">
            <p className="text-success">
              <b>Rate Request Submitted</b>
            </p>
            <p>Your rate request was successfully submitted and is under review.</p>
            <p>You will be notified once it is approved.</p>
          </span>
        </div>
      </div>
    );
  }

  handleTabChange(index) {
    const {router} = this.props;
    //short-term fix
    //should redirect to the marketplace if user chooses rate tab for parcels
    if (this.props.one.mode && this.props.one.mode.code === 'PARCEL' && index === 3) {
      return router.push({
        pathname: `/marketplace/${this.props.one.id}/fedex-rates`,
        state: {backRoute: `/shipments/${this.props.one.id}`}
      });
    }
    router.push(`/shipments/${router.params.shipment_id}/${getRouteForTabIndex(index)}`);
  }

  render() {
    const {
      isLoading,
      one,
      purchaseOrders,
      featureFlags = {},
      route,
      company,
      vizTimelineRedesign,
      user,
      stmFinancialTenderingUserPermission,
      airMode
    } = this.props;
    const hasError = this.state.hasError;
    if (hasError && this.state.errorType === '400') {
      return this.show404();
    }
    if (hasError && this.state.errorType === 'SyntaxError') {
      return this.showError();
    }
    if (isLoading || !one) {
      return this.showLoading();
    }

    const hasOpenAuction = one?.metadata?.has_open_auction;
    const isFTL = one && one.mode && one.mode.id === 1;
    const isLTL = one && one.mode && (one.mode.id === 2 || one.mode.id === 4);
    this.hideEditButtons();
    const pendingApproval = true; //TODO real logic here
    const {tenderDetails, routingGuides, workflowExecutionErrorDetails, loadingRoutingGuides, layoutSchema} =
      this.state;
    const canViewFinancials = stmFinancialTenderingUserPermission
      ? user.permissions.includes(VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION)
      : true;
    const leftColumnItems =
      layoutSchema?.leftColumn
        ?.filter((card) => (!canViewFinancials ? ![SHIPMENT_LOAD_BOARDS_CARD, LOAD_CARD].includes(card.id) : true))
        .sort((a, b) => a.ordinalIndex - b.ordinalIndex) || [];
    const showCapacitySearch = this.props.publicLoadBoardCapacitySearch;
    const {alert_message, alerts = []} = one?.metadata;
    const allAlertMessages = [];

    if (alert_message) {
      allAlertMessages.push(
        <span key="alert_message">
          {alert_message}
          <br />
        </span>
      );
    }

    alerts.forEach((alert) => {
      if (alert.message) {
        allAlertMessages.push(
          <span key={alert.id}>
            {alert.message}
            <br />
          </span>
        );
      }
    });
    return (
      <div className="shipment-details content-wrapper js-show-all-btn-action">
        <RollbarErrorBoundary>
          <Header hasError={hasError} fetchDetails={this.fetchDetails} routeTo="/" />
        </RollbarErrorBoundary>
        {allAlertMessages?.length > 0 ? (
          <div className="m-0 pt-1">
            <Banner title={allAlertMessages} onClose={this.clearAlert} />
          </div>
        ) : null}
        {!hasError && (
          <TwoColumnLayout className="shipment shipmentWorkspace" leftColumnMaxWidth="412px" gridGap="4px">
            <TwoColumnLayoutLeft>
              <div className="shipment__info">
                <DragDropContainer
                  className="grid grid-cols-1 gap-2"
                  items={leftColumnItems}
                  droppableId="leftColumn"
                  direction="vertical"
                  onDragEnd={(newItems) => this.handleDragEnd(newItems, 'leftColumn')}
                  onCollapse={(result, cardId) => this.handleCollapseItem(result, cardId, 'leftColumn')}
                  Component={ShipmentCard}
                  shipment={one}
                  canViewOrders={this.state.canViewOrders}
                  showCustomerField={this.state.showCustomerField}
                  showShipmentReps
                  shipmentId={one.id}
                  fetchDetails={this.fetchDetails}
                  purchaseOrders={purchaseOrders.purchaseOrdersByShipment[one.id]}
                  awardedQuote={null}
                  //TODO get the object of vendor assigned
                  vendorAssignment={this.props.one.relationship_to_vendor}
                  carrierNotes={this.props.one.notes_for_carrier}
                  carrierRelationships={this.props.carrierRelationships}
                  company={this.props.company}
                  equipmentConfig={this.props.one.equipment_config}
                  equipmentTypes={this.props.equipmentTypes}
                  isLTL={isLTL}
                  selectedEquipmentType={this.props.one.equipment_type}
                  selectedMode={this.props.one.mode}
                  selectedServiceLevel={this.props.one.service_level}
                  serviceLevels={this.props.serviceLevels}
                  shipmentModes={this.props.shipmentModes}
                  triggerCallCancel={this.triggerCallCancel}
                  user={user}
                />
              </div>
            </TwoColumnLayoutLeft>
            <TwoColumnLayoutRight className="shipment__main-container" collapseRight>
              <div className="shipment__workspace">
                <Tabs onSelect={this.handleTabChange} selectedIndex={getTabIndexForTabName(getTabNameFromRoute(route))}>
                  <TabList className="p-4 pb-0">
                    <div className="flex flex-wrap gap-y-2">
                      {SHIPMENT_DETAILS_TABS_WITH_LABELS(canViewFinancials).map((tab) => {
                        if (!showCapacitySearch && tab.id === 'source-capacity') {
                          return null;
                        }
                        return <Tab key={tab.id}>{tab.label}</Tab>;
                      })}
                    </div>
                  </TabList>
                  <TabPanel className="shipment__tab">
                    {airMode && one?.mode?.code === 'AIR' && <MovementDetails shipment={one} />}
                    <div className="p-2">
                      <Card title="Shipment Stops" isCollapsible>
                        <CollapsibleCardContent>
                          <RollbarErrorBoundary errorMessage="There was an error displaying stops">
                            <ShipmentStops
                              one={one}
                              isFTL={isFTL}
                              isLTL={isLTL}
                              canViewOrders={this.state.canViewOrders}
                              shipmentAccessorials={one.accessorials}
                              shipmentId={one.id}
                              stops={one.stops}
                              fetchDetails={this.fetchDetails}
                              constructMarkerArray={this.constructMarkerArray}
                              triggerCallCancel={this.triggerCallCancel}
                              setAddOrderSuccess={() => {
                                this.setState({showAddOrderSuccess: true});
                                this.fetchDetails();
                              }}
                              setAddOrderError={(errorTitle, errorMessage) => {
                                this.setState({
                                  showAddOrderError: true,
                                  addOrderErrorTitle: errorTitle,
                                  addOrderErrorMessage: errorMessage
                                });
                              }}
                            />
                          </RollbarErrorBoundary>
                        </CollapsibleCardContent>
                      </Card>
                    </div>

                    {hasOpenAuction && (
                      <div className="p-2">
                        <RollbarErrorBoundary>
                          <ShipmentAuction one={one} />
                        </RollbarErrorBoundary>
                      </div>
                    )}

                    {pendingApproval && tenderDetails && (
                      <div className="p-2">
                        <RollbarErrorBoundary>
                          <TenderDetails tenderDetails={tenderDetails} shipment={one} />
                        </RollbarErrorBoundary>
                      </div>
                    )}

                    {one.id &&
                      purchaseOrders.purchaseOrdersByShipment[one.id] &&
                      purchaseOrders.purchaseOrdersByShipment[one.id].length > 0 &&
                      this.state.canViewOrders && (
                        <div className="p-2">
                          <Card title="Orders">
                            {purchaseOrders.purchaseOrdersByShipment[one.id].map((order, index) => (
                              <Grid container style={{padding: '20px 0 0'}} key={index}>
                                <Grid item xs={12}>
                                  <p>
                                    <strong>Description</strong> {order.description}
                                  </p>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <p>
                                    <strong>Order</strong> #{order.order_number}
                                  </p>
                                  <p>
                                    <strong>Source</strong> {order.source || '--'}
                                  </p>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <p>
                                    <strong>Origin</strong> {order.origin_address.formatted_address || '--'}
                                  </p>
                                  <p>
                                    <strong>Destination</strong> {order.destination_address.formatted_address || '--'}
                                  </p>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <p>
                                    <strong>Supplier ID</strong> {order.supplier_external_id || '--'}
                                  </p>
                                  <p>
                                    <strong>Description</strong> {order.description || '--'}
                                  </p>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <p>
                                    <strong>Created</strong> {formatDayOfWeekDateTime(order.created_at)}
                                  </p>
                                </Grid>
                              </Grid>
                            ))}
                          </Card>
                        </div>
                      )}

                    <div className="shipment__items">
                      <RollbarErrorBoundary errorMessage="There was an error displaying the line items">
                        <ShipmentLineItems
                          isFTL={isFTL}
                          isLTL={isLTL}
                          closePopover={this.closePopover}
                          canViewOrders={this.state.canViewOrders}
                          shipmentId={one.id}
                          lineitems={one.line_items}
                          fetchDetails={this.fetchDetails}
                          packageTypes={this.props.packageTypes}
                          company={this.props.company}
                        />
                      </RollbarErrorBoundary>
                    </div>
                  </TabPanel>
                  <TabPanel className="shipment__tab">
                    <div className="flex flex-col gap-2">
                      <div className="rounded-md border border-sw-border bg-sw-background p-4">
                        <Title>Workflows</Title>
                        <div className="relative flex flex-col gap-4 pt-4">
                          {workflowExecutionErrorDetails ? (
                            <ConflictingWorkflows
                              errorDetails={workflowExecutionErrorDetails}
                              onSelectRoutingGuide={this.initialize}
                              shipmentId={this.props.params.shipment_id}
                            />
                          ) : loadingRoutingGuides ? (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <ShipwellLoader loading />
                            </div>
                          ) : routingGuides?.length > 0 ? (
                            routingGuides.map((routingGuide) => (
                              <WorkflowSummaryCard
                                key={routingGuide.id}
                                summary={routingGuide}
                                isMobileView={layoutSchema?.sidebar?.isOpen}
                              />
                            ))
                          ) : (
                            <Title variant="emptyStateHeader" className="flex justify-center pb-4">
                              No Workflows on this Shipment
                            </Title>
                          )}
                        </div>
                      </div>

                      <ScriptsInvocationStatus resource={'shipment'} resourceId={one.id} />
                    </div>
                  </TabPanel>
                  {canViewFinancials ? (
                    <TabPanel className="shipment__tab">
                      <MarketplaceWithRouter params={{shipment_id: one.id}} hideTabs hideShipmentInfo>
                        <MarketplaceDetailsWithRouter params={{shipment_id: one.id}} />
                      </MarketplaceWithRouter>
                    </TabPanel>
                  ) : null}
                  {canViewFinancials ? (
                    <TabPanel className="shipment__tab">
                      <MarketplaceWithRouter params={{shipment_id: one.id}} hideTabs hideShipmentInfo hideEditShipment>
                        <InstantRates />
                      </MarketplaceWithRouter>
                    </TabPanel>
                  ) : null}
                  <TabPanel className={classNames('shipment__tab', {'pl-0': vizTimelineRedesign})}>
                    <RollbarErrorBoundary errorMessage="There was an error displaying the timeline">
                      <LegacyShipmentTrackingOverview
                        shipmentId={one?.id}
                        sidebarOpen={layoutSchema?.sidebar?.isOpen}
                      />
                    </RollbarErrorBoundary>
                  </TabPanel>
                  {canViewFinancials ? (
                    <TabPanel className="shipment__tab">
                      <div className="shipment__items">
                        <ShipmentFinancials
                          isFTL={isFTL}
                          isLTL={isLTL}
                          fetchDetails={this.fetchDetails}
                          vendorAssignment={this.props.one.relationship_to_vendor}
                          customerAssignment={this.props.one.relationship_to_customer}
                          shipmentInvoices={this.props.shipmentInvoices}
                        />
                      </div>
                    </TabPanel>
                  ) : null}
                  {showCapacitySearch ? (
                    <TabPanel className="shipment__tab p-4">
                      <ShipmentSourceCapacity shipment={this.props.one} />
                    </TabPanel>
                  ) : null}
                </Tabs>
              </div>
              <Collaboration
                shipment={this.props.one}
                company={this.props.company}
                onUpdateShipment={() => this.fetchDetails(this.props.params.shipment_id)}
                onExecuteRoutingGuide={(id) => this.getRoutingGuideDetails(id)}
                onCollaborationClose={this.handleCollaborationClose}
                onCollaborationSelect={this.handleCollaborationSelect}
                isOpen={layoutSchema?.sidebar?.isOpen}
                selectedIndex={layoutSchema?.sidebar?.selectedIndex}
              />
            </TwoColumnLayoutRight>
          </TwoColumnLayout>
        )}
        <InfoModalWrapper
          show={this.state.showCasesModal}
          extraClass="cases__modal"
          title={'Shipment ' + this.props.one.reference_id + ' Cases'}
          onHide={() => {
            this.setState({showCasesModal: false});
          }}
        >
          <Cases
            customer={this.props.one.customer && this.props.one.customer.name}
            vendor={
              this.props.one.mode && this.props.one.mode.code === 'LTL'
                ? //for LTL, show the current_carrier if it exists
                  this.props.one.current_carrier
                  ? this.props.one.current_carrier.name
                  : this.props.one.relationship_to_vendor && this.props.one.relationship_to_vendor.vendor
                  ? this.props.one.relationship_to_vendor.vendor.name
                  : null
                : this.props.one.relationship_to_vendor && this.props.one.relationship_to_vendor.vendor
                ? this.props.one.relationship_to_vendor.vendor.name
                : null
            }
            shipmentIdentifier={this.props.one.reference_id}
            shouldShowOwner
            shipmentId={this.props.router.params.shipment_id}
          />
        </InfoModalWrapper>
        <Modal
          show={this.state.showCarrierBidForm}
          title="New Bid"
          footerComponent={null}
          onClose={() => this.setState({showCarrierBidForm: false})}
        >
          <CarrierBidCreateContainer
            shipment={this.props.one}
            onCancel={() => this.setState({showCarrierBidForm: false})}
            onSubmitSuccess={() => {
              this.setState({showCarrierBidForm: false, showCarrierBidSuccess: true});
            }}
          />
        </Modal>
        <Toast
          show={this.state.showCarrierBidSuccess}
          title="Bid Submitted!"
          anchor="bottom-right"
          onClose={() => this.setState({showCarrierBidSuccess: false})}
        >
          Carrier bid submitted successfully.
        </Toast>
        <Toast
          show={this.state.showAddOrderSuccess}
          anchor="top-right"
          title="Success!"
          onClose={() => this.setState({showAddOrderSuccess: false})}
        >
          Orders were successfully added to shipment
        </Toast>
        <Toast
          show={this.state.showAddOrderError}
          variant="error"
          anchor="top-right"
          title={this.state.addOrderErrorTitle}
          onClose={() => this.setState({showAddOrderError: false})}
        >
          {this.state.addOrderErrorMessage}
        </Toast>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showLoadboardToast}
          autoHideDuration={null}
          onClose={this.handleCloseLoadboardToast.bind(this)}
        >
          {this.renderLoadboardToast()}
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showFTLRateRequested}
          autoHideDuration={null}
          onClose={this.handleCloseFTLRateRequestedToast.bind(this)}
        >
          {this.renderFTLRateRequestedToast()}
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addressBook: state.addresses.addressbook,
    breadcrumbs: state.shipmentdetails.breadcrumbs,
    brokerMarkup: null,
    carrier: state.shipmentdetails.carrier,
    company: state.auth.company,
    equipmentTypes: state.shipmentdetails.equipmentTypes,
    hasFutureScheduledCalls: state.shipmentdetails.hasFutureScheduledCalls,
    isLoading: state.shipmentdetails.isLoading,
    nmfcCodes: state.shipmentdetails.nmfcCodes,
    one: state.shipmentdetails.one,
    reps: state.shipmentdetails.reps,
    selectedQuote: state.shipmentdetails.selectedQuote,
    serviceLevels: state.shipmentdetails.serviceLevels,
    shipmentModes: state.shipmentdetails.shipmentModes,
    timelineEvents: state.shipmentdetails.timelineEvents,
    user: state.auth.user,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    carrierRelationships: state.vendors.carrierRelationships,
    shipmentInvoices: state.integrations.shipmentInvoices,
    purchaseOrders: state.purchaseOrders.details,
    featureFlags: state.auth.company && state.auth.company.feature_flags,
    packageTypes: state.shipments.packageTypes,
    isBidManagerEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.bid_manager_enabled,
    canPerformAutomatedCalls:
      state.userCompany.preferences && state.userCompany.preferences.automated_check_calling_enabled
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...carrierActions,
      ...shipmentdetailsActions,
      ...shipmentActions,
      ...addressBookActions,
      ...documentActions,
      ...formActions,
      ...productActions,
      ...userActions,
      ...brokerActions,
      ...integrationActions,
      getPurchaseOrderByShipment,
      getShipmentBills,
      getShipmentInvoices,
      getAllSpotNegotiationsByShipment,
      getFinancialManagementSystems,
      clearShipmentState,
      fetchPieceTypes
    },
    dispatch
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPageTitle,
  WithStatusToasts,
  withFlags(
    'vizTimelineRedesign',
    'publicLoadboardPost',
    'publicLoadBoardCapacitySearch',
    'stmFinancialTenderingUserPermission',
    'airMode'
  )
)(ShipmentDetails);
