import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {ShipmentMode} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useModesQuery} from 'App/data-hooks';

const ModesField = ({
  name = 'modes',
  optionsWhiteList = [],
  optionsBlackList = [],
  required = false,
  disabled = false,
  ...props
}: {
  name?: string;
  optionsWhiteList?: string[];
  optionsBlackList?: string[];
  required?: boolean;
  disabled?: boolean;
}) => {
  const modesQuery = useModesQuery();
  const modes = modesQuery.data || [];
  const refineModesOptions = () => {
    return modes.filter((mode) => {
      if (optionsWhiteList.length > 0 && !optionsWhiteList.includes(mode.code as string)) {
        return false;
      }
      if (optionsBlackList.length > 0 && optionsBlackList.includes(mode.code as string)) {
        return false;
      }
      return true;
    });
  };

  return (
    <Field
      required={required}
      options={refineModesOptions()}
      getOptionLabel={(option: ShipmentMode) => option.description}
      getOptionValue={(option: ShipmentMode) => option.id}
      label="Modes"
      name={name}
      isMulti
      component={FormikSelect}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default ModesField;
