import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import set from 'lodash/set';
import {Formik, Form} from 'formik';
import {object, string} from 'yup';
import {Card} from '@shipwell/shipwell-ui';
import {PROPTYPE_COMPANY} from '../../../../utils/propTypeConstants';
import {ContractDefaultsFields} from 'App/formComponents/formSections/contractFields/contractDefaultsFields';
import FormFooter from 'App/formComponents/formSections/formFooter';
import WithStatusToasts, {WithStatusToastsDefaultProps} from 'App/components/withStatusToasts';
import {updateCompanySetting} from 'App/containers/userCompany/actions/async';
import {validateDollarValue} from 'App/utils/globals';
import 'App/containers/userCompany/defaults/styles.scss';

export const validationSchema = object().shape({
  additional_stop_charge_flat_fee: string()
    .nullable()
    .test('additional_stop_charge_flat_fee', 'A valid dollar value is required.', (value) => {
      if (value) {
        return validateDollarValue(value, 2);
      }
      return true;
    }),
  additional_stop_charge_per_mile: string()
    .nullable()
    .test('additional_stop_charge_per_mile', 'A valid dollar value is required.', (value) => {
      if (value) {
        return validateDollarValue(value, 2);
      }
      return true;
    }),
  email: string().email('A valid email is required.').nullable()
});

const CompanyContractDefaults = ({dispatch, company, setSuccess, setError}) => {
  const handleDefaultsSubmit = async (values) => {
    const companyCustomData = company.custom_data || {};
    set(companyCustomData, 'shipwell_custom_data.company_default_values.contracts', values);
    try {
      const response = await dispatch(updateCompanySetting(company.id, {...company, custom_data: companyCustomData}));
      if (response?.ok) {
        setSuccess('Success!', 'Company Contract Defaults Updated Successfully.');
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error?.error_description);
    }
  };

  return (
    <div className="company-settings-wrapper">
      <div className="companyDefaults__wrapper">
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={get(company, 'custom_data.shipwell_custom_data.company_default_values.contracts', {})}
          onSubmit={handleDefaultsSubmit}
        >
          {({isSubmitting, dirty, setFieldValue, values, ...props}) => (
            <Form className="contracts__form">
              <Card
                title="Contracts"
                className="companyDefaultCard"
                tooltip={
                  <div className="explainer">
                    <div className="explainer__title">Contract Defaults</div>
                    <div className="explainer__details">
                      The defaults you set here for contracts will be applied across the company as you create new
                      contracts. These values can be overidden when you create a contract.
                    </div>
                  </div>
                }
              >
                <ContractDefaultsFields
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  values={values}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                />
              </Card>
              {dirty && <FormFooter isSubmitting={isSubmitting} />}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

CompanyContractDefaults.propTypes = {
  company: PROPTYPE_COMPANY,
  dispatch: PropTypes.func,
  ...WithStatusToastsDefaultProps
};

export default compose(
  connect((state) => ({
    company: state.userCompany.company
  })),
  WithStatusToasts
)(CompanyContractDefaults);
