import {useEffect, useMemo, useRef, useState} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {ColumnOrderState, PaginationState, RowSelectionState, Updater, flexRender} from '@tanstack/react-table';
import {Button, IconButton, SvgIcon} from '@shipwell/shipwell-ui';
import {
  ShippingDashboardApiShipwellUiShippingDashboardGetRequest,
  ShippingDashboardRow,
  CustomFieldEntityTypesEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import isEqual from 'lodash/isEqual';
import {convertFiltersRelativeDateValues} from './utils/filter/filterUtils';
import {
  defaultKeys,
  featureFlagDashboardColumnMap,
  getColumnData,
  getUserPermissionsDashboardColumnMap,
  nonDefaultKeys
} from 'App/containers/Dashboard/columns';
import {
  useSavedTable,
  useTableColumns,
  useTableFilters,
  useTablePagination,
  useTableSidebar,
  useTableSort,
  useTypedTable
} from 'App/components/TypedTable/hooks';
import {
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderRow,
  TableHeaderSortIcon,
  TableRow
} from 'App/components/TypedTable/baseComponents';
import {
  DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
  TableTabEnums,
  getHasFiltersApplied,
  DASHBOARD_LOCAL_STORAGE_KEY
} from 'App/components/TypedTable/tableUtils';
import {
  DashboardItem,
  SidebarFooterForm,
  TableColumnsTab,
  TableFiltersTab,
  TableLoadingBar,
  TableSavedTab,
  TableSidebar,
  TableSidebarFooter,
  TableSidebarHeader
} from 'App/components/TypedTable/complementaryComponents';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useDebounce} from 'App/utils/hooks/useDebounce';
import {
  dashboardLabelMap,
  filterDashboardColumnsByFlagValue,
  filterDashboardColumnsByPermissionValue,
  getColumnExportIds,
  initialFilterState,
  transformShipmentsDashboardColumns,
  transformShipmentsDashboardFilters
} from 'App/containers/Dashboard/utils/DashboardUtils';
import {SHIPMENTS_LIST_TABLE_KEY} from 'App/utils/tableTypeKeys';
import {SelectedCount} from 'App/components/SelectedCount';
import {
  useCustomFields,
  useGetAuctionStats,
  useShipmentDashboardQuery,
  useUserMe,
  useGetWorkflowExecutions
} from 'App/data-hooks';
import {DashboardReadback} from 'App/containers/Dashboard/DashboardReadback';
import {useLocationFilter} from 'App/components/TypedTable/filters';
import {useGetShipmentMetaDataAlerts} from 'App/data-hooks/shipments/useGetShipmentMetaDataAlerts';
// eslint-disable-next-line import/no-named-as-default
import NoFilterResultsSprinkles from 'App/components/Table/components/NoFilterResultsSprinkles';
import EmptyListView from 'App/components/Table/components/EmptyListView';
import {useCompanyUnitPreferences} from 'App/utils/hooks/useCompanyUnitPreferences';
import {DashboardFilters} from 'App/containers/Dashboard/DashboardFilters';
import {useLocalStorage} from 'App/utils/hooks/useLocalStorage';

export const initialSortState = {id: 'pickup', desc: true};

export const DashboardTable = ({
  globalQuery,
  onChangeGlobalQuery,
  onSelectShipmentRows,
  shipmentDrawerId,
  onSelectShipment,
  tableRowSelectionState,
  setTableRowSelectionState,
  onColumnVisibilityChange
}: {
  globalQuery: string;
  onChangeGlobalQuery: (query: string) => void;
  onSelectShipmentRows: React.Dispatch<React.SetStateAction<ShippingDashboardRow[]>>;
  onColumnVisibilityChange: React.Dispatch<React.SetStateAction<string[]>>;
  shipmentDrawerId?: string;
  onSelectShipment: (shipment: ShippingDashboardRow) => void;
  tableRowSelectionState: RowSelectionState;
  setTableRowSelectionState: React.Dispatch<React.SetStateAction<RowSelectionState>>;
}) => {
  const flags = useFlags();
  const {data: userData} = useUserMe();
  const permissions = userData?.user?.permissions;
  const {customFields, isLoading: isLoadingCustomFields} = useCustomFields(CustomFieldEntityTypesEnum.Shipment);
  const columnData = getColumnData(customFields);

  const defaultColumns = defaultKeys.reduce<Record<string, boolean>>((keyObject, key) => {
    keyObject[key] = true;
    return keyObject;
  }, {});
  const defaultColumnVisibility = nonDefaultKeys.reduce<Record<string, boolean>>((keyObject, key) => {
    keyObject[key] = false;
    return keyObject;
  }, defaultColumns);

  //used when we need to reset the initial column state after the columns array has been updated
  const [initialDashboardColumnVisibility, setInitialDashboardColumnVisibility] =
    useState<Record<string, boolean>>(defaultColumnVisibility);

  const userPermissionColumnMap = getUserPermissionsDashboardColumnMap(flags);

  const conditionalColumns = filterDashboardColumnsByPermissionValue({
    columnData: filterDashboardColumnsByFlagValue({columnData, flags, flagsColumnMap: featureFlagDashboardColumnMap}),
    permissions,
    userPermissionColumnMap: userPermissionColumnMap
  });

  const {columns, columnOrder, setColumnOrder, columnVisibility, setColumnVisibility} = useTableColumns(
    conditionalColumns,
    SHIPMENTS_LIST_TABLE_KEY,
    initialDashboardColumnVisibility
  );

  const {
    filters,
    onFilterChange,
    openFilters,
    onToggleFilter,
    onCollapseAllFilters,
    onExpandAllFilters,
    onDashboardChange
  } = useTableFilters(initialFilterState, true);
  const {
    deliveryStopsContains,
    deliveryStopsStateProvince,
    deliveryRadius,
    deliveryStopsLabel,
    pickupStopsContains,
    pickupStopsStateProvince,
    pickupRadius,
    pickupStopsLabel
  } = filters;

  const {isOpen, toggleIsOpen, selectedTab, setSelectedTab} = useTableSidebar(TableTabEnums.filters);
  const [, setLocalStorageValue] = useLocalStorage({
    key: DASHBOARD_LOCAL_STORAGE_KEY,
    initialValue: ''
  });
  const [sorting, setSorting, sortString] = useTableSort({defaultSort: initialSortState, shouldUseRouter: true});
  const {
    dashboardsQuery,
    onCreateDashboard,
    onUpdateDashboard,
    onDeleteDashboard,
    onSelectDashboard,
    selectedDashboardId,
    isNewDashboard,
    createDashboardSuccess,
    setSelectedDashboardId
  } = useSavedTable({
    tableType: SHIPMENTS_LIST_TABLE_KEY,
    initialFilterState,
    filterState: filters,
    onFilterChange,
    initialColumnOrder: conditionalColumns.map((col) => col.id || ''),
    columnOrder,
    // ! the legacy dashboard can have saved columns that no longer exist. Filtering out any col that we do not have an id for to ensure column order doesn't get wonky
    onColumnOrderChange: (colOrderState) => {
      setColumnOrder(colOrderState.filter((column) => columnData.map((col) => col.id).includes(column)));
    },
    initialColumnVisibility: initialDashboardColumnVisibility,
    columnVisibility,
    onColumnVisibilityChange: (colVis) => {
      setColumnVisibility(colVis);
    },
    initialSortState,
    sortString,
    onSort: setSorting,
    shouldUseRouter: true,
    transformFilters: transformShipmentsDashboardFilters,
    transformColumns: transformShipmentsDashboardColumns,
    onDashboardChange,
    allowOverrideLoadDashboard: true
  });

  // specific to the shipments dashboard
  // if the view is removed from search params by search we should unset selectedDashboardId
  const currentLocationSearch = window.location.search;
  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocationSearch);
    if ((!searchParams.has('view') || !searchParams.get('view')) && !!selectedDashboardId) {
      setSelectedDashboardId('');
    }
    setLocalStorageValue(searchParams.toString());
    // we only need to monitor the search params for changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocationSearch]);

  const currentDashboardName = dashboardsQuery.data?.results?.find(
    (dashboard) => dashboard.id === selectedDashboardId
  )?.name;
  const [pagination, setPagination] = useTablePagination({shouldUseRouter: true});
  // Reseting pageIndex on search so we don't try to query search results of a page that doesn't exist.
  // It also makes more sense from the user's perspective that we send them to the first page of their search results
  const debouncedQuery = useDebounce(globalQuery, 300, () => {
    if (!globalQuery) {
      return;
    }
    setPagination({...pagination, pageIndex: 0});
  });

  const transformedFilters = convertFiltersRelativeDateValues(filters);
  const params: ShippingDashboardApiShipwellUiShippingDashboardGetRequest = {
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
    ordering: sortString,
    q: debouncedQuery,
    // this dashboard should always use the optimized BE route
    optimized: true,
    ...(transformedFilters as unknown as Partial<ShippingDashboardApiShipwellUiShippingDashboardGetRequest>)
  };

  const shipmentDashboardQuery = useShipmentDashboardQuery(params, {
    keepPreviousData: true
  });

  const shipmentIds = shipmentDashboardQuery.data?.results?.map((shipment) => shipment.id) || [];
  // useGetAuctionStats is called here to "seed" the query cache
  // each AuctionStatsCell then reads from this cache to get the data so it doesn't have to call each shipment individually
  useGetAuctionStats({
    shipmentIds,
    options: {
      // enable if shipments are finished fetching and auction_stats are visible
      enabled: Boolean(shipmentIds.length) && (columnVisibility.auction_stats || columnVisibility.carrier)
    }
  });

  useGetWorkflowExecutions({
    shipmentIds,
    options: {
      enabled: !!shipmentIds.length && columnVisibility.workflows
    }
  });

  // seeding the query cache to grab this data in a few cells (Weight and Total Miles)
  useCompanyUnitPreferences();
  // seeding cache for metadata alerts
  // each IdCell then reads from this cache to get the data as seen with the AuctionStatsCell example above
  useGetShipmentMetaDataAlerts({
    shipmentIds,
    options: {
      enabled: Boolean(shipmentIds.length) && columnVisibility.reference_id
    }
  });
  const tableRef = useRef<HTMLDivElement>(null);

  const table = useTypedTable({
    data: shipmentDashboardQuery.data?.results || [],
    columns,
    pageCount: shipmentDashboardQuery.data?.total_pages || 1,
    state: {
      sorting,
      pagination,
      columnOrder,
      columnVisibility,
      rowSelection: tableRowSelectionState
    },
    getRowId: (row) => row.id,
    onSortingChange: setSorting,
    onPaginationChange: (updater: Updater<PaginationState>) => {
      const getState = (prevState: PaginationState) => (updater instanceof Function ? updater(prevState) : updater);
      setPagination((prev) => {
        const updated = getState(prev);
        // on page change we should reset the scroll position to the top of the table
        if (tableRef.current) {
          tableRef.current.scrollTop = 0;
        }
        return updated;
      });
    },
    onColumnOrderChange: setColumnOrder,
    onRowSelectionChange: (updater: Updater<RowSelectionState>) => {
      // This pattern of getting the state via react table's updater is seen in the useTablePagination hook
      const getState = (prevState: RowSelectionState) => (updater instanceof Function ? updater(prevState) : updater);
      setTableRowSelectionState((prev) => {
        // getting updated state
        const updated = getState(prev);
        const shipmentKeys = Object.keys(updated);
        // as a side effect we want to get the full shipment objects of all selected rows
        // nesting setters allows us to track on user change as opposed to an effect
        onSelectShipmentRows((prevSelected) => {
          const prevSelectedIds = prevSelected.map((prev) => prev.id);
          //get the shipments from this page that were not previously selected
          const thisPageUnselectedShipments =
            shipmentDashboardQuery?.data?.results?.filter((shipment) => !prevSelectedIds.includes(shipment.id)) || [];
          const thisPageAndPreviouslySelectedShipments = [...prevSelected, ...thisPageUnselectedShipments];
          return thisPageAndPreviouslySelectedShipments.filter((selected) => shipmentKeys.includes(selected.id));
        });
        return updated;
      });
    },
    onColumnVisibilityChange: (colVis) => {
      setColumnVisibility(colVis);
    },
    manualSorting: true
  });

  const deliveryAddressFilter = useLocationFilter({
    contains: deliveryStopsContains,
    stateProvinces: deliveryStopsStateProvince,
    latLonLabel: deliveryStopsLabel,
    radius: deliveryRadius,
    onChange: ({addresses, states, lat, lon, label}) => {
      onFilterChange('deliveryStopsContains', addresses);
      onFilterChange('deliveryStopsStateProvince', states);
      onFilterChange('deliveryLat', lat || '');
      onFilterChange('deliveryLon', lon || '');
      onFilterChange('deliveryStopsLabel', label || '');
    },
    onRadiusChange: (radius) => onFilterChange('deliveryRadius', radius),
    useLatLong: true
  });
  const pickupAddressFilter = useLocationFilter({
    contains: pickupStopsContains,
    stateProvinces: pickupStopsStateProvince,
    latLonLabel: pickupStopsLabel,
    radius: pickupRadius,
    onChange: ({addresses, states, lat, lon, label}) => {
      onFilterChange('pickupStopsContains', addresses);
      onFilterChange('pickupStopsStateProvince', states);
      onFilterChange('pickupLat', lat || '');
      onFilterChange('pickupLon', lon || '');
      onFilterChange('pickupStopsLabel', label || '');
    },
    onRadiusChange: (radius) => onFilterChange('pickupRadius', radius),
    useLatLong: true
  });

  useMemo(() => {
    if (customFields.length > 0) {
      const customFieldsDefaultColumnVisibility = customFields.reduce<Record<string, boolean>>((acc, colKey) => {
        //custom field columns are not visible by default. However, if the user has already set a visibility
        //state for the custom field, we leave the custom field column visible.
        if (!columnVisibility[colKey.name])
          return {
            ...acc,
            [colKey.name]: false
          };
        return acc;
      }, {});
      const columnVisibilityWithCustomFields = {...columnVisibility, ...customFieldsDefaultColumnVisibility};
      if (!isEqual(columnVisibility, columnVisibilityWithCustomFields)) {
        setColumnVisibility({...columnVisibility, ...customFieldsDefaultColumnVisibility});
        setInitialDashboardColumnVisibility({...columnVisibility, ...customFieldsDefaultColumnVisibility});
      }
    }
  }, [columnVisibility, customFields, setColumnVisibility, setInitialDashboardColumnVisibility]);

  const visibleColumns = table.getVisibleLeafColumns();
  //when column visibility changes, update the column export ids for the csv download bulk action
  useMemo(() => {
    onColumnVisibilityChange(getColumnExportIds(visibleColumns));
  }, [onColumnVisibilityChange, visibleColumns]);

  const hasFiltersApplied = getHasFiltersApplied(filters);
  return (
    <>
      {shipmentDashboardQuery.isFetching ? (
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0">
            <TableLoadingBar />
          </div>
        </div>
      ) : null}
      <div className="flex h-full overflow-hidden">
        <TableSidebar isOpen={isOpen} onClick={toggleIsOpen} hasFiltersApplied={hasFiltersApplied}>
          <TableSidebarHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} onClose={toggleIsOpen} />
          {selectedTab === TableTabEnums.filters ? (
            <TableFiltersTab
              footer={
                createDashboardSuccess ? (
                  <TableSidebarFooter>
                    <div className="flex items-start gap-2 bg-sw-background-component p-2">
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="font-bold">Dashboard Saved</span>
                          <SvgIcon color="sw-success" name="CheckCircleFilled" />
                        </div>
                        <span>
                          To view your dashboards, select <span className="font-bold text-sw-primary">Saved</span> at
                          the top of the column panel.
                        </span>
                      </div>
                      <IconButton iconName="Close" aria-label="dismiss success message" />
                    </div>
                  </TableSidebarFooter>
                ) : (
                  <SidebarFooterForm
                    onCreate={onCreateDashboard}
                    isNewDashboard={isNewDashboard}
                    onUpdate={onUpdateDashboard}
                  >
                    <div className="flex gap-2">
                      <IconButton iconName="ExpandAll" aria-label="Show all filters" onClick={onExpandAllFilters} />
                      <IconButton iconName="CollapseAll" aria-label="Hide all filters" onClick={onCollapseAllFilters} />
                    </div>
                  </SidebarFooterForm>
                )
              }
            >
              <DashboardFilters
                filters={filters}
                onChange={onFilterChange}
                openFilters={openFilters}
                onToggleFilter={onToggleFilter}
                deliveryAddressFilter={deliveryAddressFilter}
                pickupAddressFilter={pickupAddressFilter}
              />
            </TableFiltersTab>
          ) : null}
          {selectedTab === TableTabEnums.columns ? (
            <TableColumnsTab
              onColumnOrderChange={(updater) => {
                const getState = (prevState: ColumnOrderState) =>
                  updater instanceof Function ? updater(prevState) : updater;
                setColumnOrder((prevOrder) => {
                  const updatedOrder = getState(prevOrder);
                  // remove the reference_id column from the column order and add it at start of the array
                  // this keeps the order from getting out of sync with UI since it is filtered from the UI in the disabledIds prop below
                  const referenceColumnIndex = updatedOrder.findIndex((col) => col === 'reference_id');
                  if (referenceColumnIndex > -1) {
                    updatedOrder.splice(referenceColumnIndex, 1);
                    updatedOrder.unshift('reference_id');
                  }
                  return updatedOrder;
                });
              }}
              columns={table.getAllLeafColumns()}
              // filtering out the reference_id column because it should not be user selectable column
              disabledIds={['reference_id']}
              // labels are auto-interpreted via the column's ID, but it is possible to override the column label by passing the id and preferred label
              labels={dashboardLabelMap}
              footer={
                createDashboardSuccess ? (
                  <TableSidebarFooter>
                    <div className="flex items-start gap-2 bg-sw-background-component p-2">
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="font-bold">Dashboard Saved</span>
                          <SvgIcon color="sw-success" name="CheckCircleFilled" />
                        </div>
                        <span>
                          To view your dashboards, select <span className="font-bold text-sw-primary">Saved</span> at
                          the top of the column panel.
                        </span>
                      </div>
                      <IconButton iconName="Close" aria-label="dismiss success message" />
                    </div>
                  </TableSidebarFooter>
                ) : isNewDashboard ? (
                  <SidebarFooterForm
                    onCreate={onCreateDashboard}
                    isNewDashboard={isNewDashboard}
                    onUpdate={onUpdateDashboard}
                  />
                ) : null
              }
            />
          ) : null}
          {selectedTab === TableTabEnums.saved ? (
            <TableSavedTab isLoading={dashboardsQuery.isLoading}>
              {dashboardsQuery.data?.results?.map((dashboard) => (
                <DashboardItem
                  key={dashboard.id}
                  dashboard={dashboard}
                  onUpdate={onUpdateDashboard}
                  onDelete={onDeleteDashboard}
                  onSelect={onSelectDashboard}
                  isSelected={selectedDashboardId === dashboard.id}
                />
              ))}
            </TableSavedTab>
          ) : null}
        </TableSidebar>
        <TableContainer>
          {hasFiltersApplied ? (
            <DashboardReadback
              label={currentDashboardName || ''}
              filters={filters}
              onClear={() => {
                onDashboardChange();
                // if clearing all filters, also deselect any saved dashboard that might be applied
                onSelectDashboard();
                pickupAddressFilter.handleClear();
                deliveryAddressFilter.handleClear();
              }}
              onFilterChange={onFilterChange}
              onClearPickupAddress={pickupAddressFilter.handleClear}
              onClearDeliveryAddress={deliveryAddressFilter.handleClear}
            />
          ) : null}
          <TableContainer ref={tableRef}>
            <Table
              head={table.getHeaderGroups().map((headerGroup) => (
                <TableHeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    //since we have a bulk select column header, we want to opt out of the
                    //standard behavior of sorting whenever anything in the header is clicked.
                    if (header.id === 'reference_id') {
                      return (
                        <TableHeader
                          key={header.id}
                          width={header.getSize()}
                          sortDirection={header.column.getIsSorted()}
                          onResize={header.getResizeHandler()}
                          isFixed
                        >
                          {/*sort element is included in the header context*/}
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHeader>
                      );
                    }
                    return (
                      <TableHeader
                        key={header.id}
                        width={header.getSize()}
                        sortDirection={header.column.getIsSorted()}
                        onSort={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                        onResize={header.getResizeHandler()}
                      >
                        <div className="flex items-center gap-1">
                          {header.column.getCanSort() ? (
                            <TableHeaderSortIcon isSorted={header.column.getIsSorted()} />
                          ) : null}
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </div>
                      </TableHeader>
                    );
                  })}
                </TableHeaderRow>
              ))}
              body={
                <>
                  {table.getRowModel().rows.map((row, i) => (
                    <TableRow
                      key={row.id}
                      isSelected={shipmentDrawerId === row.original.id}
                      onClick={() => onSelectShipment(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          isSelected={shipmentDrawerId === row.original.id}
                          rowIndex={i}
                          isFixed={cell.column.id === 'reference_id'}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              }
            />
            {shipmentDashboardQuery.isLoading || dashboardsQuery.isLoading || isLoadingCustomFields ? (
              <div className="absolute inset-0">
                <div aria-hidden className="absolute inset-0 bg-sw-background opacity-50" />
                <ShipwellLoader loading />
              </div>
            ) : !shipmentDashboardQuery.data?.results?.length ? (
              hasFiltersApplied || debouncedQuery.trim().length ? (
                <div className="relative inset-0">
                  <NoFilterResultsSprinkles>
                    <Button
                      onClick={() => {
                        onChangeGlobalQuery('');
                        onDashboardChange();
                        // if clearing all filters, also deselect any saved dashboard that might be applied
                        onSelectDashboard();
                      }}
                    >
                      Clear filters
                    </Button>
                  </NoFilterResultsSprinkles>
                </div>
              ) : (
                <EmptyListView itemLabel="Shipments" />
              )
            ) : null}
            {Object.keys(tableRowSelectionState).length ? (
              <SelectedCount
                count={Object.keys(tableRowSelectionState).length}
                itemLabel="Shipment"
                className="fixed bottom-[4.25rem] left-1/2 -translate-x-2/4 translate-y-1/4"
                clickCta="Deselect All"
                onClick={() => {
                  setTableRowSelectionState({});
                  onSelectShipmentRows([]);
                }}
              />
            ) : null}
            <TableFooter table={table} pageSizes={DEFAULT_TABLE_PAGE_SIZE_OPTIONS} />
          </TableContainer>
        </TableContainer>
      </div>
    </>
  );
};
