import replace from 'lodash/replace';
import upperCase from 'lodash/upperCase';
import {ProviderCode} from '@shipwell/genesis-sdk';
import {startCaseToLower} from 'App/utils/startCaseToLower';

enum PurolatorServiceCodesEnum {
  PurolatorExpress = 'PUROLATOR_EXPRESS',
  PurolatorExpress12Pm = 'PUROLATOR_EXPRESS_12PM',
  PurolatorExpressPack12Pm = 'PUROLATOR_EXPRESS_PACK_12PM',
  PurolatorExpressBox12Pm = 'PUROLATOR_EXPRESS_BOX_12PM',
  PurolatorExpressEnvelope12Pm = 'PUROLATOR_EXPRESS_ENVELOPE_12PM',
  PurolatorExpress1030Am = 'PUROLATOR_EXPRESS_1030AM',
  PurolatorExpress9Am = 'PUROLATOR_EXPRESS_9AM',
  PurolatorExpressBox = 'PUROLATOR_EXPRESS_BOX',
  PurolatorExpressBox1030Am = 'PUROLATOR_EXPRESS_BOX_1030AM',
  PurolatorExpressBox9Am = 'PUROLATOR_EXPRESS_BOX_9AM',
  PurolatorExpressBoxEvening = 'PUROLATOR_EXPRESS_BOX_EVENING',
  PurolatorExpressBoxInternational = 'PUROLATOR_EXPRESS_BOX_INTERNATIONAL',
  PurolatorExpressBoxUs = 'PUROLATOR_EXPRESS_BOX_US',
  PurolatorExpressEnvelope = 'PUROLATOR_EXPRESS_ENVELOPE',
  PurolatorExpressEnvelope1030Am = 'PUROLATOR_EXPRESS_ENVELOPE_1030AM',
  PurolatorExpressEnvelope9Am = 'PUROLATOR_EXPRESS_ENVELOPE_9AM',
  PurolatorExpressEnvelopeEvening = 'PUROLATOR_EXPRESS_ENVELOPE_EVENING',
  PurolatorExpressEnvelopeInternational = 'PUROLATOR_EXPRESS_ENVELOPE_INTERNATIONAL',
  PurolatorExpressEnvelopeUs = 'PUROLATOR_EXPRESS_ENVELOPE_US',
  PurolatorExpressEvening = 'PUROLATOR_EXPRESS_EVENING',
  PurolatorExpressInternational = 'PUROLATOR_EXPRESS_INTERNATIONAL',
  PurolatorExpressInternational1030Am = 'PUROLATOR_EXPRESS_INTERNATIONAL_1030AM',
  PurolatorExpressInternational1200 = 'PUROLATOR_EXPRESS_INTERNATIONAL_1200',
  PurolatorExpressInternational9Am = 'PUROLATOR_EXPRESS_INTERNATIONAL_9AM',
  PurolatorExpressBoxInternational1030Am = 'PUROLATOR_EXPRESS_BOX_INTERNATIONAL_1030AM',
  PurolatorExpressBoxInternational1200 = 'PUROLATOR_EXPRESS_BOX_INTERNATIONAL_1200',
  PurolatorExpressBoxInternational9Am = 'PUROLATOR_EXPRESS_BOX_INTERNATIONAL_9AM',
  PurolatorExpressEnvelopeInternational1030Am = 'PUROLATOR_EXPRESS_ENVELOPE_INTERNATIONAL_1030AM',
  PurolatorExpressEnvelopeInternational1200 = 'PUROLATOR_EXPRESS_ENVELOPE_INTERNATIONAL_1200',
  PurolatorExpressEnvelopeInternational9Am = 'PUROLATOR_EXPRESS_ENVELOPE_INTERNATIONAL_9AM',
  PurolatorExpressPackInternational1030Am = 'PUROLATOR_EXPRESS_PACK_INTERNATIONAL_1030AM',
  PurolatorExpressPackInternational1200 = 'PUROLATOR_EXPRESS_PACK_INTERNATIONAL_1200',
  PurolatorExpressPackInternational9Am = 'PUROLATOR_EXPRESS_PACK_INTERNATIONAL_9AM',
  PurolatorExpressPack = 'PUROLATOR_EXPRESS_PACK',
  PurolatorExpressPack1030Am = 'PUROLATOR_EXPRESS_PACK_1030AM',
  PurolatorExpressPack9Am = 'PUROLATOR_EXPRESS_PACK_9AM',
  PurolatorExpressPackEvening = 'PUROLATOR_EXPRESS_PACK_EVENING',
  PurolatorExpressPackInternational = 'PUROLATOR_EXPRESS_PACK_INTERNATIONAL',
  PurolatorExpressPackUs = 'PUROLATOR_EXPRESS_PACK_US',
  PurolatorExpressUs = 'PUROLATOR_EXPRESS_US',
  PurolatorExpressUs1030Am = 'PUROLATOR_EXPRESS_US_1030AM',
  PurolatorExpressUs1200 = 'PUROLATOR_EXPRESS_US_1200',
  PurolatorExpressUs9Am = 'PUROLATOR_EXPRESS_US_9AM',
  PurolatorExpressBoxUs1030Am = 'PUROLATOR_EXPRESS_BOX_US_1030AM',
  PurolatorExpressBoxUs1200 = 'PUROLATOR_EXPRESS_BOX_US_1200',
  PurolatorExpressBoxUs9Am = 'PUROLATOR_EXPRESS_BOX_US_9_AM',
  PurolatorExpressEnvelopeUs1030Am = 'PUROLATOR_EXPRESS_ENVELOPE_US_1030AM',
  PurolatorExpressEnvelopeUs1200 = 'PUROLATOR_EXPRESS_ENVELOPE_US_1200',
  PurolatorExpressEnvelopeUs9Am = 'PUROLATOR_EXPRESS_ENVELOPE_US_9AM',
  PurolatorExpressPackUs1030Am = 'PUROLATOR_EXPRESS_PACK_US_1030AM',
  PurolatorExpressPackUs1200 = 'PUROLATOR_EXPRESS_PACK_US_1200',
  PurolatorExpressPackUs9Am = 'PUROLATOR_EXPRESS_PACK_US_9AM',
  PurolatorGround = 'PUROLATOR_GROUND',
  PurolatorGround1030Am = 'PUROLATOR_GROUND_1030AM',
  PurolatorGround9Am = 'PUROLATOR_GROUND_9AM',
  PurolatorGround12Pm = 'PUROLATOR_GROUND_12PM',
  PurolatorGroundDistribution = 'PUROLATOR_GROUND_DISTRIBUTION',
  PurolatorGroundEvening = 'PUROLATOR_GROUND_EVENING',
  PurolatorGroundUs = 'PUROLATOR_GROUND_US',
  PurolatorQuickShip = 'PUROLATOR_QUICK_SHIP',
  PurolatorQuickShipEnvelope = 'PUROLATOR_QUICK_SHIP_ENVELOPE',
  PurolatorQuickShipPack = 'PUROLATOR_QUICK_SHIP_PACK',
  PurolatorQuickShipBox = 'PUROLATOR_QUICK_SHIP_BOX'
}

const serviceCodesByProvider = {
  [ProviderCode.Purolator]: PurolatorServiceCodesEnum
};

export const getProviderServiceCodes = (providerCode?: ProviderCode) => {
  if (!providerCode || !(providerCode in serviceCodesByProvider)) return [];

  const serviceCodes = serviceCodesByProvider[<keyof typeof serviceCodesByProvider>providerCode];
  return Object.values(serviceCodes)?.map((value) => ({
    id: value,
    name: replace(startCaseToLower(value), /\b(?:Us|Am|Pm)\b/g, (match) => upperCase(match))
  }));
};
