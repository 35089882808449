import isNil from 'lodash/isNil';
import moment from 'moment-timezone';
import {
  LocationType,
  Shipment,
  ShipmentLineItem,
  ShipmentLineItemWeightUnitEnum,
  StopDetail,
  ShipmentLineItemLengthUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  WeightUnit,
  LocationType as GenesisLocationType,
  TimeWindow,
  Appointment,
  ParcelStop,
  CreateParcelRateRequestTransportationModeEnum,
  ParcelCapacityProvider,
  RatingApiCreateRateRequestRequest,
  CreateParcelRateRequest,
  ParcelStopAccessorials,
  ParcelHandlingUnit,
  PackagingType,
  VolumeUnit,
  VolumetricMeasurementMeasurementTypeEnum,
  ParcelBillTo,
  ProviderCode
} from '@shipwell/genesis-sdk';
import toNumber from 'lodash/toNumber';
import {calculateVolume} from 'App/utils/globalsTyped';
import {startCaseToLower} from 'App/utils/startCaseToLower';

/**
 * Maps backend core weight unit type to genesis weight unit type.
 */
function mapWeightUnit(unit?: ShipmentLineItemWeightUnitEnum | null): WeightUnit {
  switch (unit) {
    case ShipmentLineItemWeightUnitEnum.Kg:
      return WeightUnit.Kg;
    case ShipmentLineItemWeightUnitEnum.Lb:
    default:
      return WeightUnit.Lb;
  }
}

export function transformParcelPayload({
  shipment,
  parcelCapacityProviders,
  billTo
}: {
  shipment: Shipment;
  parcelCapacityProviders: ParcelCapacityProvider[];
  billTo?: ParcelBillTo;
}) {
  const grossWeight = transformLineItems(shipment?.line_items as ShipmentLineItem[]).reduce(
    (acc, lineItem) => acc + (lineItem?.quantity ? lineItem?.quantity : 1) * lineItem.weight.value,
    0
  );

  const parcelStops = transformStops({
    providerAccountNumber: parcelCapacityProviders?.at(0)?.account_number,
    stops: shipment?.stops as StopDetail[]
  });

  const billToPointOfContact = shipment?.stops?.at(0)?.location?.point_of_contacts?.at(0);

  const payload: RatingApiCreateRateRequestRequest = {
    createParcelRateRequest: {
      transportation_mode: CreateParcelRateRequestTransportationModeEnum.Parcel,
      customer_reference_number: shipment?.id,
      ...(shipment?.total_declared_value && {
        total_declared_value: {
          amount: shipment?.total_declared_value,
          currency: shipment?.total_declared_value_currency
        }
      }),
      preferred_currency: shipment?.preferred_currency,
      capacity_providers: parcelCapacityProviders,
      bill_to: {
        account_number: billTo?.account_number,
        contact: {
          company_name: shipment?.stops?.at(0)?.location?.company_name,
          first_name: billToPointOfContact?.first_name,
          email: billToPointOfContact?.email,
          last_name: billToPointOfContact?.last_name,
          phone: billToPointOfContact?.phone_number,
          contact_id: billToPointOfContact?.id
        },
        ...(billTo?.location
          ? {location: billTo.location}
          : shipment?.stops &&
            parcelStops.at(0)?.location && {
              location: parcelStops.at(0)?.location
            })
      } as CreateParcelRateRequest['bill_to'],
      ...(shipment?.stops && {
        stops: parcelStops
      }),
      ...(shipment?.line_items &&
        shipment?.stops && {
          handling_units: transformLineItems(shipment?.line_items)
        }),
      gross_weight: {
        value: grossWeight,
        unit: shipment?.line_items?.at(0)?.weight_unit
      },
      shipment_id: shipment?.id
    }
  };

  return payload;
}

/**
 * Maps backend-core data model ShipmentLineItem to genesis data model FtlShipmentLineItem.
 *
 * Stops in this method are hardcoded to "1" and "2" as full truck load only ever has two stops
 * for all items.
 *
 * - Stop sequence "1" is set to "LOAD"
 * - stop sequence "2" is set to "UNLOAD".
 *
 * Note: all values are mapped 1-to-1 or required. default values are provided if no value is provided
 * through making a shipment.
 *
 * - Numeric values that have not been provided will be set to -1.
 * - Values which can be marked as null or undefined will be set to undefined.
 */
export function transformLineItems(lineItems: ShipmentLineItem[]): ParcelHandlingUnit[] {
  const result: ParcelHandlingUnit[] = lineItems?.map(
    ({
      weight_unit: weightUnit,
      package_weight: packageWeight,
      description,
      value_per_piece: valuePerPiece,
      value_per_piece_currency: valuePerPieceCurrency,
      provider_specific_packaging: providerSpecificPackaging,
      package_type: packageType,
      total_packages: totalPackages,
      length,
      width,
      height,
      length_unit: lengthUnit
    }) => {
      const hasDimensions = length && width && height && lengthUnit;
      const system =
        lengthUnit &&
        [ShipmentLineItemLengthUnitEnum.Ft, ShipmentLineItemLengthUnitEnum.In].some((unit) => lengthUnit === unit)
          ? 'IMPERIAL'
          : 'METRIC';

      const volume = hasDimensions ? calculateVolume(length, width, height, lengthUnit, system) : undefined;

      return {
        weight: {
          unit: weightUnit && mapWeightUnit(weightUnit),
          value: packageWeight ?? -1
        },
        description: description || undefined,
        // provider packaging sizes are standardized, do not require dimensions
        ...(!providerSpecificPackaging && hasDimensions && volume
          ? {
              dimensions: {
                unit: system === 'METRIC' ? VolumeUnit.M : VolumeUnit.Ft,
                length: {unit: lengthUnit, value: length},
                width: {unit: lengthUnit, value: width},
                height: {unit: lengthUnit, value: height},
                measurement_type: VolumetricMeasurementMeasurementTypeEnum.Volume,
                value: volume
              }
            }
          : {}),
        quantity: totalPackages ?? -1,
        declared_value: isNil(valuePerPiece)
          ? undefined
          : {
              currency: valuePerPieceCurrency,
              amount: valuePerPiece
            },
        packaging_type: (packageType as PackagingType) || undefined,
        packaging_code: providerSpecificPackaging || undefined
      };
    } // end lineItem map
  ); // end mapping

  return result ?? [];
}

/**
 * Maps backend-core stop to genesis FtlStop for getting FTL rates. Stop sequences will start at "1"
 * and increase by "1" individually from there.
 *
 * Note: all values are mapped 1-to-1 or required. default values are provided if no value is provided
 * through making a shipment.
 *
 * - Numeric values that have not been provided will be set to -1.
 * - Values which can be marked as null or undefined will be set to undefined.
 * - Dates are set to default today using `moment()`.
 */
export function transformStops({
  providerAccountNumber,
  stops
}: {
  stops: StopDetail[];
  providerAccountNumber: string | undefined;
}): ParcelStop[] {
  const result: ParcelStop[] | null | undefined = stops?.map(
    ({
      location,
      accessorials,
      ordinal_index: ordinalIndex,
      planned_date: plannedDate,
      planned_time_window_start: plannedTimeWindowStart,
      planned_time_window_end: plannedTimeWindowEnd
    }) => {
      const {timezone, country, address_1, address_2, city, postal_code, state_province, latitude, longitude} =
        location.address;

      const sequenceNumber = isNil(ordinalIndex) ? -1 : ordinalIndex + 1; // check for undefined because "0" is a valid value.

      return {
        // check with be
        contact: {} as ParcelStop['contact'],
        sequence_number: sequenceNumber,
        location: {
          country: country,
          line_1: address_1 || undefined,
          line_2: address_2 || undefined,
          locality: city || undefined,
          postal_code: postal_code || undefined,
          region: state_province || undefined,
          geo_coordinates:
            isNil(latitude) || isNil(longitude)
              ? undefined
              : {
                  latitude,
                  longitude
                },
          timezone: timezone || undefined,
          location_type: location.location_type && transformLocationType(location.location_type)
        },
        requested_window: getRequestedWindow(
          plannedDate || new Date().toString(),
          timezone,
          plannedTimeWindowStart || '00:00',
          plannedTimeWindowEnd || '00:00'
        ),
        appointment: getAppointment(
          plannedDate || new Date().toString(),
          plannedTimeWindowStart,
          plannedTimeWindowEnd,
          timezone
        ),
        // check with be
        accessorials: accessorials as unknown as ParcelStopAccessorials[],
        account_number: providerAccountNumber,
        // check with be
        instructions: ''
      };
    }
  );

  return result ?? [];
}

/**
 * Gets a requested window object to send to genesis. The requested window tells
 * the carrier what "dates" to look at for pickup and drop off.
 *
 * The requested window will use the planned_date from the stop and use the UTC time info
 * as the earliest time and latest time.
 */
function getRequestedWindow(
  plannedDate: string,
  timezone?: string | null,
  plannedTimeStart?: string,
  plannedTimeEnd?: string
): TimeWindow {
  const tz = timezone || moment.tz.guess();

  // default set these so we can at least send a value to genesis0 for rates.
  const earliestDateISO = moment(plannedDate || new Date())
    .set({
      hour: Number(plannedTimeStart?.split(':')[0]),
      minute: Number(plannedTimeStart?.split(':')[1]),
      second: 0
    })
    .tz(tz, true)
    .toISOString(true);
  const latestDateISO = moment(plannedDate || new Date())
    .set({
      hour: Number(plannedTimeEnd?.split(':')[0]),
      minute: Number(plannedTimeEnd?.split(':')[1]),
      second: 0
    })
    .tz(tz, true)
    .toISOString(true);
  return {
    earliest: earliestDateISO,
    latest: latestDateISO
  };
}

/**
 * Gets appointment object to send to genesis. The planned window tells the carrier
 * when the dispatch request should occur and in what order.
 *
 * The `appointment_number` is always set to 1 as carriers only support a single
 * appointment at this time.
 */
function getAppointment(
  plannedDate?: string,
  plannedTimeWindowStart?: string | null,
  plannedTimeWindowEnd?: string | null,
  timezone?: string | null
): Appointment {
  const tz = timezone || moment.tz.guess();
  const earliestTime = moment(plannedTimeWindowStart || '00:00:00', 'HH:mm:ss'),
    latestTime = moment(plannedTimeWindowEnd || '23:59:59', 'HH:mm:ss');

  const earliestDateISO = moment(plannedDate || new Date())
    .set({
      hour: earliestTime.get('hour'),
      minute: earliestTime.get('minute'),
      second: earliestTime.get('second')
    })
    .tz(tz, true)
    .toISOString(true);
  const latestDateISO = moment(plannedDate || new Date())
    .set({
      hour: latestTime.get('hour'),
      minute: latestTime.get('minute'),
      second: latestTime.get('second')
    })
    .tz(tz, true)
    .toISOString(true);

  return {
    appointment_number: 1,
    begin: earliestDateISO,
    end: latestDateISO
  };
}

/**
 * Maps the location type from backend-core to genesis. There are only two mapped types
 * that can be utilized for FTL rates. All unmapped types will be set as `LocationType.Other`.
 *
 * - "Business (with dock or forklift)"
 * - "Trade Show/Convention Center"
 */
function transformLocationType(locationType: LocationType): GenesisLocationType {
  switch (locationType.name) {
    case 'Business (with dock or forklift)':
      return GenesisLocationType.BusinessWithDock;
    case 'Trade Show/Convention Center':
      return GenesisLocationType.Exhibition;
    default:
      return GenesisLocationType.Other;
  }
}

// returns formatted provider names
export const getFormattedParcelProvider = (provider: ProviderCode) => {
  switch (provider) {
    case ProviderCode.Fedex: {
      return 'FedEx';
    }
    default: {
      return startCaseToLower(provider);
    }
  }
};

// currently untyped by Genesis
type CODOptions = {
  price: {amount?: string; currency?: string};
  method_of_payment?: string;
};

export type NewQuoteServiceOptions = Omit<ParcelCapacityProvider, 'service_options'> & {
  providerCodes?: Array<ProviderCode>;
  service_code?: string | null;
  bill_to?: ParcelBillTo | null;
  service_options?: {cod_options?: CODOptions; delivery_confirmation?: string; payment_options?: string} | null;
};
// used to map Quote redux-form fields to what useCreateParcelRateRequest wants
export const mapNewQuoteFormServiceOptions = (
  options: NewQuoteServiceOptions,
  currency?: string
): {parcelCapacityProviderOptions: ParcelCapacityProvider[]; billTo?: ParcelBillTo} => {
  const {provider_code, account_number, service_options, service_code: serviceCode, bill_to, providerCodes} = options;
  const {delivery_confirmation, payment_options} = service_options || {};
  const {price, method_of_payment} = service_options?.cod_options || {};
  const hasServiceOptions = (price && method_of_payment) || delivery_confirmation || payment_options;

  const serviceOptions = hasServiceOptions
    ? {
        ...(price?.amount && method_of_payment
          ? {
              cod_options: {
                method_of_payment,
                price: {
                  currency: currency || 'USD',
                  amount: toNumber(price.amount)
                },
                cod_type: method_of_payment
              }
            }
          : {}),
        delivery_confirmation,
        payment_options
      }
    : undefined;

  const parcelCapacityProviderOptions = providerCodes?.length
    ? providerCodes.map((provider) => ({provider_code: provider}))
    : [
        {
          provider_code,
          account_number,
          // frontend only supports one service code currently
          service_codes: serviceCode ? [serviceCode] : undefined,
          service_options: serviceOptions
        }
      ];

  return {
    parcelCapacityProviderOptions,
    billTo: bill_to || undefined
  };
};
