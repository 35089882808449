import {ReactNode} from 'react';
import {Popover, SvgIcon} from '@shipwell/shipwell-ui';
import {
  CompanyInternationalPreferencesCurrencyEnum,
  ShipmentChargeLineItem
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {formatCurrency} from 'App/utils/internationalConstants';

export const AggregateTotalCell = ({
  aggregateTotal,
  currency,
  breakdownCharges,
  justifyText = 'end'
}: {
  aggregateTotal: number;
  currency: CompanyInternationalPreferencesCurrencyEnum;
  /**
   * These are displayed in the popover as a "breakdown" of the charges used to calculate the total. These should
   * be the charges that were added together to get the aggregate total
   */
  breakdownCharges?: ShipmentChargeLineItem[];
  justifyText?: string;
}) => {
  const totalWithParens =
    aggregateTotal < 0
      ? `(${formatCurrency(Math.abs(aggregateTotal), currency)})` // Display negative numbers in parentheses notation
      : formatCurrency(aggregateTotal, currency);

  if (!breakdownCharges?.length) {
    return (
      <div className={`justify-${justifyText} flex`}>
        <div>{totalWithParens}</div>
      </div>
    );
  }

  return (
    <div className={`justify-${justifyText} flex`}>
      <Popover
        placement="top"
        theme="dark"
        showArrow
        trigger={({
          setTriggerElement,
          setIsOpen
        }: {
          setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
          setTriggerElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
        }) => (
          <div ref={setTriggerElement} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            {totalWithParens}
          </div>
        )}
      >
        <div className="w-max p-2">
          <div className="grid gap-8 whitespace-nowrap font-bold" style={{gridTemplateColumns: '1fr 60px 40px 1fr'}}>
            <div className="flex items-center gap-2">Category</div>
            <div className="flex items-center justify-end gap-2">Rate</div>
            <div className="flex items-center justify-end gap-2">Qty</div>
            <div className="flex items-center justify-end gap-2">Total</div>
          </div>
          {breakdownCharges.map((charge) => (
            <div
              key={charge.id}
              className="grid gap-8 whitespace-nowrap"
              style={{gridTemplateColumns: '1fr 60px 40px 1fr'}}
            >
              <div className="flex items-center gap-2">{charge.unit_name || '--'}</div>
              <div className="flex items-center justify-end gap-2">{charge.unit_amount.toLocaleString() || '--'}</div>
              <div className="flex items-center justify-end gap-2">{charge.unit_quantity || '--'}</div>
              <div className="flex items-center justify-end gap-2">
                {charge.amount ? formatCurrency(charge.amount, charge.unit_amount_currency) : '--'}
              </div>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export const InfoTooltip = ({children}: {children: ReactNode}) => {
  const size = 'size-5';
  return (
    <Popover
      placement="top"
      showArrow
      theme="dark"
      trigger={({
        setIsOpen,
        setTriggerElement
      }: {
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setTriggerElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
      }) => (
        <div
          ref={setTriggerElement}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className={size}
        >
          <SvgIcon className={`${size} fill-sw-icon`} name="InfoOutlined" />
        </div>
      )}
    >
      <div className="max-w-md p-2">{children}</div>
    </Popover>
  );
};
