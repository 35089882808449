import {
  CLEAR_SELECTED_TASK,
  SELECT_TASK,
  SELECT_CASE,
  CLEAR_SELECTED_CASE,
  LOADING_TASKS_FOR_CASE,
  GET_TASKS_BY_CASE,
  GET_CASE_NOTES,
  GET_CASES,
  GET_TASKS,
  GET_IDENTIFYING_CODES,
  GET_NOTIFICATION_PREFERENCES,
  SELECT_USER,
  CLEAR_SELECTED_USER,
  GET_COMPANY_USERS,
  CREATE_COMPANY_USER,
  EDIT_COMPANY_USER,
  DELETE_COMPANY_USER,
  GET_PERMISSIONS,
  REQ_USER_DATA,
  RESET_PASSWORD,
  USER_ACCT_ERROR,
  TOGGLE_SIDE_MENU,
  GET_COMPANY_PREFERENCES
} from '../actions/types';
import {countryDefaults} from 'App/utils/internationalConstants';

export const INITIAL_STATE = {
  details: null,
  isLoading: false,
  error: false,
  notifications: [],
  completedWelcome: false,
  openMenu: false,
  companyUsers: [],
  isCompanyUsersLoading: true,
  isNotificationsLoading: true,
  permissionsCategories: [],
  permissionsArray: [],
  selectedUser: {
    permissionsObj: {
      analytics: false,
      billing: false,
      carrier_relationships: false,
      customer_relationships: false,
      company: false,
      messaging: false,
      quotes: false,
      shipments: false,
      users: false,
      orders: false,
      integrations: false,
      payment_processors: false
    }
  },
  identifyingCodes: [],
  notificationTypes: [],
  notificationPreferences: [],
  preferences: null,
  tasks: [],
  cases: [],
  selectedTask: {description: '', assignee: null},
  selectedCase: {title: '', description: '', assignee: null},
  loadingCases: [],
  unitPreferences: countryDefaults.find((e) => e.country === 'US').unitPreferences // default to US
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_SELECTED_TASK:
      return {
        ...state,
        selectedTask: {description: '', assignee: action.payload}
      };
    case SELECT_TASK:
      const task = JSON.parse(JSON.stringify(action.payload));
      task.assignee = task.assignee.id;
      return {
        ...state,
        selectedTask: task
      };
    case SELECT_CASE:
      const oneCase = JSON.parse(JSON.stringify(action.payload));
      oneCase.assignee = oneCase.assignee.id;
      return {
        ...state,
        selectedCase: oneCase
      };
    case CLEAR_SELECTED_CASE:
      return {
        ...state,
        selectedCase: {title: action.payload.title, assignee: action.payload.user}
      };

    case LOADING_TASKS_FOR_CASE:
      const loadingCases = state.loadingCases;
      if (loadingCases.filter((e) => e.id === action.payload.id).length > 0) {
        if (action.payload.bool === false) {
          //remove it from the actively loading list
          loadingCases.splice(loadingCases.indexOf(loadingCases.filter((e) => e.id === action.payload.id)[0]));
        }
      } else {
        if (action.payload.bool === true) {
          loadingCases.push(action.payload);
        }
      }
      return {
        ...state
      };

    case GET_TASKS_BY_CASE:
      var cases = state.cases;
      //cycle through the current cases and plug in the tasks wherever they belong to cases
      if (cases.results && cases.results.length > 0) {
        if (action.payload.results.length > 0) {
          for (let i = 0; i < cases.results.length; i++) {
            if (cases.results[i].id === action.payload.results[0].case) {
              cases.results[i].tasks = action.payload.results;
            }
          }
        } else {
          cases.results[i].tasks = [];
        }
      }
      return {
        ...state,
        cases: cases
      };

    case GET_CASE_NOTES:
      var cases = state.cases;
      //cycle through the current cases and plug in the tasks wherever they belong to cases
      if (cases.results && cases.results.length > 0) {
        if (action.payload.results.length > 0) {
          for (let i = 0; i < cases.results.length; i++) {
            if (cases.results[i].id === action.payload.results[0].case) {
              cases.results[i].notes = action.payload.results;
            }
          }
        } else {
          cases.results[i].notes = [];
        }
      }
      return {
        ...state,
        cases: cases
      };

    case GET_CASES:
      return {
        ...state,
        cases: action.payload
      };
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload
      };

    case GET_IDENTIFYING_CODES:
      return {
        ...state,
        identifyingCodes: action.payload
      };

    case GET_NOTIFICATION_PREFERENCES:
      const notificationTypes = [];
      for (var i = 0; i < action.payload.length; i++) {
        if (!notificationTypes.includes(action.payload[i].notification_type)) {
          notificationTypes.push(action.payload[i].notification_type);
        }
      }
      const aggregated = [];
      const notificationForm = {};
      let countInbox = 0;
      let countEmail = 0;
      for (var j = 0; j < notificationTypes.length; j++) {
        const obj = {};
        const filtered = action.payload.filter((e) => e.notification_type === notificationTypes[j]);
        notificationForm[notificationTypes[j]] = {};

        for (var k = 0; k < filtered.length; k++) {
          if (filtered[k].medium === 'inbox' || filtered[k].medium === 'email') {
            const type = filtered[k].medium;
            obj[type] = filtered[k].enabled;
            if (type === 'inbox') {
              if (obj[type] === true) {
                countInbox++;
              }
            } else if (type === 'email') {
              if (obj[type] === true) {
                countEmail++;
              }
            }
            notificationForm[notificationTypes[j]][type] = obj[type];
          }
        }

        obj.notification_type = notificationTypes[j];
        obj.details = filtered;

        aggregated.push(obj);
      }

      //set the initial values of the select All sliders
      const selectAll = {};
      selectAll.email = countEmail === notificationTypes.length;
      selectAll.inbox = countInbox === notificationTypes.length;

      notificationForm.selectAll = selectAll;

      //get the data in a good format for the form

      return {
        ...state,
        notificationPreferences: aggregated,
        notificationOriginals: action.payload,
        notificationTypes: notificationTypes,
        notificationForm: notificationForm
      };

    case SELECT_USER:
      //get the categories of permissions out of the user
      const user = action.payload;
      user.permissionsObj = {
        analytics: false,
        billing: false,
        company: false,
        messaging: false,
        shipments: false,
        carrier_relationships: false,
        customer_relationships: false,
        quotes: false,
        users: false,
        integrations: false,
        payment_processors: false
      };
      for (var i = 0; i < user.permissions.length; i++) {
        user.permissionsObj[user.permissions[i].split('.')[0]] = true;
      }
      return {
        ...state,
        selectedUser: user
      };

    case CLEAR_SELECTED_USER:
      return {
        ...state,
        selectedUser: INITIAL_STATE.selectedUser
      };

    case GET_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.payload,
        isCompanyUsersLoading: false,
        isLoading: false
      };

    case CREATE_COMPANY_USER:
      var currentUsers = [...(state.companyUsers.results || []), action.payload.data || action.payload];

      return {
        ...state,
        companyUsers: {results: currentUsers}
      };

    case EDIT_COMPANY_USER:
      var currentUsers = state.companyUsers;
      for (let i = 0; currentUsers.results && i < currentUsers.results.length; i++) {
        if (currentUsers.results[i].id === action.payload.id) {
          currentUsers.results[i] = action.payload;
        }
      }
      return {
        ...state,
        companyUsers: currentUsers
      };

    case DELETE_COMPANY_USER:
      var currentUsers = state.companyUsers;
      for (let i = 0; i < currentUsers.results.length; i++) {
        if (currentUsers.results[i].id === action.payload) {
          currentUsers.results.splice(i, 1);
        }
      }
      return {
        ...state,
        companyUsers: currentUsers
      };

    case GET_PERMISSIONS:
      const permissionsCategories = Object.keys(action.payload);
      const permissionsArray = [];
      Object.keys(action.payload).forEach(function (key) {
        permissionsArray.push(action.payload[key]);
      });
      //add a key for each permissions type to use later
      for (let i = 0; i < permissionsArray.length; i++) {
        permissionsArray[i].key = permissionsArray[i].permissions[0].key.split('.')[0];
      }
      return {
        ...state,
        permissionsCategories: permissionsCategories.sort(),
        permissionsArray: permissionsArray.sort(function (a, b) {
          return a.permissions[0].key > b.permissions[0].key;
        })
      };

    case REQ_USER_DATA:
      return {
        ...state,
        isLoading: true,
        error: false
      };

    case RESET_PASSWORD:
      return {
        ...state,
        isLoading: false,
        error: false
      };

    case USER_ACCT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
        details: action.payload
      };

    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        openMenu: action.payload
      };
    case GET_COMPANY_PREFERENCES:
      const {country} = action.payload;
      const {unitPreferences} = countryDefaults.find((e) => e.country === country);
      const {currency} = unitPreferences;
      return {
        ...state,
        preferences: action.payload,
        country,
        unitPreferences,
        currency
      };

    default:
      return state;
  }
}
