import {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {useFlags} from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import {Button, IconButton} from '@shipwell/shipwell-ui';
import renderDropDownSelector from 'App/formComponents/renderDropDownSelector';
import renderNoLabelField from 'App/formComponents/renderNoLabelField';
import {removeCommasAndDollarSign} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';
import {InlineGroupDropDownSelector} from 'App/containers/Book/FormSections/Financials/components/';

const RenderMultipleFinancials = ({
  fields,
  currency,
  financialTotals,
  prepaidTotals,
  chargeCategories,
  selectedRateTable,
  meta,
  mode,
  chargeLineItemCustomFields,
  changeField,
  additionalGridTemplateColumns = false,
  chargeCodesValues,
  form
}) => {
  const {shipmentFinancialUpdate24, chargeLineItemsCustomFieldsFix} = useFlags();

  useEffect(() => {
    if (!selectedRateTable) {
      return;
    }
    if ('vendor' in selectedRateTable) {
      fields?.push(selectedRateTable);
    }
    if ('customer' in selectedRateTable) {
      fields?.push(selectedRateTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRateTable]);

  const {error} = meta;

  const baseColumnsCount = ['category', 'unit_name', 'unit_amount', 'unit_quantity'].length;
  const customFieldsCount = (chargeLineItemCustomFields || []).length;
  const hasCustomData = customFieldsCount > 0;
  const prepaidColumnCount = mode === 'vendor' ? 'repeat(1, minmax(0, 1fr))' : '';
  const totalsColumnCount = 1;
  const repeatColumnsCount = additionalGridTemplateColumns ? 4 : 0;

  const columnCount = baseColumnsCount + customFieldsCount + totalsColumnCount - repeatColumnsCount;
  const gridTemplateColumnStyle = additionalGridTemplateColumns
    ? hasCustomData
      ? `290px 150px repeat(${columnCount}, minmax(0, 1fr)) 65px ${prepaidColumnCount} 135px`
      : `300px 300px 135px 65px ${prepaidColumnCount} 1fr`
    : `repeat(${columnCount}, minmax(0, 1fr))`;
  return (
    <div>
      {fields.map((financial, index) => {
        return (
          <div key={index} className="grid gap-x-4" style={{gridTemplateColumns: gridTemplateColumnStyle}}>
            {shipmentFinancialUpdate24 ? (
              <Field
                component={InlineGroupDropDownSelector}
                name={`${financial}.category`}
                changeField={changeField}
                index={index}
                chargeCodesValues={chargeCodesValues}
                formName={form}
              />
            ) : (
              <Field
                component={renderDropDownSelector}
                data={chargeCategories}
                nolabel
                name={`${financial}.category`}
              />
            )}

            <Field component={renderNoLabelField} name={`${financial}.unit_name`} placeholder="e.g., Line Haul" />
            <Field component={renderNoLabelField} name={`${financial}.charge_code`} type="hidden" />

            {chargeLineItemCustomFields
              ? chargeLineItemCustomFields.map((customField) => (
                  <Fragment key={customField.id}>
                    {(() => {
                      if (customField.field_type === 'STRING') {
                        return (
                          <Field
                            placeholder={customField.label}
                            component={renderNoLabelField}
                            name={`${financial}.custom_data.${customField.name}`}
                            req={customField.required}
                          />
                        );
                      }

                      if (customField.field_type === 'SELECTION') {
                        const allowedValueOptions = customField.allowed_values.map((allowedType) => ({
                          id: allowedType.value,
                          name: allowedType.label
                        }));

                        return (
                          <Field
                            component={renderDropDownSelector}
                            req={customField.required}
                            placeholder={customField.required ? '--' : undefined}
                            data={
                              customField.required
                                ? allowedValueOptions
                                : [{id: '', name: '--'}, ...allowedValueOptions]
                            }
                            chargeLineItemsCustomFieldsFix={chargeLineItemsCustomFieldsFix}
                            nolabel
                            name={`${financial}.custom_data.${customField.name}`}
                          />
                        );
                      }

                      return null;
                    })()}
                  </Fragment>
                ))
              : null}

            <Field
              component={renderNoLabelField}
              name={`${financial}.unit_amount`}
              placeholder="Rate"
              normalize={(value) => removeCommasAndDollarSign(value)}
            />

            <Field component={renderNoLabelField} name={`${financial}.unit_quantity`} placeholder="e.g., 1" />

            {mode === 'vendor' && (
              <Field
                component={renderNoLabelField}
                name={`${financial}.prepaid_amount`}
                placeholder="Prepaid"
                normalize={(value) => removeCommasAndDollarSign(value)}
              />
            )}

            <div className="pt-1">
              <div className="flex items-center justify-end gap-4">
                <div className="whitespace-nowrap text-right">
                  {formatCurrency(
                    prepaidTotals && prepaidTotals[index]
                      ? financialTotals[index] - prepaidTotals[index]
                      : financialTotals[index],
                    currency
                  )}
                </div>
                <IconButton
                  iconName="TrashOutlined"
                  aria-label="Remove"
                  onClick={() => {
                    fields.remove(index);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="quote-flow__financialsLineItem-addButtons pad-top pl-4">
        <Button
          variant="tertiary"
          iconName="AddCircleOutlined"
          type="button"
          onClick={() => fields.push({unit_quantity: 1, ...(!shipmentFinancialUpdate24 && {category: 'LH'})})}
          isCompact
        >
          Add Line Item
        </Button>
      </div>
      {error && (
        <p className="error-text-form-level pad-top">
          <i className="icon icon-Delayed pad-right" />
          {error}
        </p>
      )}
    </div>
  );
};

RenderMultipleFinancials.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
    remove: PropTypes.func
  }),
  currency: PropTypes.string,
  financialTotals: PropTypes.array,
  prepaidTotals: PropTypes.array,
  chargeCategories: PropTypes.array,
  selectedRateTable: PropTypes.object,
  metadata: PropTypes.object,
  meta: PropTypes.object,
  mode: PropTypes.string,
  chargeLineItemCustomFields: PropTypes.array,
  changeField: PropTypes.func,
  additionalGridTemplateColumns: PropTypes.bool,
  chargeCodesValues: PropTypes.array,
  form: PropTypes.string
};

export default connect((state) => ({
  selectedRateTable: state.shipmentdetails?.selectedRateTable
}))(RenderMultipleFinancials);

export const FinancialsColumnHeaders = ({labels, mode, additionalGridTemplateColumns = false}) => {
  const labelsWithoutCustomData = mode === 'vendor' ? 6 : 5;
  const hasCustomData = labels.length > labelsWithoutCustomData;
  const repeatColumnsCount = mode === 'vendor' ? 5 : 4;
  const secondRepeatColumnsCount = mode === 'vendor' ? 'repeat(1, minmax(0, 1fr))' : '';
  const columnCount = additionalGridTemplateColumns ? labels.length - repeatColumnsCount : labels.length;
  const gridTemplateColumnStyle = additionalGridTemplateColumns
    ? hasCustomData
      ? `290px 150px repeat(${columnCount}, minmax(0, 1fr)) 65px ${secondRepeatColumnsCount} 135px`
      : `300px 300px 135px 65px ${secondRepeatColumnsCount} 1fr`
    : `repeat(${columnCount}, minmax(0, 1fr))`;
  return (
    <div className="my-2 grid gap-x-4" style={{gridTemplateColumns: gridTemplateColumnStyle}}>
      {labels.map((label) => (
        <div
          key={label}
          className={classNames('min-w-[5rem] text-[10px]', {
            'text-right': label.toLowerCase() === 'total',
            'pr-10 font-bold text-sm': !additionalGridTemplateColumns
          })}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

FinancialsColumnHeaders.propTypes = {
  labels: PropTypes.array,
  mode: PropTypes.string,
  additionalGridTemplateColumns: PropTypes.bool
};
