/* eslint-disable promise/no-nesting */
/* eslint-disable promise/always-return */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable promise/catch-or-return */
/* eslint-disable import/no-namespace */
import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactTable from 'react-table-6';
import {compose} from 'recompose';
import moment from 'moment';
// App files
import {DeprecatedButton, Card, Checkbox, Modal} from '@shipwell/shipwell-ui';
import withFlags from 'App/utils/withFlags';
import {getIsLTL} from 'App/containers/Dashboard/columns';
import * as shipmentActions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import * as formActions from 'App/actions/forms';
import * as billingActions from 'App/actions/billing';
import * as policyActions from 'App/actions/policy';
import * as userActions from 'App/actions/users';
import * as marketplaceActions from 'App/actions/marketplace';
import {formatCurrencyValue, transformShipmentToForm} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {SourceTypeEnum} from 'App/api/quoting/typed';
import {
  serviceLevelAccessor,
  instantQuotesFilter
} from 'App/containers/Marketplace/components/InstantRates/legacy/utils';

import './_styles.scss';

let quotingTimeout;
let quoteInterval = null;
const DATE_NOT_PROVIDED = 'Not provided';

class InstantRates extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.getQuotes = this.getQuotes.bind(this);
    //this.handleQuoteFilterChange = this.handleQuoteFilterChange.bind(this);
    this.handleSelectQuote = this.handleSelectQuote.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleMarketplaceRedirect = this.handleMarketplaceRedirect.bind(this);
    this.handleManualRedirect = this.handleManualRedirect.bind(this);
    this.handleEditQuote = this.handleEditQuote.bind(this);
    this.QUOTING_INTERVAL_TIME = 60000;
    this.DEFAULT_POOL_FOR_QUOTES_INTERVAL = 2000;
    this.state = {
      loading: true,
      quoteFilter: 'price',
      expandedFinancials: [],
      expanded: {},
      pagination: {includeFailures: true, pageSize: 100, page: 1, ordering: ['total']},
      showFTLConfirmation: false,
      dontShowMessage: false,
      selectedFTLQuote: null
    };
  }

  componentDidMount() {
    if (this.props.company && this.props.company.id) {
      this.initialize();
    }
    quotingTimeout = setTimeout(() => {
      if (quoteInterval) {
        //after `this.QUOTING_INTERVAL_TIME` milliseconds, kill the quoting interval
        this.props.dispatchError(
          `New quotes were not retrieved within ${Math.ceil(
            this.QUOTING_INTERVAL_TIME / 1000
          )} seconds. Please refresh the page to try again.`
        );
        clearInterval(quoteInterval);
        quoteInterval = null;
        this.setState({loading: false});
      }
    }, this.QUOTING_INTERVAL_TIME);
  }

  componentDidUpdate(prevProps) {
    if (this.props.company !== prevProps.company) {
      this.initialize();
    }
    if (this.props.selectedShipment !== prevProps.selectedShipment) {
      this.initialize();
    }
  }

  initialize() {
    this.props.fetchHazmatCodes();
    if (this.props.selectedShipment) {
      const parentRFQ = this.getParentRFQ(this.props.selectedShipment);
      if (parentRFQ) {
        this.getRFQDetails(parentRFQ);
      } else {
        // there is no RFQ to get details, simply stop loading spinner
        this.setState({loading: false});
      }
    }
  }

  getParentRFQ(shipment) {
    let rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    //if there are no RFQs, maybe show error message?
    if (rfqs.length > 0) {
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);

      //if you are the owner of the shipment, filter down to just RFQs that you own (since you can't see broker's)
      if (shipment.customer.id === this.props.company.id) {
        rfqs = rfqs.filter((e) => e.company_owner_id === this.props.company.id);
      } else {
        //if you are a broker on this shipment (not the root customer), you should also be looking at the customer's RFQ
        //so that you correctly see your markup attached to the quote you are working with
        rfqs = rfqs.filter((e) => e.company_owner_id === shipment.customer.id);
      }

      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];

      return lastRFQ;
    }

    return null;
  }

  pollForQuotes(ms = this.DEFAULT_POOL_FOR_QUOTES_INTERVAL) {
    const {selectedRFQ} = this.props;
    if (selectedRFQ && selectedRFQ.autoquote && !selectedRFQ.did_finish_autoquote) {
      //get quote details
      quoteInterval = setInterval(() => {
        this.getRFQDetails(selectedRFQ);
      }, ms);
    } else if (selectedRFQ && selectedRFQ.autoquote && selectedRFQ.did_finish_autoquote) {
      clearInterval(quoteInterval);
      quoteInterval = null;
      clearTimeout(quotingTimeout);
      this.setState({loading: false});
    }
  }

  getRFQDetails(RFQ) {
    const opts = {includeFailures: true};
    if (RFQ.company_owner_id !== this.props.company.id) {
      opts['xCompanyId'] = RFQ.company_owner_id;
    }
    this.props.getRFQDetails(RFQ.id, opts).then((response) => {
      if (response && response.details && response.details.autoquote && response.details.did_finish_autoquote) {
        //kill the loop now
        clearInterval(quoteInterval);
        quoteInterval = null;
        clearTimeout(quotingTimeout);
        this.setState({loading: false});
      } else if (response && response.details && !response.details.autoquote) {
        //then we aren't looking for instant rates, simply stop loading
        this.setState({loading: false});
      } else {
        if (!quoteInterval) {
          //if we havent started polling yet, do so now
          this.pollForQuotes();
        }
      }
    });
  }

  getQuotes(shipment) {
    //get the most recent RFQ for this shipment, and check if autoquoting finished
    let rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    //if there are no RFQs, maybe show error message?
    if (rfqs.length > 0) {
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);
      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];
      const opts = {includeFailures: true};
      if (lastRFQ.company_owner_id !== this.props.company.id) {
        opts['xCompanyId'] = lastRFQ.company_owner_id;
      }

      this.props.getRFQDetails(lastRFQ.id, opts).then((response) => {
        //if this quote was being autoquoted and did not finish, debounce and check again in one second
        if (
          response &&
          response.details &&
          response.details.autoquote === true &&
          response.details.did_finish_autoquote === false
        ) {
          setTimeout(() => this.fetchShipmentDetails(), 2000);
        }

        if (shipment.customer.id !== this.props.company.id) {
          //if this is a customer's RFQ, also get the broker RFQ at this point to make sure we can start a spot neg
          //then also get the broker's RFQ, which will contain the spot negotiations on it
          let brokerRFQs = JSON.parse(JSON.stringify(shipment.rfqs));
          brokerRFQs = brokerRFQs.filter(
            (e) => e.has_parent_rfq === true && e.company_owner_id === this.props.company.id
          );
          brokerRFQs = brokerRFQs.sort(function (a, b) {
            return a.updated_at > b.updated_at ? -1 : 1;
          });
          const lastBrokerRFQ = brokerRFQs[0];
          //set the brokerRFQ for use later from state
          if (lastBrokerRFQ) {
            this.props.getBrokerRFQDetails(lastBrokerRFQ.id).then((rfqResponse) => {
              this.props.setSelectedBrokerRFQ(rfqResponse.details);
              this.setState({
                loading: false
              });
              clearTimeout(quotingTimeout);

              if (this.props.marketplaceRedirect === true) {
                this.props.triggerMarketplaceRedirect(false);

                //TODO figure out if this is needed

                //this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/my-marketplace');
              }

              let quotes = [];
              if (this.props.selectedRFQ) {
                quotes = this.props.selectedRFQ.quotes?.filter(
                  ({source_type: sourceType}) =>
                    sourceType === SourceTypeEnum.Instant || sourceType === SourceTypeEnum.CarrierConnection
                );
              }
              if (quotes.length === 0 && this.props.initialQuoteLoading === true) {
                if (
                  this.props.selectedRFQ &&
                  this.props.selectedRFQ.quotes?.filter(
                    ({source_type: sourceType}) => sourceType === SourceTypeEnum.PrivateMarketplace
                  ).length > 0
                ) {
                  //push directly to the my-marketplace page
                  this.props.setInitialQuoteLoading(false);
                  this.handleMarketplaceRedirect();
                }
              }
            });
          } else {
            //we need to create a new RFQ if one doesn't exist to match these criteria.
            //this happens when brokers dont rate share with the customer, but want to create a marketplace for them
            const rfqObj = {};
            rfqObj.autoquote = false;
            rfqObj.shipment = shipment.id;
            rfqObj.parent_rfq = response.details.id;
            this.props.createRFQ(rfqObj, {}).then((newRFQResponse) => {
              if (newRFQResponse.status > 299) {
                throw Error('Received RFQ with response status code ' + newRFQResponse.status);
              }

              this.setState({loading: false});
              clearTimeout(quotingTimeout);
              this.props.setSelectedBrokerRFQ(newRFQResponse.details);
              //ensure we don't overwrite the selectedRFQ
              this.props.getRFQDetails(response.details.id, opts);
              if (this.props.marketplaceRedirect === true) {
                this.props.triggerMarketplaceRedirect(false);
                this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/my-marketplace');
              }

              const quotes = this.props.selectedRFQ.quotes?.filter(
                ({source_type: sourceType}) =>
                  sourceType === SourceTypeEnum.Instant || sourceType === SourceTypeEnum.CarrierConnection
              );
              if (quotes.length === 0 && this.props.initialQuoteLoading === true) {
                if (
                  this.props.selectedRFQ.quotes?.filter(
                    ({source_type: sourceType}) => sourceType === SourceTypeEnum.CarrierConnection
                  ).length > 0
                ) {
                  //push directly to the my-marketplace page
                  this.props.setInitialQuoteLoading(false);
                  this.handleMarketplaceRedirect();
                }
              }
            });
          }
        }

        this.setState({loading: false});
        clearTimeout(quotingTimeout);
        this.props.setSelectedBrokerRFQ(response.details);
        if (this.props.marketplaceRedirect === true) {
          this.props.triggerMarketplaceRedirect(false);
          this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/my-marketplace');
        }
        let quotes = [];
        if (this.props.selectedRFQ) {
          quotes = this.props.selectedRFQ.quotes?.filter(
            ({source_type: sourceType}) =>
              sourceType === SourceTypeEnum.Instant || sourceType === SourceTypeEnum.CarrierConnection
          );
        }
        if (quotes.length === 0 && this.props.initialQuoteLoading === true) {
          if (
            this.props.selectedRFQ &&
            this.props.selectedRFQ.quotes &&
            this.props.selectedRFQ.quotes?.filter(
              ({source_type: sourceType}) => sourceType === SourceTypeEnum.PrivateMarketplace
            ).length > 0
          ) {
            //push directly to the my-marketplace page
            this.props.setInitialQuoteLoading(false);
            //this.handleMarketplaceRedirect();
          }
        }
      });
    } else {
      this.setState({loading: false});
      clearTimeout(quotingTimeout);
      if (this.props.marketplaceRedirect === true) {
        this.props.triggerMarketplaceRedirect(false);
        this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/my-marketplace');
      }

      const quotes = this.props.selectedRFQ.quotes?.filter(
        ({source_type: sourceType}) =>
          sourceType === SourceTypeEnum.Instant || sourceType === SourceTypeEnum.CarrierConnection
      );
      if (quotes.length === 0 && this.props.initialQuoteLoading === true) {
        if (
          this.props.selectedRFQ.quotes?.filter(
            ({source_type: sourceType}) => sourceType === SourceTypeEnum.PrivateMarketplace
          ).length > 0
        ) {
          //push directly to the my-marketplace page
          this.props.setInitialQuoteLoading(false);
          //this.handleMarketplaceRedirect();
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(quotingTimeout);
    clearInterval(quoteInterval);
    quoteInterval = null;
  }

  handleSelectQuote(quote) {
    this.props.selectQuote(quote);
    let shipment = JSON.parse(JSON.stringify(this.props.selectedShipment));
    const mode = quote.mode;
    let equipmentTypes = [];
    if (quote.equipment_type) {
      //default to the first one until we build some UI around picking the equipment type if multiple
      equipmentTypes = [quote.equipment_type];
    }
    shipment = transformShipmentToForm(shipment, this.props.hazmatCodes, [mode], equipmentTypes, true);
    this.props.selectFormShipment(shipment);
    if (quote.mode.id === 1) {
      //FTL instant rate
      localStorage.setItem('swHideRateApproval', this.state.dontShowMessage);
    }
    //go directly to the confirm details page for LTL quotes
    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/confirm?mode=instant-rates');
  }

  handleSelectFTLQuote(quote) {
    this.setState({selectedFTLQuote: quote});
    //show a modal with confirmation that the rate must be approved first
    const swHideRateApproval = localStorage.getItem('swHideRateApproval');
    if (swHideRateApproval === 'true') {
      this.handleSelectQuote(quote);
    } else {
      this.setState({showFTLConfirmation: true});
    }
  }

  handleRedirect() {
    let shipment = JSON.parse(JSON.stringify(this.props.selectedShipment));
    shipment = transformShipmentToForm(shipment);
    this.props.selectFormShipment(shipment);

    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/edit');
  }

  handleMarketplaceRedirect() {
    this.props.setDefaultCommunicationTab('carriers');
    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/my-marketplace');
  }

  handleManualRedirect() {
    this.props.setDefaultCommunicationTab('customer');
    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/my-marketplace');
  }

  handleEditQuote() {
    this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/edit');
  }

  toggleFinancials(quoteId, e) {
    e.stopPropagation();

    const current = JSON.parse(JSON.stringify(this.state.expandedFinancials));
    if (current.includes(quoteId)) {
      current.splice(current.indexOf(quoteId), 1);
    } else {
      current.push(quoteId);
    }
    this.setState({expandedFinancials: current});
  }

  toggleExpanded(index, e) {
    e.stopPropagation();

    const current = JSON.parse(JSON.stringify(this.state.expanded));
    if (current[index]) {
      current[index] = false;
    } else {
      current[index] = true;
    }
    this.setState({expanded: current});
  }

  renderFinancials(quote) {
    if (!quote || quote.is_failure) {
      return '--';
    }

    const currency = quote.currency;

    return (
      <div className="instant-rates__expandedFinancials">
        {quote.charge_line_items &&
          quote.charge_line_items.map((charge, i) => {
            return (
              <div className="quoteItem__financials-row" key={i}>
                <div className="quoteItem__financials-name">
                  {charge.is_provider_markup
                    ? charge.unit_name +
                      ' (' +
                      formatCurrencyValue(parseFloat((charge.unit_amount * 100) / (quote.total - charge.unit_amount))) +
                      '%):'
                    : charge.unit_name + ': '}
                </div>
                <div className="quoteItem__financials-amount">{formatCurrency(charge.unit_amount, currency)}</div>
              </div>
            );
          })}
        {quote.customer_markup && quote.customer_markup !== 0 ? (
          <div className="quoteItem__financials-row">
            <div className="quoteItem__financials-name">
              Markup
              {' (' +
                formatCurrencyValue(parseFloat((quote.customer_markup * 100) / (quote.total - quote.customer_markup))) +
                '%):'}
            </div>
            <div className="quoteItem__financials-amount">{formatCurrency(quote.customer_markup, currency)}</div>
          </div>
        ) : (
          ''
        )}
        {quote.total && (
          <div className="quoteItem__financials-row">
            <div className="quoteItem__financials-name">
              <b>Total</b>
            </div>
            <div className="quoteItem__financials-amount">
              <b>{formatCurrency(quote.total, currency)}</b>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderDetails(row) {
    const quote = row.original;
    const deliveryDate = moment(quote.delivery_date).isValid()
      ? moment(quote.delivery_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
      : 'Delivery date not provided';
    let transitTime = row.original.transit_days;
    if (isNaN(transitTime) || transitTime === null || transitTime === 0 || !parseInt(transitTime, 10)) {
      transitTime = 'Transit time not provided';
    } else {
      if (transitTime === 1) {
        transitTime = transitTime + ' day';
      } else {
        transitTime = transitTime + ' days';
      }
    }
    const laneType = quote.lane_type !== 'UNSPECIFIED' ? startCaseToLower(quote.lane_type) : '';
    const serviceLevel = quote && quote.service_level ? quote.service_level.description : '';
    const dispatchError =
      quote && !quote.can_dispatch ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="bottom" id="tooltip">
              <div>{quote.error_message}</div>
            </Tooltip>
          }
        >
          <i className="icon icon-Delayed text-danger" />
        </OverlayTrigger>
      ) : (
        ''
      );
    const isVLTL =
      quote && quote.mode && quote.mode.code === 'VLTL' ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="bottom" id="tooltip">
              <div>Volume LTL quote</div>
            </Tooltip>
          }
        >
          <i className="icon icon-Adjust" />
        </OverlayTrigger>
      ) : (
        ''
      );
    const isCSP =
      quote && quote.is_csp_rate ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="bottom" id="tooltip">
              <div>Customer-specific rate</div>
            </Tooltip>
          }
        >
          <i className="icon icon-Handshake" />
        </OverlayTrigger>
      ) : (
        ''
      );

    const providerLogo = quote.provider_logo_url ? <img src={quote.provider_logo_url} height="14" alt="Echo" /> : '';

    const quoteOwner =
      quote.created_by_company.is_shipwell === true ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="bottom" id="tooltip">
              <span>CloudTrucks rate</span>
            </Tooltip>
          }
        >
          <img className="quoteItem__belongsToCloudTrucks" src="/images/cloudtrucks_logo.png" alt="CloudTrucks" />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip placement="bottom" id="tooltip">
              <span>
                {quote.created_by_company.id === this.props.company.id
                  ? this.props.company.name
                  : quote.created_by_company.name}{' '}
                rate
              </span>
            </Tooltip>
          }
        >
          <span className="quoteItem__belongsTo">
            {quote.created_by_company.id === this.props.company.id
              ? this.props.company.name.split('')[0].toUpperCase()
              : quote.created_by_company.name.split('')[0].toUpperCase()}
          </span>
        </OverlayTrigger>
      );

    return (
      <div className="instant-rates__columns-details">
        <span>{deliveryDate}</span>
        <span>{transitTime}</span>
        <span>{laneType}</span>
        <span>{serviceLevel}</span>
        <span className="instant-rates__columns-details-icons">
          {isVLTL}
          {isCSP}
          {providerLogo}
          {quoteOwner}
          {dispatchError}
        </span>
      </div>
    );
  }

  renderQuotes() {
    const {selectedShipment, intLtl3RdPartySuppressRates, intHideSwlDuplicateRates} = this.props;
    let allAcc = [];
    if (selectedShipment && selectedShipment.accessorials.length > 0) {
      allAcc = allAcc.concat(selectedShipment.accessorials);
    }
    if (selectedShipment && selectedShipment.stops && selectedShipment.stops.length > 0) {
      for (let i = 0; i < selectedShipment.stops.length; i++) {
        if (selectedShipment.stops[i].accessorials.length > 0) {
          allAcc = allAcc.concat(selectedShipment.stops[i].accessorials);
        }
      }
    }

    const shouldHideRates =
      intLtl3RdPartySuppressRates &&
      !!selectedShipment?.metadata?.bill_to_override?.direction &&
      ['COLLECT', '3RD_PARTY'].includes(selectedShipment?.metadata?.bill_to_override?.direction) &&
      selectedShipment?.mode?.code === 'LTL';

    //filter down to just INSTANT quotes
    const instantQuotes = this.props.selectedRFQ.quotes.filter(
      ({source_type: sourceType}) =>
        sourceType === SourceTypeEnum.Instant || sourceType === SourceTypeEnum.CarrierConnection
    );

    // filter SWL and WWEX quotes with the same carrier and service level of client-specific quotes - if the feature flag is enabled
    const quotes = intHideSwlDuplicateRates ? instantQuotesFilter(instantQuotes) : instantQuotes;

    const serviceColumn = {
      Header: () => (
        <span>
          Service
          <span className="btn-sort" />
        </span>
      ),
      id: 'service_level',
      sortable: true,
      accessor: serviceLevelAccessor,
      Cell: (row) => {
        const quote = row.original;
        const dispatchError =
          quote && !quote.can_dispatch ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="bottom" id="tooltip">
                  <div>{quote.error_message}</div>
                </Tooltip>
              }
            >
              <i className="icon icon-Delayed text-danger" />
            </OverlayTrigger>
          ) : (
            ''
          );
        const isVLTL =
          quote && quote.mode && quote.mode.code === 'VLTL' ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="bottom" id="tooltip">
                  <div>Volume LTL quote</div>
                </Tooltip>
              }
            >
              <i className="icon icon-Adjust" />
            </OverlayTrigger>
          ) : (
            ''
          );
        const isCSP =
          quote && quote.is_csp_rate ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="bottom" id="tooltip">
                  <div>Customer-specific rate</div>
                </Tooltip>
              }
            >
              <i className="icon icon-Handshake" />
            </OverlayTrigger>
          ) : (
            ''
          );

        const providerLogo = quote.provider_logo_url ? (
          <img src={quote.provider_logo_url} height="14" alt="Echo" />
        ) : (
          ''
        );

        const quoteOwner =
          quote.created_by_company.is_shipwell === true ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="bottom" id="tooltip">
                  <span>CloudTrucks rate</span>
                </Tooltip>
              }
            >
              <img className="quoteItem__belongsToCloudTrucks" src="/images/cloudtrucks_logo.png" alt="CloudTrucks" />
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="bottom" id="tooltip">
                  <span>
                    {quote.created_by_company.id === this.props.company.id
                      ? this.props.company.name
                      : quote.created_by_company.name}{' '}
                    rate
                  </span>
                </Tooltip>
              }
            >
              <span className="quoteItem__belongsTo">
                {quote.created_by_company.id === this.props.company.id
                  ? this.props.company.name.split('')[0].toUpperCase()
                  : quote.created_by_company.name.split('')[0].toUpperCase()}
              </span>
            </OverlayTrigger>
          );
        return (
          <div className="instant-rates__columns-details">
            <span>{row.value}</span>
            <span>{row.original.lane_type !== 'UNSPECIFIED' ? startCaseToLower(row.original.lane_type) : ''}</span>
            <span className="instant-rates__columns-details-icons">
              {isVLTL}
              {isCSP}
              {providerLogo}
              {quoteOwner}
              {dispatchError}
            </span>
          </div>
        );
      }
    };

    const carrierColumn = {
      Header: () => (
        <span>
          Carrier
          <span className="btn-sort" />
        </span>
      ),
      id: 'carrier',
      sortable: true,
      accessor: (d) => (d.carrier ? d.carrier.display_name : d.created_by_company ? d.created_by_company.name : '--'),
      Cell: (row) => {
        const isFedex = row.original?.carrier?.display_name?.toLowerCase() === 'fedex';
        const isLTL = getIsLTL(row.original.mode?.code || '');
        return (
          <div className="flex flex-col">
            <div className="mb-2">
              {row.original.carrier
                ? row.original.carrier.display_name
                : row.original.created_by_company
                ? row.original.created_by_company.name
                : '--'}
              {isFedex && !!row.original?.carrier_code ? ` (${row.original.carrier_code})` : null}
            </div>
            {isLTL && this.props.intP44MultiAccountCode && row.original.carrier?.account_code ? (
              <div>{row.original.carrier.account_code}</div>
            ) : null}
          </div>
        );
      }
    };

    const modeColumn = {
      Header: () => (
        <span>
          Mode
          <span className="btn-sort" />
        </span>
      ),
      id: 'mode',
      sortable: true,
      accessor: (d) => (d.mode ? d.mode.code : '--')
    };

    const equipmentColumn = {
      Header: () => (
        <span>
          Equipment
          <span className="btn-sort" />
        </span>
      ),
      id: 'equipment_type',
      sortable: true,
      accessor: (d) => (d.equipment_type ? d.equipment_type.name : '--')
    };

    const transitColumn = {
      Header: () => (
        <span>
          Transit
          <span className="btn-sort" />
        </span>
      ),
      id: 'transit_days',
      sortable: true,
      accessor: (d) => d.transit_days,
      maxWidth: 100,
      sortMethod: (a, b) => {
        if (isNaN(a) || a === null || a === 0 || !parseInt(a, 10)) {
          return 1;
        }
        if (isNaN(b) || b === null || b === 0 || !parseInt(b, 10)) {
          return -1;
        }
        return a > b ? 1 : -1;
      },
      Cell: (row) => {
        let transitTime = row.value;
        if (isNaN(transitTime) || transitTime === null || transitTime === 0 || !parseInt(transitTime, 10)) {
          transitTime = 'Not provided';
        } else {
          if (transitTime === 1) {
            transitTime = transitTime + ' day';
          } else {
            transitTime = transitTime + ' days';
          }
        }
        return transitTime;
      }
    };

    const deliveryColumn = {
      Header: () => (
        <span>
          Delivery
          <span className="btn-sort" />
        </span>
      ),
      id: 'delivery_date',
      sortable: true,
      accessor: (d) => d.delivery_date,
      sortMethod: (a, b) => {
        if (!moment(a).isValid() && moment(b).isValid()) {
          return 1;
        }
        if (moment(a).isValid() && !moment(b).isValid()) {
          return -1;
        }
        return a > b ? 1 : -1;
      },
      Cell: (row) => {
        return moment(row.value).isValid()
          ? moment(row.value, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')
          : DATE_NOT_PROVIDED;
      }
    };

    const rateColumn = {
      Header: () => (
        <span className="rate-header">
          Rate
          <span className="btn-sort" />
        </span>
      ),
      id: 'total',
      sortable: true,
      accessor: (d) => d.total,
      sortMethod: (a, b) => {
        if (!a) {
          return 1;
        }
        if (!b) {
          return -1;
        }
        return a > b ? 1 : -1;
      },
      Cell: (row) => {
        if (row.original.is_failure) {
          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip placement="bottom" id="tooltip">
                  {row.original.error_message}
                </Tooltip>
              }
            >
              <span className="text-danger">
                <i className="icon icon-Delayed text-danger pad-right" /> Failed to Quote
              </span>
            </OverlayTrigger>
          );
        }
        const isExpanded = this.state.expanded[row.viewIndex];
        const rateValue = shouldHideRates ? '--' : formatCurrency(row.original.total, row.original.currency);

        const onClick = (e) => {
          if (shouldHideRates) {
            return;
          }

          return this.toggleExpanded(row.viewIndex, e);
        };

        return (
          <div className="instant-rates__total showAsLink" onClick={onClick}>
            <b>{rateValue}</b>
            {!shouldHideRates && <i className={`icon pad-left ${!isExpanded ? 'icon-Down' : 'icon-Up'}`} />}
          </div>
        );
      }
    };

    const expanderColumn = {
      expander: true,
      Header: () => '',
      headerClassName: 'hidden',
      id: 'expander',
      width: 0,
      Expander: () => {
        return <div />;
      }
    };

    const selectColumn = {
      Header: () => '',
      id: 'select',
      sortable: false,
      accessor: (d) => d.total,
      width: 90,
      Cell: (row) => {
        if (row.original.is_failure) {
          return '';
        }
        if (row.original.mode.id === 1) {
          //FTL quote
          return (
            <DeprecatedButton onClick={() => this.handleSelectFTLQuote(row.original)} variant="primary" size="small">
              Request
            </DeprecatedButton>
          );
        }
        return (
          <DeprecatedButton onClick={() => this.handleSelectQuote(row.original)} variant="primary" size="small">
            Select
          </DeprecatedButton>
        );
      }
    };

    const ltlColumns = [
      carrierColumn,
      serviceColumn,
      transitColumn,
      deliveryColumn,
      rateColumn,
      expanderColumn,
      selectColumn
    ];
    const ftlColumns = [
      carrierColumn,
      serviceColumn,
      modeColumn,
      equipmentColumn,
      rateColumn,
      expanderColumn,
      selectColumn
    ];

    const {showFTLConfirmation} = this.state;

    let columnsToShow = [];

    if (this.props.selectedRFQ.shipment_modes.find((e) => e.id === 2 || e.id === 4)) {
      columnsToShow = ltlColumns;
      if (this.props.selectedRFQ.shipment_modes.find((e) => e.id === 1)) {
        columnsToShow = [
          carrierColumn,
          serviceColumn,
          modeColumn,
          equipmentColumn,
          transitColumn,
          deliveryColumn,
          rateColumn,
          expanderColumn,
          selectColumn
        ];
      }
    } else if (this.props.selectedRFQ.shipment_modes.find((e) => e.id === 1)) {
      columnsToShow = ftlColumns;
    }

    return (
      <Fragment>
        {quotes && quotes.length > 0 ? (
          <div className="instant-rates__table table__container">
            <ReactTable
              data={quotes}
              showPagination={false}
              className="-highlight -striped"
              minRows={0}
              defaultPageSize={100}
              defaultSorted={[{id: 'total', desc: false}]}
              expanded={this.state.expanded}
              SubComponent={(row) => {
                return this.renderFinancials(row.original);
              }}
              columns={columnsToShow}
            />
          </div>
        ) : (
          <div className="paper__content text-center ">
            <div className="instant-rates__noResults">
              <h3>No instant rates available for this shipment.</h3>
              {allAcc && allAcc.length > 1 && (
                <p>
                  There are {allAcc.length} accessorials requested on this shipment. Consider removing some accessorials
                  to get instant rates from more carriers.
                </p>
              )}
            </div>
          </div>
        )}
        <Modal
          primaryBtnName="OK"
          onPrimaryAction={() => this.handleSelectQuote(this.state.selectedFTLQuote)}
          secondaryBtnName="Cancel"
          onSecondaryAction={() => this.setState({showFTLConfirmation: false})}
          onClose={() => this.setState({showFTLConfirmation: false})}
          title="Rate Approval Required"
          show={showFTLConfirmation}
        >
          {this.renderFTLConfirmationModal()}
        </Modal>
      </Fragment>
    );
  }

  renderFTLConfirmationModal() {
    const {dontShowMessage} = this.state;
    return (
      <div>
        <p>
          This request must be approved prior to finalizing the rate and scheduling pickup. You will be notified when
          the rate is approved or if additional information is required. Once approved, you will be able to schedule
          pickup.
        </p>
        <Checkbox
          label="Don't show this message again."
          value={dontShowMessage}
          onChange={(event) => {
            this.setState({dontShowMessage: event.target.checked});
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <Card
        title="Instant Rates"
        className="instant-rates"
        bodyClassName="p-0"
        actions={
          <DeprecatedButton variant="tertiary" onClick={() => this.handleEditQuote()}>
            Edit Quote
          </DeprecatedButton>
        }
      >
        {this.state.loading ? (
          <ShipwellLoader loading={this.state.loading} loadingText="Searching for quotes..." />
        ) : (
          <Fragment>{this.props.selectedRFQ && this.props.selectedRFQ.quotes && this.renderQuotes()}</Fragment>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedShipment: state.shipments.selectedShipment,
    selectedQuote: state.shipments.selectedQuote,
    selectedRFQ: state.shipments.selectedRFQ,
    error: state.shipments.error,
    user: state.auth.user,
    company: state.auth.company,
    actingAsCompany: state.brokers.actingAsCompany,
    defaultQuotesTab: state.marketplace.defaultQuotesTab,
    initialQuoteLoading: state.shipments.initialQuoteLoading,
    marketplaceRedirect: state.shipments.marketplaceRedirect,
    hazmatCodes: state.shipments.hazmatCodes
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...shipmentActions,
      ...formActions,
      ...policyActions,
      ...billingActions,
      ...userActions,
      ...brokerActions,
      ...marketplaceActions
    },
    dispatch
  );
};

InstantRates.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  intLtl3RdPartySuppressRates: PropTypes.bool,
  intHideSwlDuplicateRates: PropTypes.bool,
  marketplaceRedirect: PropTypes.bool,
  initialQuoteLoading: PropTypes.bool,
  hazmatCodes: PropTypes.array,
  selectedRFQ: PropTypes.shape({
    autoquote: PropTypes.bool,
    did_finish_autoquote: PropTypes.bool,
    shipment_modes: PropTypes.array,
    quotes: PropTypes.array
  }),
  selectedShipment: PropTypes.shape({
    id: PropTypes.string,
    accessorials: PropTypes.array,
    stops: PropTypes.arrayOf({
      accessorials: PropTypes.array
    }),
    metadata: PropTypes.shape({
      bill_to_override: PropTypes.shape({
        direction: PropTypes.string
      })
    }),
    mode: PropTypes.shape({
      code: PropTypes.string
    })
  }),
  createRFQ: PropTypes.func,
  selectQuote: PropTypes.func,
  setDefaultCommunicationTab: PropTypes.func,
  selectFormShipment: PropTypes.func,
  setInitialQuoteLoading: PropTypes.func,
  triggerMarketplaceRedirect: PropTypes.func,
  setSelectedBrokerRFQ: PropTypes.func,
  getRFQDetails: PropTypes.func,
  getBrokerRFQDetails: PropTypes.func,
  dispatchError: PropTypes.func,
  fetchHazmatCodes: PropTypes.func,
  intP44MultiAccountCode: PropTypes.bool
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFlags('intLtl3RdPartySuppressRates', 'intHideSwlDuplicateRates', 'intP44MultiAccountCode')
)(InstantRates);
