import {BulkLoadOrderOperation, OrdersApi, OrdersApiListOrdersRequest} from '@shipwell/corrogo-sdk';
import {configuration} from './configuration';
import {decimalVersionHeader} from 'App/api/consts';

const ordersApi = new OrdersApi(configuration);

export async function getOrdersList(params: OrdersApiListOrdersRequest, isUsingDecimals = false) {
  const axiosResponse = await ordersApi.listOrders(params, {
    ...(isUsingDecimals ? {headers: decimalVersionHeader} : {})
  });
  return axiosResponse.data;
}

export const executeBulkLoadOrderOperation = async (
  bulkLoadOrderOperation: BulkLoadOrderOperation[],
  isUsingDecimals = false
) => {
  const axiosResponse = await ordersApi.bulkLoadOrders(
    {bulkLoadOrderOperation},
    {
      ...(isUsingDecimals ? {headers: decimalVersionHeader} : {})
    }
  );
  return axiosResponse.data;
};
