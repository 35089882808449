import isNil from 'lodash/isNil';
import moment from 'moment-timezone';
import {
  LocationType,
  EquipmentType,
  EquipmentTypeValues,
  ShipmentLineItem,
  ShipmentLineItemWeightUnitEnum,
  Shipment,
  Stop
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  CreateFtlRateRequestTransportationModeEnum,
  WeightUnit,
  StopReason,
  FtlPackageType,
  LocationType as GenesisLocationType,
  FtlEquipmentType,
  TemperatureUnit,
  FtlShipmentLineItem,
  FtlStop,
  FtlEquipment,
  TimeWindow,
  Appointment,
  FtlProductPieceType,
  FtlStopAccessorials
} from '@shipwell/genesis-sdk';
import {Accessorial} from '@shipwell/backend-core-sdk';
import {RatingApiCreateFtlRateRequestRatesFtlPostRequest} from '@shipwell/genesis-sdk/api';

/**
 * Changes the backend-core shipment datamodel into a usable
 * data model for requesting rates from genesis.
 */
export const transformFtlPayload = (
  equipmentTypes: EquipmentType[],
  shipment: Shipment
): RatingApiCreateFtlRateRequestRatesFtlPostRequest => {
  const {stops, line_items} = shipment;
  const ftlStops = transformStops(stops ?? []);

  return {
    createFtlRateRequest: {
      customer_reference_number: shipment.customer_reference_number ? shipment.customer_reference_number : 'TESTONLY',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      transportation_mode: CreateFtlRateRequestTransportationModeEnum.Ftl,
      equipment: mapEquipment(equipmentTypes, shipment),
      stops: ftlStops,
      line_items: transformLineItems(line_items ?? [], ftlStops),
      preferred_currency: shipment.preferred_currency
    }
  };
};

/**
 * Maps backend core equipment to genesis equipment.
 */
function mapEquipment(equipmentTypes: EquipmentType[], shipment: Shipment): FtlEquipment {
  // depending on where the shipment is coming from the equipment type information will change.
  // sometimes it's an object, sometimes it's just an integer. This method will test both.
  const {
    temperature_lower_limit: temperatureLowerLimit,
    temperature_upper_limit: temperatureUpperLimit,
    equipment_type: equipmentType
  } = shipment;
  const equipment: FtlEquipment = {
    equipment_type: FtlEquipmentType.DryVan // must have default value
  };

  if (Number.isInteger(equipmentType)) {
    const mappedEquipmentType = mapEquipmentType(equipmentTypes, equipmentType as unknown as number); // equipment_type is a lie. turns out this is actually stored an integer.
    if (!isNil(mappedEquipmentType)) {
      equipment.equipment_type = mappedEquipmentType;
    }
  } else if (!isNil(equipmentType)) {
    equipment.equipment_type = equipmentType.machine_readable as FtlEquipmentType;
  }

  if (temperatureLowerLimit && temperatureUpperLimit) {
    equipment.temperature = {
      unit: TemperatureUnit.Fahrenheit, // all temperatures currently are in farenheit
      minimum: temperatureLowerLimit,
      maximum: temperatureUpperLimit
    };
  }

  return equipment;
}

/**
 * Maps backend core equipment type to genesis equipment type.
 */
function mapEquipmentType(equipmentTypes: EquipmentType[], typeId: number): FtlEquipmentType | undefined {
  const type = equipmentTypes.find((et) => et.id == typeId);
  switch (type?.machine_readable) {
    case EquipmentTypeValues.DryVan:
      return FtlEquipmentType.DryVan;
    case EquipmentTypeValues.Flatbed:
    case EquipmentTypeValues.Flatbed53Foot:
    case EquipmentTypeValues.FlatbedAirRide:
    case EquipmentTypeValues.FlatbedConestoga:
    case EquipmentTypeValues.FlatbedDouble:
    case EquipmentTypeValues.FlatbedHotshot:
    case EquipmentTypeValues.FlatbedMaxi:
    case EquipmentTypeValues.FlatbedOverdimension:
      return FtlEquipmentType.Flatbed;
    case EquipmentTypeValues.Reefer:
    case EquipmentTypeValues.ReeferAirRide:
    case EquipmentTypeValues.ReeferDouble:
    case EquipmentTypeValues.ReeferIntermodal:
      return FtlEquipmentType.Reefer;
    default:
      return undefined;
    // throw Error(`Cannot map type ${type.name} to ftl rate equipment type.`)
  }
}

/**
 * Maps backend core weight unit type to genesis weight unit type.
 */
function mapWeightUnit(unit?: ShipmentLineItemWeightUnitEnum | null): WeightUnit {
  switch (unit) {
    case ShipmentLineItemWeightUnitEnum.Kg:
      return WeightUnit.Kg;
    case ShipmentLineItemWeightUnitEnum.Lb:
    default:
      return WeightUnit.Lb;
  }
}

/**
 * Maps backend-core data model ShipmentLineItem to genesis data model FtlShipmentLineItem.
 *
 * Stops in this method are hardcoded to "1" and "2" as full truck load only ever has two stops
 * for all items.
 *
 * - Stop sequence "1" is set to "LOAD"
 * - stop sequence "2" is set to "UNLOAD".
 *
 * Note: all values are mapped 1-to-1 or required. default values are provided if no value is provided
 * through making a shipment.
 *
 * - Numeric values that have not been provided will be set to -1.
 * - Values which can be marked as null or undefined will be set to undefined.
 */
function transformLineItems(lineItems: ShipmentLineItem[], ftlStops: FtlStop[]): FtlShipmentLineItem[] {
  const pickSequenceNumber = getSequenceNumber(ftlStops, 'p');
  const dropSequenceNumber = getSequenceNumber(ftlStops, 'd');
  const result = lineItems?.map(
    ({
      weight_unit: weightUnit,
      package_weight: packageWeight,
      description,
      value_per_piece: valuePerPiece,
      value_per_piece_currency: valuePerPieceCurrency,
      package_type: packageType,
      piece_type: pieceType,
      total_packages: totalPackages
    }) => ({
      weight: {
        unit: weightUnit && mapWeightUnit(weightUnit),
        value: packageWeight ?? -1
      },
      description: description || 'unknown',
      quantity: totalPackages ?? -1,
      declared_value: isNil(valuePerPiece)
        ? undefined
        : {
            currency: valuePerPieceCurrency,
            amount: valuePerPiece
          },
      package_type: transformPackageType(packageType),
      piece_type: transformPieceType(pieceType),
      associated_stops: [
        {
          reason: StopReason.Load,
          stop: {
            sequence_id: pickSequenceNumber
          }
        },
        {
          reason: StopReason.Unload,
          stop: {
            sequence_id: dropSequenceNumber
          }
        }
      ]
    }) // end lineItem map
  ); // end mapping

  return result ?? [];
}
/**
 * Gets the pick or drop sequence number from stops (typically the first stop). If no
 * stop is present in the list or it is undefined then -1 is set.
 */
function getSequenceNumber(ftlStops: FtlStop[], pickOrDrop: 'p' | 'd' = 'p'): number | -1 {
  if (!ftlStops || ftlStops?.length === 0) {
    return -1;
  }

  const sequenceNumbers = ftlStops.map((stop) => stop.sequence_number ?? -1); // set -1 as default since "0" is valid

  if (pickOrDrop.toLowerCase() === 'p') {
    return sequenceNumbers.reduce((left, right) => {
      if (left > right) {
        return right;
      }
      return left;
    });
  }

  // return last sequence number
  return sequenceNumbers.reduce((left, right) => {
    if (left > right) {
      return left;
    }
    return right;
  });
}

/**
 * Maps backend-core stop to genesis FtlStop for getting FTL rates. Stop sequences will start at "1"
 * and increase by "1" individually from there.
 *
 * Note: all values are mapped 1-to-1 or required. default values are provided if no value is provided
 * through making a shipment.
 *
 * - Numeric values that have not been provided will be set to -1.
 * - Values which can be marked as null or undefined will be set to undefined.
 * - Dates are set to default today using `moment()`.
 */
function transformStops(stops: Stop[]): FtlStop[] {
  const result: FtlStop[] | null | undefined = stops?.map(
    ({
      location,
      accessorials,
      ordinal_index: ordinalIndex,
      planned_date: plannedDate,
      planned_time_window_start: plannedTimeWindowStart,
      planned_time_window_end: plannedTimeWindowEnd
    }) => {
      const {timezone, country, address_1, address_2, city, postal_code, state_province, latitude, longitude} =
        location.address;

      const sequenceNumber = isNil(ordinalIndex) ? -1 : ordinalIndex + 1; // check for undefined because "0" is a valid value.

      return {
        sequence_number: sequenceNumber,
        location: {
          country: country,
          line_1: address_1 || undefined,
          line_2: address_2 || undefined,
          locality: city || undefined,
          postal_code: postal_code || undefined,
          region: state_province || undefined,
          geo_coordinates:
            isNil(latitude) || isNil(longitude)
              ? undefined
              : {
                  latitude,
                  longitude
                },
          timezone: timezone || undefined,
          location_type: location.location_type && transformLocationType(location.location_type)
        },
        requested_window: getRequestedWindow(
          plannedDate || new Date().toString(),
          timezone,
          plannedTimeWindowStart || '00:00',
          plannedTimeWindowEnd || '00:00'
        ),
        appointment: getAppointment(
          plannedDate || new Date().toString(),
          plannedTimeWindowStart,
          plannedTimeWindowEnd,
          timezone
        ),
        accessorials: transformAccessorials(accessorials)
      };
    }
  );

  return result ?? [];
}

/**
 * Gets a requested window object to send to genesis. The requested window tells
 * the carrier what "dates" to look at for pickup and drop off.
 *
 * The requested window will use the planned_date from the stop and use the UTC time info
 * as the earliest time and latest time.
 */
function getRequestedWindow(
  plannedDate: string,
  timezone?: string | null,
  plannedTimeStart?: string,
  plannedTimeEnd?: string
): TimeWindow {
  const tz = timezone || moment.tz.guess();

  // default set these so we can at least send a value to genesis0 for rates.
  const earliestDateISO = moment(plannedDate || new Date())
    .set({
      hour: Number(plannedTimeStart?.split(':')[0]),
      minute: Number(plannedTimeStart?.split(':')[1]),
      second: 0
    })
    .tz(tz, true)
    .toISOString(true);
  const latestDateISO = moment(plannedDate || new Date())
    .set({
      hour: Number(plannedTimeEnd?.split(':')[0]),
      minute: Number(plannedTimeEnd?.split(':')[1]),
      second: 0
    })
    .tz(tz, true)
    .toISOString(true);
  return {
    earliest: earliestDateISO,
    latest: latestDateISO
  };
}

/**
 * Gets appointment object to send to genesis. The planned window tells the carrier
 * when the dispatch request should occur and in what order.
 *
 * The `appointment_number` is always set to 1 as carriers only support a single
 * appointment at this time.
 */
function getAppointment(
  plannedDate?: string,
  plannedTimeWindowStart?: string | null,
  plannedTimeWindowEnd?: string | null,
  timezone?: string | null
): Appointment {
  const tz = timezone || moment.tz.guess();
  const earliestTime = moment(plannedTimeWindowStart || '00:00:00', 'HH:mm:ss'),
    latestTime = moment(plannedTimeWindowEnd || '23:59:59', 'HH:mm:ss');

  const earliestDateISO = moment(plannedDate || new Date())
    .set({
      hour: earliestTime.get('hour'),
      minute: earliestTime.get('minute'),
      second: earliestTime.get('second')
    })
    .tz(tz, true)
    .toISOString(true);
  const latestDateISO = moment(plannedDate || new Date())
    .set({
      hour: latestTime.get('hour'),
      minute: latestTime.get('minute'),
      second: latestTime.get('second')
    })
    .tz(tz, true)
    .toISOString(true);

  return {
    appointment_number: 1,
    begin: earliestDateISO,
    end: latestDateISO
  };
}

/**
 * Mapps the location type from backend-core to genesis. There are only two mapped types
 * that can be utilized for FTL rates. All unmapped types will be set as `LocationType.Other`.
 *
 * - "Business (with dock or forklift)"
 * - "Trade Show/Convention Center"
 */
function transformLocationType(locationType: LocationType): GenesisLocationType {
  switch (locationType.name) {
    case 'Business (with dock or forklift)':
      return GenesisLocationType.BusinessWithDock;
    case 'Trade Show/Convention Center':
      return GenesisLocationType.Exhibition;
    default:
      return GenesisLocationType.Other;
  }
}

function transformAccessorials(accessorials: Accessorial[] | undefined) {
  const accessoriesMap: {[key: string]: FtlStopAccessorials} = {
    LTDPU: FtlStopAccessorials.LimitedAccessPickup,
    LTDDEL: FtlStopAccessorials.LimitedAccessDelivery,
    RESPU: FtlStopAccessorials.ResidentialPickup,
    RESDEL: FtlStopAccessorials.ResidentialDelivery,
    SATPU: FtlStopAccessorials.SaturdayPickup,
    SATDEL: FtlStopAccessorials.SaturdayDelivery,
    APPT: FtlStopAccessorials.AppointmentDelivery,
    APPTPU: FtlStopAccessorials.PickupAppointment,
    DROPDEL: FtlStopAccessorials.DropTrailerDelivery,
    INPU: FtlStopAccessorials.InsidePickup,
    INDEL: FtlStopAccessorials.InsideDelivery,
    LGDEL: FtlStopAccessorials.LiftgateDelivery,
    LGPU: FtlStopAccessorials.LimitedAccessPickup,
    CNVDEL: FtlStopAccessorials.ConstructionSiteDelivery,
    CNVPU: FtlStopAccessorials.ConventionTradeshowPickup,
    CONPU: FtlStopAccessorials.ConstructionSitePickup,
    CONDEL: FtlStopAccessorials.ConstructionSiteDelivery
  };

  return accessorials?.map((accessorial) => accessoriesMap[accessorial.code]).filter((el) => !!el);
}

/**
 * Maps the location type from backend-core to genesis. There are only two mapped types
 * that can be utilized for FTL rates. All unmapped types will be set as `FtlPackageType.Other`.
 */
function transformPackageType(packageType?: string): FtlPackageType {
  switch (packageType) {
    case 'BAG':
      return FtlPackageType.Bag;
    case 'BALE':
      return FtlPackageType.Bale;
    case 'BOX':
      return FtlPackageType.Box;
    case 'BUCKET':
      return FtlPackageType.Bucket;
    case 'BUNDLE':
      return FtlPackageType.Bundle;
    case 'CARTON':
      return FtlPackageType.Carton;
    case 'CASE':
      return FtlPackageType.Case;
    case 'COIL':
      return FtlPackageType.Coil;
    case 'CRATE':
      return FtlPackageType.Crate;
    case 'CYLINDER':
      return FtlPackageType.Cylinder;
    case 'DRUM':
      return FtlPackageType.Drum;
    case 'PAIL':
      return FtlPackageType.Pail;
    case 'PIECES':
      return FtlPackageType.Piece;
    case 'PLT':
      return FtlPackageType.Pallet;
    case 'REEL':
      return FtlPackageType.Reel;
    case 'ROLL':
      return FtlPackageType.Roll;
    case 'SKID':
      return FtlPackageType.Skid;
    case 'TUBE':
      return FtlPackageType.Tube;
    default:
      return FtlPackageType.Other;
  }
}

/**
 * Maps the location type from backend-core to genesis. There are only two mapped types
 * that can be utilized for FTL rates. All unmapped types will be set as `FtlPackageType.Other`.
 */
function transformPieceType(pieceType?: string): FtlProductPieceType | undefined {
  switch (pieceType) {
    case 'BAG':
      return FtlProductPieceType.Bag;
    case 'BALE':
      return FtlProductPieceType.Bale;
    case 'BOX':
      return FtlProductPieceType.Box;
    case 'BUCKET':
      return FtlProductPieceType.Bucket;
    case 'BUNDLE':
      return FtlProductPieceType.Bundle;
    case 'CAN':
      return FtlProductPieceType.Carton;
    case 'CARTON':
      return FtlProductPieceType.Carton;
    case 'CASE':
      return FtlProductPieceType.Case;
    case 'COIL':
      return FtlProductPieceType.Coil;
    case 'CRATE':
      return FtlProductPieceType.Crate;
    case 'CYLINDER':
      return FtlProductPieceType.Cylinder;
    case 'DRUM':
      return FtlProductPieceType.Drum;
    case 'PAIL':
      return FtlProductPieceType.Pail;
    case 'PIECES':
      return FtlProductPieceType.Pieces;
    case 'REEL':
      return FtlProductPieceType.Reel;
    case 'ROLL':
      return FtlProductPieceType.Roll;
    case 'SKID':
      return FtlProductPieceType.Skid;
    case 'TUBE':
      return FtlProductPieceType.Tube;
    default:
      return undefined;
  }
}

/**
 * Value check to ensure all properties on `FtlStop` have been set to get
 * a FTL rate.
 */
export function validateStops(stop: FtlStop): boolean {
  const {country, line_1, locality, region, timezone, postal_code} = stop.location;

  return isNil(country) || isNil(line_1) || isNil(locality) || isNil(region) || isNil(timezone) || isNil(postal_code);
}

/**
 * Value check to ensure all properties on `FtlShipmentLineItem` have been set
 * to get a FTL rate.
 */
export function validateLineItems(lineItem: FtlShipmentLineItem) {
  return (
    isNil(lineItem.description) ||
    isNil(lineItem.package_type) ||
    isNil(lineItem.declared_value) ||
    isNil(lineItem.declared_value.amount) ||
    isNil(lineItem.declared_value.currency) ||
    isNil(lineItem.weight) ||
    isNil(lineItem.weight.unit) ||
    isNil(lineItem.weight.value)
  );
}
