import {Banner, Button} from '@shipwell/shipwell-ui';
import {
  CompanyInternationalPreferencesCurrencyEnum,
  CustomField,
  Shipment,
  ShipmentChargeLineItem
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {AggregateCharge, AggregatePrepaidCharge, GrandTotals} from '../../types';
import {AggregateTotalCell, FinancialsGridRow, FinancialsSubTotalRow, TextCell} from './components';
import {getCustomDataValueByFieldType} from './utils';
import {formatCurrency} from 'App/utils/internationalConstants';
import Loader from 'App/common/shipwellLoader';
import {useSortedChargeCategories} from 'App/data-hooks';

export const NewVendorTable = ({
  vendorAssignment,
  aggregateVendorCharges,
  aggregatePrepaidCharges,
  prepaidTotal,
  theirVendorCharges,
  myVendorCharges,
  grandTotals,
  shipmentCurrency,
  customFields,
  onEditClick,
  isEditable,
  is_quoting_limited_user
}: {
  vendorAssignment: Shipment['relationship_to_vendor'];
  aggregateVendorCharges: AggregateCharge[];
  aggregatePrepaidCharges: AggregatePrepaidCharge[];
  prepaidTotal: number;
  theirVendorCharges: ShipmentChargeLineItem[];
  myVendorCharges: ShipmentChargeLineItem[];
  grandTotals: GrandTotals;
  shipmentCurrency: CompanyInternationalPreferencesCurrencyEnum;
  customFields: CustomField[];
  onEditClick?: () => void;
  isEditable: boolean;
  is_quoting_limited_user?: boolean;
}) => {
  const {
    chargeLineItemsCustomFieldsFix,
    decimalSupportForShipmentLineItems
  }: {chargeLineItemsCustomFieldsFix: boolean; decimalSupportForShipmentLineItems: boolean} = useFlags();
  const {data: chargeCategories, isLoading: isLoadingChargeCategories} = useSortedChargeCategories({
    sendShipwellVersionHeader: decimalSupportForShipmentLineItems
  });
  const visibleCustomFields = customFields.filter((customField) => customField.ui_enabled);
  const showCopyButton =
    vendorAssignment && isEditable && !is_quoting_limited_user && theirVendorCharges && theirVendorCharges?.length > 0;
  const extraColumns = showCopyButton ? 3 : 2;

  if (!vendorAssignment) {
    return (
      <div className="p-4 text-center">
        <h3>You must assign a carrier before adding carrier financials</h3>
      </div>
    );
  }

  if (!aggregateVendorCharges?.length) {
    return (
      <div className="flex flex-wrap justify-center p-4 text-center text-base">
        <h3 className="w-full">No carrier financials</h3>
        <Button variant="secondary" size="sm" onClick={onEditClick}>
          Add Item
        </Button>
      </div>
    );
  }

  if (chargeCategories)
    return (
      <div>
        <div>
          {aggregateVendorCharges.map((aggregateVendorCharge, index) => {
            const chargeCategoryName = chargeCategories.find(
              (category) => category.id === aggregateVendorCharge?.category
            )?.name;

            const theirVendorChargesOfThisAggregateCategory = theirVendorCharges.filter(
              (theirVendorCharge) => theirVendorCharge.category === aggregateVendorCharge.category
            );

            const myVendorChargesOfThisAggregateCategory = myVendorCharges.filter(
              (myVendorCharge) => myVendorCharge.category === aggregateVendorCharge.category
            );

            return (
              <FinancialsGridRow
                key={`${aggregateVendorCharge.category}-${index}`}
                columnCount={visibleCustomFields.length + extraColumns}
                isNewFinancialsTable
                hasMismatchedTotals={aggregateVendorCharge?.total_theirs !== aggregateVendorCharge?.total_mine}
              >
                <TextCell>{`${aggregateVendorCharge.charge_code ?? 'N/A'} (${chargeCategoryName ?? ''})`}</TextCell>

                <div className="group flex items-center gap-2">
                  <TextCell>{aggregateVendorCharge.unit_name}</TextCell>
                </div>

                {visibleCustomFields.map((customField) => {
                  const customFieldValue = getCustomDataValueByFieldType(
                    aggregateVendorCharge.customData,
                    customField,
                    chargeLineItemsCustomFieldsFix
                  );
                  return <TextCell key={customField.id}>{customFieldValue}</TextCell>;
                })}
                <AggregateTotalCell
                  aggregateTotal={aggregateVendorCharge.total_theirs}
                  breakdownCharges={theirVendorChargesOfThisAggregateCategory}
                  currency={shipmentCurrency}
                  justifyText="start"
                />
                {showCopyButton ? <div /> : null}
                <AggregateTotalCell
                  aggregateTotal={aggregateVendorCharge.total_mine}
                  breakdownCharges={myVendorChargesOfThisAggregateCategory}
                  currency={shipmentCurrency}
                />
              </FinancialsGridRow>
            );
          })}
        </div>

        <div className="quote-flow__financialsLineItem-addButtons pad-top pl-4">
          <Button variant="tertiary" iconName="AddCircleOutlined" onClick={onEditClick} isCompact>
            Add Line Item
          </Button>
        </div>
        <div className="bg-sw-success-background">
          <FinancialsSubTotalRow
            label="Shipment Total"
            total={formatCurrency(grandTotals.vendor || 0, shipmentCurrency)}
          />
          {aggregatePrepaidCharges.map((aggregatePrepaidCharge, i) => {
            const chargeCategoryName = chargeCategories.find(
              (category) => category.id === aggregatePrepaidCharge.category
            )?.name;

            const myVendorChargesOfThisAggregateCategory = myVendorCharges.filter(
              (myVendorCharge) => myVendorCharge.category === aggregatePrepaidCharge.category
            );

            return (
              <div key={`${aggregatePrepaidCharge.category}-${i}`} className="flex items-center justify-between px-4">
                <div className="text-sm font-semibold">{`Prepaid ${aggregatePrepaidCharge.chargeCode ?? ''} - ${
                  aggregatePrepaidCharge.chargeName ?? ''
                } (${chargeCategoryName ?? ''})`}</div>
                <AggregateTotalCell
                  // prepaid charges come through as positive numbers, but I want to display them as negative
                  aggregateTotal={aggregatePrepaidCharge.total * -1}
                  breakdownCharges={myVendorChargesOfThisAggregateCategory}
                  currency={shipmentCurrency}
                />
              </div>
            );
          })}

          <div className="flex items-center justify-between rounded px-4 py-2 font-semibold text-sw-success">
            <div>Amount Due to Carrier</div>
            <div>{formatCurrency((grandTotals.vendor || 0) - prepaidTotal, shipmentCurrency)}</div>
          </div>
        </div>
      </div>
    );

  if (isLoadingChargeCategories) return <Loader loading />;

  return <Banner title={'Error'}>There was an error loading financials. Try reloading the page.</Banner>;
};
