import capitalize from 'lodash/capitalize';
import minBy from 'lodash/minBy';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import {AxiosError} from 'axios';
import {
  Accessorial,
  ShipmentMode,
  ShipwellError,
  Stop,
  PaginatedSpotNegotiation,
  PurchaseOrderLineItem,
  ShipmentLineItem,
  ShippingDashboardShipmentLineItem,
  ShippingDashboardWeight,
  ShipwellErrorFieldErrorsCondensed,
  SpotNegotiation,
  TotalWeightOverride
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {createGrammaticList} from './grammar';
import {startCaseToLower} from './startCaseToLower';
import {getLineItemUnitSystem} from './lineItemTotalsHelpers';
import {UnitPreferences} from 'App/reducers/types';

export enum ShipmentModeEnum {
  FTL = 'FTL',
  LTL = 'LTL',
  PARCEL = 'PARCEL',
  DRAYAGE = 'DRAYAGE',
  INTERMODAL = 'INTERMODAL',
  VLTL = 'VLTL',
  RAIL = 'RAIL_CAR',
  AIR = 'AIR'
}

export const getShipmentModeText = (mode: ShipmentModeEnum): string => {
  switch (mode) {
    case ShipmentModeEnum.FTL:
      return 'Full Truckload';
    case ShipmentModeEnum.LTL:
      return 'Less Than Truckload';
    case ShipmentModeEnum.PARCEL:
      return 'Parcel';
    case ShipmentModeEnum.DRAYAGE:
      return 'Drayage';
    case ShipmentModeEnum.INTERMODAL:
      return 'Intermodal';
    case ShipmentModeEnum.VLTL:
      return 'Volume Less Than Truckload';
    case ShipmentModeEnum.RAIL:
      return 'Rail';
    default:
      return '';
  }
};

export enum ShipmentTimelineSourceEnum {
  CheckCall = 'Check Call',
  ContainerTracking = 'Tracking Event (Container)',
  DriverApp = 'Driver (Mobile App)',
  EldTrackingEvent = 'Tracking Event (ELD)',
  GeoFenceApp = 'Geofence (Mobile App)',
  GeoFenceELD = 'Geofence (ELD)',
  MobileApp = 'Mobile App',
  P44TrackingEvent = 'P44 Tracking Event',
  System = 'System',
  Unknown = 'Unknown',
  WebApp = 'Web App'
}

type FieldError = {
  field_name: string;
  field_errors: (string | Record<string, string[] | undefined>)[];
};

export type Error = {
  status: number;
  error_description: string;
  field_errors: FieldError[];
};

export type DeliveryType = 'Shipping' | 'Receiving';

export const unpackShipmentErrors = (error: Error, defaultMessage: string, ignoredKeys?: string[]) => {
  const fieldErrors = error.field_errors ?? [];

  const filteredErrors = fieldErrors.reduce((errors: FieldError[], fieldError) => {
    // Because the errors are returned in dot notation, if ignoredKeys contain `.` we look for an exact match.
    // Otherwise, we use `startsWith` to prevent any children of a key from displaying an error
    const exactKeys = ignoredKeys?.filter((ignoredKey) => ignoredKey.includes('.'));
    const generalKeys = ignoredKeys?.filter((ignoredKey) => !ignoredKey.includes('.'));

    if (exactKeys?.some((exactKey) => exactKey === fieldError.field_name)) {
      return errors;
    }
    if (generalKeys?.some((generalKey) => fieldError.field_name.startsWith(generalKey))) {
      return errors;
    }
    return [...errors, fieldError];
  }, []);

  const mappedMetadataErrors = filteredErrors.map((erroredField) => {
    const fullFieldName = erroredField.field_name.split('.');
    const name = fullFieldName.map((n) => n.split('_').join(' ')).join(' ');

    const formattedFieldErrors = erroredField.field_errors.reduce((errorCollection: string[], fieldError) => {
      // fieldError is either a string or an object who's values are either an array of strings or undefined
      if (typeof fieldError === 'string') {
        return [...errorCollection, fieldError];
      }
      // if fieldError is not a string, then it is an object.
      // by reducing the values, we get an array of strings
      const reducedObjectValues = Object.values(fieldError).reduce((acc: string[], val) => {
        if (val !== undefined) {
          return [...acc, ...val];
        }
        return acc;
      }, []);
      return [...errorCollection, ...reducedObjectValues];
    }, []);

    const errors = createGrammaticList(formattedFieldErrors);
    return `${capitalize(name)}: ${errors}`;
  });

  return mappedMetadataErrors.length > 0 ? mappedMetadataErrors : defaultMessage;
};

export const checkShipmentModes = (shipmentMode?: string | number | ShipmentMode | ShipmentMode[]) => {
  let hasFTL: boolean | undefined,
    hasLTL: boolean | undefined,
    hasVLTL: boolean | undefined,
    hasDrayage: boolean | undefined,
    hasParcel: boolean | undefined,
    hasIntermodal: boolean | undefined,
    hasRail: boolean | undefined,
    hasAir: boolean | undefined;
  if (shipmentMode && (Array.isArray(shipmentMode) || typeof shipmentMode === 'object')) {
    const modes: ShipmentMode[] = Array.isArray(shipmentMode) ? shipmentMode : [shipmentMode];
    modes?.forEach((shipmentMode) => {
      hasFTL = hasFTL
        ? hasFTL
        : shipmentMode?.code?.includes(ShipmentModeEnum.FTL.toString()) || shipmentMode?.id === 1;
      hasLTL = hasLTL
        ? hasLTL
        : shipmentMode?.code?.includes(ShipmentModeEnum.LTL.toString()) || shipmentMode?.id === 2;
      hasVLTL = hasVLTL
        ? hasVLTL
        : shipmentMode?.code?.includes(ShipmentModeEnum.VLTL.toString()) || shipmentMode?.id === 4;
      hasDrayage = hasDrayage
        ? hasDrayage
        : shipmentMode?.code?.includes(ShipmentModeEnum.DRAYAGE.toString()) || shipmentMode?.id === 5;
      hasParcel = hasParcel
        ? hasParcel
        : shipmentMode?.code?.includes(ShipmentModeEnum.PARCEL.toString()) || shipmentMode?.id === 6;
      hasIntermodal = hasIntermodal
        ? hasIntermodal
        : shipmentMode?.code?.includes(ShipmentModeEnum.INTERMODAL.toString()) || shipmentMode?.id === 7;
      hasRail = hasRail
        ? hasRail
        : shipmentMode?.code?.includes(ShipmentModeEnum.RAIL.toString()) || shipmentMode?.id === 8;
      hasAir = hasAir
        ? hasAir
        : shipmentMode?.code?.includes(ShipmentModeEnum.AIR.toString()) || shipmentMode?.id === 9;
    });
  } else {
    hasFTL = Number(shipmentMode) === 1;
    hasLTL = Number(shipmentMode) === 2;
    hasVLTL = Number(shipmentMode) === 4;
    hasDrayage = Number(shipmentMode) === 5;
    hasParcel = Number(shipmentMode) === 6;
    hasIntermodal = Number(shipmentMode) === 7;
    hasRail = Number(shipmentMode) === 8;
    hasAir = Number(shipmentMode) === 9;
  }
  return {hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel, hasIntermodal, hasRail, hasAir};
};

// schedule-select value is calculated by other values in a stop
export const getScheduleForTime = (stop: Stop) => {
  const {planned_time_window_start, planned_time_window_end} = stop;
  if (planned_time_window_start) {
    if (planned_time_window_end) {
      if (planned_time_window_end === planned_time_window_start) {
        return 1;
      }
      return 2;
    }
    return 4;
  }
  if (planned_time_window_end) {
    return 3;
  }
  return 1;
};

const workingAccessorialCodes = [
  'APPTPU',
  'LGDEL',
  'RESDEL',
  'LGPU',
  'RESPU',
  'APPTDEL',
  'LTDDEL',
  'CONDEL',
  'SORTDEL',
  'LTDPU',
  'INDEL',
  'INPU',
  'CNVPU',
  'CNVDEL',
  'CROSSDEL',
  'CROSSPU',
  'TRANSLOADPU',
  'TRANSLOADDEL',
  'WHITEGLOVE',
  'HDAYPU',
  'HDAYDEL',
  'SUNDEL',
  'SUNPU',
  'ROOMOFCHOICE'
];

export function filterAccessorials(accessorials: Accessorial[]) {
  accessorials = accessorials || [];
  //filter down to pickup, dropoff, and shipment level accessorials. shipment-level is a specific list of acc that we know work
  const drayageAccessorialPickupIds = new Set([113, 253, 258, 256, 135, 232, 125, 259, 260]);
  const drayageAccessorialDropoffIds = new Set([261, 262, 263, 264, 257, 125]);
  const drayageAccessorialShipmentIds = new Set([162, 205, 206, 207, 265, 127, 266, 267, 228, 197, 179]);
  const saturdayDeliveryAccessorialId = 87;
  const shipment = accessorials.filter(
    (item) =>
      item.type !== 'dropoff' &&
      item.type !== 'pickup' &&
      (item.id === 176 ||
        item.id === 104 ||
        item.id === 121 ||
        item.id === 153 ||
        item.id === 163 ||
        item.id === 178 ||
        item.id === 179 ||
        item.id === 201 ||
        item.id === 205 ||
        item.id === 209 ||
        item.id === 225 ||
        item.id === 67 ||
        item.id === 69 ||
        item.id === 70 ||
        item.id === 68 ||
        item.id === 136 ||
        item.id === 138 ||
        item.id === 139 ||
        item.id === 140 ||
        item.id === 142 ||
        item.id === 143 ||
        item.id === 145 ||
        item.id === 147 ||
        item.id === 137 ||
        item.id === 141 ||
        item.id === 144 ||
        item.id === 146 ||
        item.id === 148 ||
        item.id === 149 ||
        item.id === 150 ||
        item.id === 151 ||
        item.id === 152 ||
        item.id === 113 ||
        item.id === 197 ||
        item.id === 253 ||
        item.id === 254 ||
        item.id === 254 ||
        item.id === 255 ||
        item.id === 256 ||
        item.id === 257 ||
        item.id === 228 ||
        item.id === 206 ||
        item.id === 258 ||
        item.id === 268 ||
        item.id === 232)
  );

  const accessorialObj = {
    drayagePickup: accessorials.filter((accessorial) => drayageAccessorialPickupIds.has(accessorial.id)),
    drayageDropoff: accessorials.filter((accessorial) => drayageAccessorialDropoffIds.has(accessorial.id)),
    drayageShipment: accessorials.filter((accessorial) => drayageAccessorialShipmentIds.has(accessorial.id)),
    parcelShipment: accessorials.filter((a) => a?.id === saturdayDeliveryAccessorialId),
    pickup: accessorials.filter((e) => e.type === 'pickup' && workingAccessorialCodes.includes(e.code)),
    dropoff: accessorials
      .filter((e) => (e.type === 'dropoff' || e.type === 'inside-delivery') && workingAccessorialCodes.includes(e.code))
      .sort((a, b) => (a.description < b.description ? -1 : 1)),
    pickupDropoff: accessorials.filter(
      (e) =>
        (e.type === 'dropoff' || e.type === 'pickup' || e.type === 'inside-delivery') &&
        workingAccessorialCodes.includes(e.code)
    ),
    shipment
  };

  return accessorialObj;
}
/**
 * Get browser specific Visibility API keys and events
 * @returns {Object}
 */
export const getTabVisibilityEventTypes = (document: Document & {msHidden?: boolean; webkitHidden?: boolean}) => {
  let hidden = '';
  let event = '';
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    event = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    event = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    event = 'webkitvisibilitychange';
  }
  return {event, hidden};
};

// returns title and messsage to be used in error toast
export function transformAxiosError(error: AxiosError<ShipwellError>): {title: string; message: string | JSX.Element} {
  const {error_description: title = 'Error', field_errors_condensed = []} = error.response?.data || {};
  let message: string | JSX.Element = 'An Unknown Error Occurred'; // Generic error message

  if (field_errors_condensed?.length) {
    message = (
      <div>
        <ul className="list-none">
          {field_errors_condensed.map((error, ix) => (
            <li key={ix}>
              {error.field_name}: {error.field_errors?.join(', ')}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return {
    title,
    message
  };
}

/**
 * Accepts an array, and a delimiting value, or function yielding a delimiting
 * value, and returns the array, with a delimiter inserted between each pair of
 * elements.
 *
 * Example 1: `interleave([1, 2, 3], 0)` -> `[1, 0, 2, 0, 3]`
 * Example 2:
 *  ```
 *    let i = 1;
 *    return interleave(
 *      [
 *        (<span key="1">1</span>),
 *        (<span key="2">2</span>),
 *        (<span key="3">3</span>)
 *      ],
 *      () => (<span key=${`dk_${i++}`}>, </span>),
 *  ```
 *  returns:
 *  <span key="1">1</span><span key="dk_1">, </span><span key="2">2</span><span key="dk_2">, </span><span key="3">3</span>
 *
 * Note that if `T` is JSX then the delimiter must yield JSX elements with distinct keys.
 */
export function interleave<T>(elements: T[], delimiterIn: T | (() => T), surround = false): T[] {
  if (typeof delimiterIn !== 'function') {
    const d = delimiterIn;
    delimiterIn = () => d;
  }
  const delimiter = delimiterIn as () => T;
  const n = elements.length;
  const result: T[] = [];
  if (surround) {
    result.push(delimiter());
  }
  result.push(elements[0]);
  for (let i = 1; i < n; i++) {
    result.push(delimiter());
    result.push(elements[i]);
  }
  if (surround) {
    result.push(delimiter());
  }
  return result;
}

export const formatShipmentModeCode = (modeCode?: string) =>
  modeCode
    ? ['FTL', 'LTL', 'VLTL'].includes(modeCode)
      ? modeCode
      : modeCode === ShipmentModeEnum.RAIL
      ? 'Rail'
      : startCaseToLower(modeCode)
    : undefined;

type GetLowestBidParams =
  | {
      shipmentSpotNegotiations: PaginatedSpotNegotiation;
      shipmentSpotNegotiation?: never;
    }
  | {
      shipmentSpotNegotiations?: never;
      shipmentSpotNegotiation: SpotNegotiation;
    };
//get the lowest bid from a list of spot negotiations OR a single spot negotiation
export const getLowestBid = ({shipmentSpotNegotiations, shipmentSpotNegotiation}: GetLowestBidParams) => {
  const flattenedQuotes = (shipmentSpotNegotiations?.results || [shipmentSpotNegotiation])
    .map((spotNegotation) => spotNegotation?.quotes)
    .flat();

  return minBy(flattenedQuotes, (quote) => quote?.total) || null;
};

export const countTotalDigits = (value: string | number) => String(value).match(/\d/g)?.length;

const convertCubicUnit = (value: number, unit = 'CM', system = 'METRIC') => {
  const CM3_IN_METERS = 1000000;
  const IN3_IN_METERS = 61023.744;
  const FT3_IN_METERS = 35.315;
  const MTR3_IN_METERS = 1;
  const CM3_IN_FEET = 28316.847;
  const IN3_IN_FEET = 1728;
  const MTR3_IN_FEET = 0.02831657935721365;
  const FT3_IN_FEET = 1;

  const conversions = {
    metric: {cm: CM3_IN_METERS, in: IN3_IN_METERS, ft: FT3_IN_METERS, m: MTR3_IN_METERS},
    imperial: {cm: CM3_IN_FEET, in: IN3_IN_FEET, ft: FT3_IN_FEET, m: MTR3_IN_FEET}
  };

  type ConversionsKey = keyof typeof conversions;
  type UnitKey = keyof typeof conversions.metric;

  return (
    value /
    conversions[system.toLowerCase() as ConversionsKey][(unit === null ? 'CM' : unit).toLocaleLowerCase() as UnitKey]
  );
};

export const calculateVolume = (length: number, width: number, height: number, unit = 'CM', system = 'METRIC') => {
  return convertCubicUnit(length * width * height, unit, system);
};

export const convertWeight = (weight: number, unit = 'KG', system = 'METRIC') => {
  const LBS_IN_KG = 2.20462;
  const KGS_IN_KG = 1;
  const KGS_IN_LB = 0.45359290943563974;
  const LBS_IN_LB = 1;

  const conversions = {
    metric: {kg: KGS_IN_KG, lb: LBS_IN_KG},
    imperial: {kg: KGS_IN_LB, lb: LBS_IN_LB}
  };

  type ConversionsKey = keyof typeof conversions;
  type UnitKey = keyof typeof conversions.metric;

  return (
    weight /
    conversions[system.toLowerCase() as ConversionsKey][(unit === null ? 'KG' : unit).toLocaleLowerCase() as UnitKey]
  );
};

export const calculateShipmentTotals = ({
  line_items = [],
  unitPreferences,
  totalWeight = null
}: {
  line_items: ShipmentLineItem[] | PurchaseOrderLineItem[] | ShippingDashboardShipmentLineItem[];
  unitPreferences?: UnitPreferences;
  totalWeight?: TotalWeightOverride | ShippingDashboardWeight | null;
}) => {
  const getCurrency = () => {
    const lineItemsWithValuePerPiece = line_items.filter((lineItem) => !!lineItem.value_per_piece);
    if (!lineItemsWithValuePerPiece.length) return unitPreferences?.currency;
    const allSameDeclaredValueCurrency = lineItemsWithValuePerPiece.every(
      (filteredLineItem, index, filteredLineItems) =>
        filteredLineItem.value_per_piece_currency === filteredLineItems[0]?.value_per_piece_currency
    );
    if (allSameDeclaredValueCurrency) {
      return lineItemsWithValuePerPiece[0]?.value_per_piece_currency;
    }
    return undefined;
  };

  const shipmentTotals = {
    weight: 0,
    volume: 0,
    units: 0,
    density: 0,
    value: 0,
    system: getLineItemUnitSystem(line_items, unitPreferences, totalWeight),
    currency: getCurrency()
  };
  line_items.forEach(
    ({
      length = 0,
      width = 0,
      height = 0,
      length_unit,
      total_packages = 0,
      value_per_piece = 0,
      total_pieces = 0,
      package_weight = 0,
      weight_unit
    }) => {
      shipmentTotals.value += (total_pieces || 0) * (value_per_piece || 0);
      shipmentTotals.units += Number(total_packages);
      shipmentTotals.weight += convertWeight(
        (package_weight || 0) * (total_packages || 0),
        weight_unit ?? undefined,
        shipmentTotals.system
      );
      shipmentTotals.volume +=
        calculateVolume(length || 0, width || 0, height || 0, length_unit ?? undefined, shipmentTotals.system) *
        (total_packages || 0);
    }
  );

  if (isNaN(shipmentTotals.units)) {
    shipmentTotals.units = 0;
  }
  // currency === undefined means line items have different currencies and we cannot provide an accurate sum
  if (isNaN(shipmentTotals.value) || shipmentTotals.currency === undefined) {
    shipmentTotals.value = 0;
  }

  if (isNaN(shipmentTotals.weight)) {
    shipmentTotals.weight = 0;
  }

  if (isNaN(shipmentTotals.volume)) {
    shipmentTotals.volume = 0;
  }
  if (shipmentTotals.volume > 0) {
    shipmentTotals.density = shipmentTotals.weight / shipmentTotals.volume;
  }
  return shipmentTotals;
};

export const getTotalWeight = (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  line_items: (ShipmentLineItem | PurchaseOrderLineItem)[] = [],
  system: 'METRIC' | 'IMPERIAL'
) => {
  return line_items.reduce(
    (weight, {package_weight = 0, total_packages = 0, weight_unit}) =>
      weight + convertWeight((package_weight || 0) * (total_packages || 0), weight_unit, system),
    0
  );
};

// the backend has not typed this well...
type CorrectedFieldError = Record<string, string | string[]>;
type FieldErrorsTypes = CorrectedFieldError | CorrectedFieldError[] | string[];
type CorrectedShipwellErrorFieldErrorsCondensed = Omit<ShipwellErrorFieldErrorsCondensed, 'field_errors'> & {
  field_errors?: FieldErrorsTypes | null;
};

// Converts a ShipwellError to an object that can be passed to Formik's setErrors
export const unpackShipwellErrorForFormik = ({
  field_errors_condensed = []
}: {
  field_errors_condensed?: CorrectedShipwellErrorFieldErrorsCondensed[];
}) =>
  field_errors_condensed.reduce<Record<string, string>>(
    (acc, {field_errors, field_name}) =>
      field_errors && field_name ? {...acc, [field_name]: unpackFieldErrors(field_errors)} : acc,
    {}
  );

const unpackFieldErrors = (fieldErrors: FieldErrorsTypes) => {
  // the error types SHOULD be either all string or all objects
  const isStringArray = (errorsArray: string[] | CorrectedFieldError[]): errorsArray is string[] =>
    errorsArray.some((error) => typeof error === 'string');

  const getFieldErrorString = (fieldError: CorrectedFieldError) =>
    Object.entries(fieldError)
      .map(
        ([key, errorStringOrArray]) =>
          `${key}: ${typeof errorStringOrArray === 'string' ? errorStringOrArray : errorStringOrArray.join(' ')}`
      )
      .join(' ');

  if (isArray(fieldErrors)) {
    // could be a string array indicating no nested errors
    if (isStringArray(fieldErrors)) {
      return fieldErrors.join(' ');
    }
    // filter out the empty objects. if a field's value is an array the errors are returned as an array of same length.
    // value entries that do not have errors are represented as {}
    const hasMultipleErrors = reject(fieldErrors, isEmpty).length > 1;

    // else reduce the error objects array down to a string containing all errors for the field
    return fieldErrors.reduce(
      (acc, fieldError, index) =>
        isEmpty(fieldError)
          ? acc
          : [acc, getFieldErrorString(fieldError)].join(hasMultipleErrors ? ` [${index}]: ` : ' ').trim(),
      ''
    );
  }
  // I haven't seen it but the sdk typing currently says that it is possible for fieldErrors to not be an array
  return getFieldErrorString(fieldErrors);
};

// used to open a popup window that is centered on the user's screen
export const openPopupWindow = ({
  url,
  popupWidth,
  popupHeight
}: {
  url: string;
  popupWidth?: number;
  popupHeight?: number;
}) => {
  const getWidth = () => {
    if (window.innerWidth) return window.innerWidth;
    if (document.documentElement.clientWidth) return document.documentElement.clientWidth;
    return window.screen.width;
  };

  const getHeight = () => {
    if (window.innerHeight) return window.innerHeight;
    if (document.documentElement.clientHeight) return document.documentElement.clientHeight;
    return window.screen.height;
  };

  const width = getWidth();
  const height = getHeight();

  const screenLeft = window.screenLeft;
  const screenTop = window.screenTop;

  const left = width / 2 - (popupWidth || 0) / 2 + screenLeft;
  const top = height / 2 - (popupHeight || 0) / 2 + screenTop;

  const sizeOptions = `${popupWidth ? `width=${popupWidth},` : ''}${popupHeight ? `height=${popupHeight},` : ''}`;

  window.open(url, '_blank', `popup,${sizeOptions}left=${left},top=${top}`);
};
