import {UserDashboardConfigurationConfig} from '@shipwell/backend-core-sdk';
import {ColumnOrderState, SortingState, VisibilityState} from '@tanstack/react-table';
import {boolean, object, string} from 'yup';

/**
 * Constants for tab selection
 *
 */
export enum TableTabEnums {
  filters = 'FILTERS',
  columns = 'COLUMNS',
  saved = 'SAVED'
}

export const DEFAULT_TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const defaultTablePaginationSize = 20;

export const SORT_STAND_IN = 'DEFAULT';

// legacy dashboard uses view as url query param
export const VIEW_URL_SEARCH_PARAM = 'view';
export const DASHBOARD_LOCAL_STORAGE_KEY = 'dashboardFilterParams';

export const dashboardValidationSchema = object().shape({
  name: string().nullable().required('A dashboard name is required.'),
  isDefault: boolean().nullable()
});

// ********** Filter state conversion ********** //
// BE only supports filters as Record<string, string[]>, convert FE filters as needed
export const convertFiltersType = (filters: Record<string, string | string[]>) =>
  Object.keys(filters).reduce((acc, key) => {
    const value = filters[key];
    // don't add empty string
    if (typeof value === 'string' && Boolean(value)) {
      return {
        ...acc,
        [key]: [value]
      };
    }
    // only add value if array has at least one item
    if (Array.isArray(value) && value.length) {
      return {
        ...acc,
        [key]: value
      };
    }
    return acc;
  }, {});

// Convert BE filters to type as expected by the FE
export const convertTypeCorrectFilters = (
  configFilters: Record<string, string[]>,
  currentFilterState: Record<string, string | string[]>
) =>
  Object.keys(configFilters).reduce((acc, key) => {
    const value = typeof currentFilterState[key] === 'string' ? configFilters?.[key][0] : configFilters?.[key];
    return {
      ...acc,
      [key]: value
    };
  }, {} as Record<string, string | string[]>);

// intialFilterState can contain keys with empty string values,
// this may be different from currentFilterState which omits empty key value pairs
export const cleanEmptyFilters = (filters: Record<string, string | string[]>) =>
  Object.keys(filters).reduce((acc, key) => {
    //if value is an array, check that it is not an empty array
    if (Array.isArray(filters[key]) && !filters[key].length) {
      return acc;
    }
    // if value is not an array, check that it is not an empty string
    if (!Array.isArray(filters[key]) && !filters[key]) {
      return acc;
    }
    return {
      ...acc,
      [key]: filters[key]
    };
  }, {} as Record<string, string | string[]>);

// ********** Sorting state conversion ********** //
export const convertSortStringToSortingState = (sortString: string | null): SortingState => {
  if (!sortString) {
    return [];
  }
  const desc = sortString[0] === '-';
  return [
    {
      id: desc
        ? //remove the '-' from the desc sort string
          sortString.substring(1)
        : sortString,
      desc
    }
  ];
};

export const convertSortingStateToSortString = (sortState: SortingState): string => {
  const {desc, id} = sortState[0];
  return `${desc ? '-' : ''}${id}`;
};

// ********** Visibilty state conversion ********** //
// compare incoming config to initial col list.
// If config is missing col id, assume col has been set to hidden
export const convertReceivedColumnsToVisibilityState = (
  configColumns: string[],
  initialColumns: ColumnOrderState
): Record<string, boolean> => {
  return initialColumns.reduce((acc, column) => {
    if (configColumns.some((col) => col === column)) {
      return acc;
    }
    return {...acc, [column]: false};
  }, {} as Record<string, boolean>);
};

// ********** Create config for PUT/POST to BE ********** //
export function convertTableStateToConfig({
  columnVisibility,
  columnOrder,
  filterState,
  sortString
}: {
  columnVisibility: VisibilityState;
  columnOrder: ColumnOrderState;
  filterState: Record<string, string | string[]>;
  sortString?: string;
}): UserDashboardConfigurationConfig {
  // remove hidden columns from columnOrder array
  const colsToRemove = Object.keys(columnVisibility).reduce((acc: string[], colKey) => {
    if (!columnVisibility[colKey]) {
      return [...acc, colKey];
    }
    return acc;
  }, []);
  const colOrder = columnOrder.filter((col) => colsToRemove.every((removeCol) => col !== removeCol));

  return {
    columns: colOrder,
    ordering: [sortString || SORT_STAND_IN],
    filters: convertFiltersType(filterState)
  };
}

export const getHasFiltersApplied = (filters: Record<string, unknown>) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return Boolean(value.length);
    }
    return Boolean(value);
  });
