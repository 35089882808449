import {useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import {StopLocation} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getDistanceBetweenStops} from 'App/api/addressBook/typed';
import {PRICING_INTEL_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {convertMilesToKilometers} from 'App/utils/internationalConstants';

interface Stops {
  stops: Pick<StopLocation, 'address'>[];
  enabled?: boolean;
}

export const useTotalDistance = ({stops = [], enabled = true}: Stops) => {
  const memoizedStops = useMemo(() => stops.map((stop) => ({address: stop.address})), [stops]);

  const {data, isLoading} = useQuery([PRICING_INTEL_QUERY_KEY, memoizedStops], () => calculateTotalDistance(), {
    refetchOnWindowFocus: false,
    enabled: memoizedStops.every((stop) => stop?.address && Object.keys(stop?.address).length > 0) && enabled,
    placeholderData: []
  });

  const calculateTotalDistance = async () => {
    return await Promise.all(
      memoizedStops.slice(0, -1).map((stop, index) =>
        getDistanceBetweenStops({
          origin: stop?.address,
          destination: stops?.[index + 1]?.address
        })
      )
    );
  };

  const totalDistance = (!isLoading && data?.reduce((sum, {distance_miles}) => sum + distance_miles, 0)) || 0;
  const totalDistanceKM = convertMilesToKilometers(totalDistance, 3, false) as number;

  return {totalDistance, totalDistanceKM, isFetchingDistance: isLoading};
};
